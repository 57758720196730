import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../api.base';
import { DeviceAdvFilterType } from '../../../../app/content/device/filter/data/dev-adv-filter.data';

export interface IGetVirtualDeviceAdvanceFilterRxData {
    type: DeviceAdvFilterType;
    groupName: string;
    subGroupList?: IGetVirtualDeviceAdvanceFilterRxData[];
    optionList?: {
        name: string;
        value: string | null;
        isDefault: boolean;
    }[];
}

export class APIGetVirtualDeviceAdvanceFilterManager extends APIBaseManager<void, void, void, IGetVirtualDeviceAdvanceFilterRxData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_GetVirtualDeviceAdvanceFilter';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/advancedFilters';
    }
}