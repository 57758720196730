export const DEVICE_GROUP_ID_ROOT: string = 'g-root';
export const DEVICE_GROUP_ID_HOME: string = 'g-home';
export const DEVICE_GROUP_ID_DEFAULT: string = 'g-default';
export const DEVICE_GROUP_NAME_DEFAULT: string = 'Default group';

export const DEVICE_GROUP_FUNC_CREATE: string = 'group-create';
export const DEVICE_GROUP_FUNC_DELETE: string = 'group-delete';
export const DEVICE_GROUP_FUNC_MOVE: string = 'group-move';
export const DEVICE_GROUP_FUNC_EXCLUDE: string = 'group-exclude';

export enum DeviceGroupMode {
    edit = 'edit',
    pickone = 'pickone',
    pickmulti = 'pickmulti',
    pickByAdmin = 'pickByAdmin',
}

export enum DeviceGroupType {
    group = 'group',
    device = 'device'
}

export class DeviceGroupInfo {
    private _name: string;
    set name(name: string) { this._name = name; }
    get name(): string { return this._name; }

    private _id: string;
    get id(): string { return this._id; }

    private _childs: DeviceGroupInfo[];
    get childs(): DeviceGroupInfo[] { return this._childs; }

    get subgroups(): DeviceGroupInfo[] { return this._childs.filter(c => c.type === DeviceGroupType.group); }
    
    /*
    get deviceStatistic(): { online: number, total: number } {
        let online: number = 0;
        let total: number = 0;
        this.childs.forEach(c => {
            if (c.type === DeviceGroupType.device) {
                total += 1;
                online += c.data?.onlineStatus == OnlineStatus.Online ? 1 : 0;
            }
            else {
                total += c.deviceStatistic.total;
                online += c.deviceStatistic.online; 
            }
        });

        return { online: online, total: total };
    }
    */
   
    private _parentID: string;
    set parentID(id: string) { this._parentID = id; }
    get parentID(): string { return this._parentID; }

    get isPolicyApply(): boolean { return this.policies.Configuration.length + this.policies.Security.length > 0 ? true : false; }

    type: DeviceGroupType = DeviceGroupType.group;
    expanded?: boolean;
    active?: boolean; //only one group could be active at a time
    hovered?: boolean;
    selected?: boolean;
    allowRemove?: boolean;
    allowMove?: boolean;
    data?: any;
    // policy id list
    policies?: {
        Configuration: string[];
        Security: string[];
        Application: string[];
        FirmwareUpdate: string[];
        Certificate: string[];
    }

    constructor(id: string, parentID: string, name: string, type: DeviceGroupType = DeviceGroupType.group, policies: { Configuration?: string[], Security?: string[], Application?: string[], FirmwareUpdate?: string[], Certificate?: string[] }, allowRemove: boolean = true, allowMove: boolean = true, expanded: boolean = false, data?: any) {
        this._id = id;
        this._parentID = parentID;
        this._name = name;
        this.type = type;
        this.data = data;
        this.expanded = expanded === undefined ? false : expanded;
        this.active = false;
        this.hovered = false;
        this.selected = false;
        this.allowRemove = allowRemove === undefined ? true : allowRemove;
        this.allowMove = allowMove === undefined ? true : allowMove;
        this._childs = [];
        this.policies = {
            Configuration: policies?.Configuration || [],
            Security: policies?.Security || [],
            Application: policies?.Application || [],
            FirmwareUpdate: policies?.FirmwareUpdate || [],
            Certificate: policies?.Certificate || []
        };
    }

    addChild(child: DeviceGroupInfo): void {
        this._childs.push(child);
    }

    cleanChilds(): void {
        this._childs = [];
    }

    removeChildByID(id: string): void {
        const c: DeviceGroupInfo = this._childs.find(c => c.id === id);
        if (c) {
            this._childs.splice(this._childs.indexOf(c), 1);
        }
    }

    removeChild(c: DeviceGroupInfo): void {
        const index: number = this._childs.indexOf(c);
        if (index >= 0) {
            this._childs.splice(index, 1);
        }
    }

    copy(): DeviceGroupInfo {
        const c: DeviceGroupInfo = new DeviceGroupInfo(this._id, this._parentID, this._name, this.type, this.policies, this.allowRemove, this.allowMove, this.expanded, this.data);
        this._childs.forEach(c => c.addChild(c));

        return c;
    }
}