<div class="dropdown">
    <div class="form-control form-control-sm p-2" [class.changed]="_isChanged" data-toggle="dropdown"
        aria-expanded="false">
        <div class="color-panel" [style.background]="_combinedColor"></div>
    </div>
    <div class="dropdown-menu w-100 p-2">
        <div class="container-fluid color-block">
            <div class="row d-flex align-items-center">
                <label class="col-4">Color</label>
                <div class="col-8">
                    <input type="color" class="form-control form-control-sm" [disabled]="_disabled"
                        [ngModel]="_pureColor" (ngModelChange)="onColorUpdated($event)">
                </div>
            </div>
            <div class="row mt-1">
                <label class="col-4">Opacity</label>
                <div class="col-8">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <input type="range" name="opacity" min="0" max="100" step="1" [ngModel]="_opacity"
                            [disabled]="_disabled" (ngModelChange)="onOpacityUpdated($event)">
                        <span class="ml-2">{{ _opacity }}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>