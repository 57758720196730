import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DeviceService } from "../device.service";
import { HelperLib } from "app/lib/common/helper.lib";
import { DeviceLabelInfo } from "./dev-label.data";
import { DeviceInfo, OnlineStatus } from "../data/device-info";
import { fromEvent, of, Subject } from "rxjs";
import { concatMap, debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { AutoUnsubscribeComponent } from "app/content/virtual/auto-unsubscribe.component";
import { Logger } from "app/lib/common/logger";

@Component({
    selector: 'na-dev-label-picker',
    templateUrl: './dev-label-picker.component.html',
    styleUrls: ['./dev-label-picker.component.css']
})
export class DeviceLabelPickerComponent extends AutoUnsubscribeComponent implements OnInit {
    private readonly _initLabel$: Subject<{ from: string, labelNames?: string[], sourceDevices?: DeviceInfo[], query?: { appended?: { labelName: string, virtualDeviceIDList: string[] }, removed?: { labelName: string, virtualDeviceIDList: string[] } } }> = new Subject();

    _labelMap: Map<string, DeviceLabelInfo> = new Map();
    _displayLabels: DeviceLabelInfo[] = [];
    _selectedLabel: DeviceLabelInfo;
    _showSelectedLabelOnly: boolean = false;
    _loading: boolean = false;

    _isAdvFilterApplied: boolean = false;
    _filter: { searchContent?: string, allowedDevices?: DeviceInfo[] } = {};

    @Input('defaultLabel')
    set defaultLabel(v: string) {
        if (!v) {
            this.selectLabel(null);
        }
    }

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._filter.searchContent = e.target.value.toLocaleLowerCase();
                this.refactor();
            });
        }
    }

    @Output() onLabelSelected = new EventEmitter<{ label: DeviceLabelInfo, filter?: { onlineStatus: { [state: string]: boolean } } }>();

    constructor(private devSvc: DeviceService) {
        super();
    }

    ngOnInit(): void {
        this.devSvc.deviceLabelUpdated.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((ev: { query?: { appended?: { labelName: string, virtualDeviceIDList: string[] }, removed?: { labelName: string, virtualDeviceIDList: string[] } }, labelNames: string[] }) => {
            Logger.logInfo('labelPicker', 'deviceLabelUpdated', 'ev: ', ev);
            //this.init('labelUpdateEv', ev.labelNames, this._isAdvFilterApplied ? this._filter.allowedDevices : null, ev.query);
            this._initLabel$.next({ from: 'labelUpdateEv', labelNames: ev.labelNames, sourceDevices: this._isAdvFilterApplied ? this._filter.allowedDevices : null, query: ev.query });
        });

        this.devSvc.deviceFilterApplied.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: { type: string, value: string }[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string, langKey?: string }, devGroup?: { id: string, switch: any } } }) => {
            Logger.logInfo('labelPicker', 'deviceFilterApplied', 'ev: ', res);
            this._isAdvFilterApplied = res.isApplied;
            this._filter.allowedDevices = res.devices;

            if (!res.isApplied) {
                this._showSelectedLabelOnly = false;
            }

            this._initLabel$.next({ from: 'filterEv', sourceDevices: res.isApplied ? res.devices : null });
        });

        this._initLabel$.pipe(
            switchMap((initDatas: { from: string, labelNames: string[], sourceDevices?: DeviceInfo[], query?: { appended?: { labelName: string, virtualDeviceIDList: string[] }, removed?: { labelName: string, virtualDeviceIDList: string[] } } }) => {
                this._loading = true;

                return of(initDatas.labelNames).pipe(
                    concatMap((labels: string[]) => !labels || labels.length == 0 ? this.devSvc.getDeviceLabels() : of(labels)),
                    map((labels: string[]) => {
                        this._labelMap.clear();

                        labels.forEach((labelName: string, index: number) => this._labelMap.set(labelName, {
                            id: index,
                            name: labelName,
                            deviceList: [],
                            onlineCount: 0
                        }));

                        return initDatas.sourceDevices;
                    }),
                    concatMap((devices: DeviceInfo[]) => devices ? of(devices) : this.devSvc.getDevicesByBatch('label-picker').pipe(
                        map((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }) => res.devices)
                    )),
                    map((devices: DeviceInfo[]) => {
                        devices.forEach(dev => {
                            Array.from(dev.virtualDeviceLabelMap.keys()).forEach(labelName => {
                                this._labelMap.get(labelName)?.deviceList.push(dev);
                            });
                        });

                        if (this._selectedLabel) {
                            if (initDatas.query?.removed && !initDatas.query?.appended) {
                                // remove a label
                                if (initDatas.query.removed.labelName === this._selectedLabel.name) {
                                    // wait 100ms in case the dlg will not removed
                                    this._selectedLabel = null;
                                    setTimeout(() => this.selectLabel(null), 100);
                                }
                            }
                            else if (initDatas.query?.removed && initDatas.query?.appended && this._selectedLabel.name === initDatas.query.removed.labelName) {
                                // rename a label
                                this._selectedLabel = this._labelMap.get(initDatas.query.appended.labelName);
                                this.selectLabel(this._selectedLabel);
                            }
                            else {
                                this._selectedLabel = this._labelMap.get(this._selectedLabel.name) || this._selectedLabel;
                            }
                        }

                        this.refactor();
                    })
                );
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe(() => {
            this._loading = false;
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._initLabel$.complete();
    }

    private refactor(): void {
        this._displayLabels = [];

        if (this._isAdvFilterApplied) {
            if (this._showSelectedLabelOnly) {
                this._displayLabels = this._selectedLabel ? [this._selectedLabel] : [];
            }
            else {
                const allowedLabelNames: Set<string> = new Set();
                this._filter.allowedDevices?.forEach(dev => {
                    Array.from(dev.virtualDeviceLabelMap.keys()).forEach((labelName: string) => {
                        allowedLabelNames.add(labelName);
                    });
                });
                allowedLabelNames.forEach(labelName => {
                    if (this._labelMap.has(labelName)) {
                        this._displayLabels.push(this._labelMap.get(labelName));
                    }
                });
            }
        }
        else {
            this._displayLabels = HelperLib.tsMapToList(this._labelMap);
        }

        if (this._filter.searchContent) {
            this._displayLabels = this._displayLabels.filter(label => label.name.toLocaleLowerCase().indexOf(this._filter.searchContent) >= 0);
        }
    }

    getOnlineDevices(deviceList: DeviceInfo[]): DeviceInfo[] {
        return deviceList?.filter(d => d.onlineStatus === OnlineStatus.Online);
    }

    searchOnlineDeviceWithLabel(label: DeviceLabelInfo): void {
        this.selectLabel(label, { onlineStatus: HelperLib.getOnlineStatusState({ [OnlineStatus.Disconnect]: false, [OnlineStatus.Offline]: false }).onlineStatus });
        this._showSelectedLabelOnly = true;
    }

    selectLabel(label: DeviceLabelInfo, filter?: { onlineStatus: { [state: string]: boolean } }): void {
        if (this._showSelectedLabelOnly && !label) {
            return;
        }
        this._selectedLabel = label;
        this.onLabelSelected.emit({ label: this._selectedLabel, filter: filter });
    }
}