import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { SavedAdvanceFilterRawData } from './api.advFilter.data';

export class APICreateSavedFilterManager extends APIBaseManager<void, void, { savedAdvancedFilter: SavedAdvanceFilterRawData }, SavedAdvanceFilterRawData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_CreateSavedFilter';
        this._apiMethod = 'POST';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'advancedFilters';
    }
}