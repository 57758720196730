import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { DeviceSavedFilterFuncService } from "../dlg/dev-saved-filter-func.service";
import { DEV_SAVED_FILTER_FUNC_APPLY, DeviceSavedFilterFuncItem, IDeviceSavedFilterFuncCtrl } from "../dlg/dev-saved-filter-func.def";
import { DeviceSavedFilterFuncDirective } from "../dlg/dev-saved-filter-func.directive";
import { DeviceSavedFilterInfo } from "../data/dev-saved-filter.data";
import { DeviceService } from "../../device.service";
import { AutoUnsubscribeComponent } from "../../../../../app/content/virtual/auto-unsubscribe.component";
import { AppliedFilterEvent } from "../data/dev-adv-filter.data";
import { DeviceFilterHelper } from "../lib/dev-filter.helper";

@Component({
    selector: 'na-dev-saved-filter-entry',
    templateUrl: './dev-saved-filter-entry.component.html',
    styleUrls: ['./dev-saved-filter-entry.component.css']
})
export class DeviceSavedFilterEntryComponent extends AutoUnsubscribeComponent implements OnInit {
    _isFilterApplied: boolean = false;

    @Input('isReady') _isReady: boolean = false;

    @ViewChild(DeviceSavedFilterFuncDirective) private _funcHost: DeviceSavedFilterFuncDirective;

    constructor(private devSavedFilterFunc: DeviceSavedFilterFuncService, private devSvc: DeviceService) {
        super();
    }

    ngOnInit(): void {
        this.devSvc.onDeviceFilterApplied.pipe(takeUntil(this._unsubscribe$)).subscribe((ev: AppliedFilterEvent) => {
            this._isFilterApplied = ev.isApplied ? Object.values(DeviceFilterHelper.isSavedFilterApplied(ev.sourceFilters)).some(Boolean) : false;
        });
    }

    openAdvanceFilter(): void {
        this.createFuncDlg(DEV_SAVED_FILTER_FUNC_APPLY, new DeviceSavedFilterInfo(null, null, this.devSvc.currentFilter));
    }

    private onActionComplete(res: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void {
        // check if setting is changed
        this._isFilterApplied = true;
    }

    private createFuncDlg(name: string, data?: DeviceSavedFilterInfo, other?: any): void {
        const item: DeviceSavedFilterFuncItem = this.devSavedFilterFunc.getItemByName(name);
        if (item) {
            const viewContainerRef = this._funcHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).title = item.title;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).funcName = name;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).data = data;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).other = other;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}