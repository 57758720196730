export interface IEnvironment {
    name: string,
    production: boolean,
    enterprise?: {
        domains: string[]
    },
    deviceFuncMap?: { [platform: string]: any }
}

export const environmentGlobal: IEnvironment = {
    name: 'default',
    production: false,
    enterprise: {
        domains: [
            'mdm.clwd.io',
            'lv-mdm.lv-tron.com'
        ]
    },
    deviceFuncMap: {
        linux: {
            "enableFwUpdate": false,
            "enableApkUpdate": false,
            "enableReboot": true,
            "enableTroubleshoot": true,
            "enableClearCache": false,
            "enableClearAppData": false,
            "enableAlert": true,
            "enableScreenshotRefresh": false,
            "enableRemoteControl": false,
            "enableSecurity": true,
            "enableUserActivity": true,
            "enableLock": true,
            "enableUnlock": true,
            "enableCAScript": true,
            "basicConfig": {
                "enableSingleConfig": true,
                "enableBatchConfig": true,
                "element": {
                    "name": true,
                    "contentUrl": true,
                    "appStartExtraOption": false,
                    "overlay": false,
                    "timezone": false,
                    "timeserver": false,
                    "resolution": false,
                    "orientation": false,
                    "HDCP": false,
                    "adb": false,
                    "dailyReboot": false,
                    "volume": false,
                    "screenOff": {
                        "enabled": false
                    },
                    "maintenancePlayback": false,
                    "failSafe": false,
                    "screenSaver": false,
                    "powersave": false,
                    "brightness": true
                }
            },
            "netConfig": {
                "enableSingleConfig": false,
                "enableBatchConfig": false,
                "element": {
                    "ethernet": false,
                    "wifi": false
                }
            }
        },
        and: {
            "enableFwUpdate": true,
            "enableApkUpdate": true,
            "enableReboot": true,
            "enableTroubleshoot": true,
            "enableClearCache": true,
            "enableClearAppData": true,
            "enableAlert": true,
            "enableScreenshotRefresh": true,
            "enableRemoteControl": true,
            "enableSecurity": true,
            "enableUserActivity": true,
            "enableLock": false,
            "enableUnlock": false,
            "enableCAScript": false,
            "basicConfig": {
                "enableSingleConfig": true,
                "enableBatchConfig": true,
                "element": {
                    "name": true,
                    "contentUrl": true,
                    "appStartExtraOption": true,
                    "overlay": true,
                    "timezone": true,
                    "timeserver": true,
                    "resolution": true,
                    "orientation": true,
                    "HDCP": true,
                    "adb": true,
                    "dailyReboot": true,
                    "volume": true,
                    "screenOff": {
                        "enabled": true
                    },
                    "maintenancePlayback": true,
                    "failSafe": true,
                    "screenSaver": true,
                    "powersave": true,
                    "brightness": true
                }
            },
            "netConfig": {
                "enableSingleConfig": true,
                "enableBatchConfig": false,
                "element": {
                    "ethernet": true,
                    "wifi": true
                }
            }
        },
        win: {
            "enableFwUpdate": true,
            "enableApkUpdate": true,
            "enableReboot": true,
            "enableTroubleshoot": true,
            "enableClearCache": true,
            "enableClearAppData": true,
            "enableAlert": true,
            "enableScreenshotRefresh": true,
            "enableRemoteControl": true,
            "enableSecurity": true,
            "enableUserActivity": true,
            "enableLock": false,
            "enableUnlock": false,
            "enableCAScript": false,
            "basicConfig": {
                "enableSingleConfig": true,
                "enableBatchConfig": true,
                "element": {
                    "name": true,
                    "contentUrl": true,
                    "appStartExtraOption": true,
                    "overlay": true,
                    "timezone": true,
                    "timeserver": true,
                    "resolution": true,
                    "orientation": true,
                    "HDCP": true,
                    "adb": true,
                    "dailyReboot": true,
                    "volume": true,
                    "screenOff": {
                        "enabled": true
                    },
                    "maintenancePlayback": true,
                    "failSafe": true,
                    "screenSaver": true,
                    "powersave": true,
                    "brightness": true
                }
            },
            "netConfig": {
                "enableSingleConfig": true,
                "enableBatchConfig": false,
                "element": {
                    "ethernet": true,
                    "wifi": true
                }
            }
        }
    }
};

