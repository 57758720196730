import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../../../../app/entry/account.service';
import { DialogPage } from '../../../../../app/lib/common/common.data';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { DeviceGroupInfo, DeviceGroupMode, DeviceGroupType } from '../../../device/group/dev-group.data';
import { DeviceGroupService } from '../../../device/group/dev-group.service';
import { IUserDlgFuncCtrl, UserDlgFuncItem, USER_DLG_GROUP_FUNC_REMOVE } from '../dlg/user-dlg-func.def';
import { UserDlgFuncDirective } from '../dlg/user-dlg-func.directive';
import { UserDlgFuncService } from '../dlg/user-dlg-func.service';
import { UserService } from '../user.service';
import { UserGroupInfo } from './user-group.data';

@Component({
    selector: 'na-user-group-detail',
    templateUrl: './user-group-detail.component.html',
    styleUrls: ['../user.style.css', './user-group-detail.component.css']
})
export class UserGroupDetailComponent implements OnInit {
    _loading: boolean;
    _isInEdit: boolean;
    _editData: { groupName: string, autoAssignDeviceGroup: boolean, devGroupMap: { [devGroupID: string]: { beforeCheck: boolean, checked: boolean, groupType: DeviceGroupType, name?: string } } };

    _errorMessage: string;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    _devGroupRoot: DeviceGroupInfo;
    _devGroupMode: DeviceGroupMode = DeviceGroupMode.pickByAdmin;
    _devGroupCount: number = 0;

    _userGroup: UserGroupInfo;
    @Input('group')
    set group(v: UserGroupInfo) {
        if (this._userGroup !== v) {
            this._userGroup = v;
            this._editData.devGroupMap = {};
            this._devGroupCount = this._userGroup.appliedDeviceGroupIDList.length;
        }
    }

    private _userDlgFuncHost: UserDlgFuncDirective;
    @ViewChild(UserDlgFuncDirective, { static: true })
    set userDlgFuncHost(host: any) {
        this._userDlgFuncHost = host;
    }

    constructor(
        private accountSvc: AccountService,
        private userSvc: UserService,
        private userDlgFuncSvc: UserDlgFuncService,
        private devGroupSvc: DeviceGroupService
    ) {
        this._editData = {
            groupName: '',
            autoAssignDeviceGroup: false,
            devGroupMap: {}
        };
    }

    ngOnInit(): void {
        this._loading = true;
        this.devGroupSvc.getEnterpriseDeviceHomeGroup().subscribe((res: DeviceGroupInfo) => {
            this._devGroupRoot = res;
            this._loading = false;
        });
    }

    supportUpdateUserGroup(): boolean {
        return this.accountSvc.hasScope_admin_account_group_update();
    }

    selectDeviceGroups(): void {
        this._devGroupCount = HelperLib.mapToList(this._editData.devGroupMap).filter(dg => dg.checked).length;
    }

    startEdit(): void {
        this._isInEdit = true;
        this._editData.groupName = this._userGroup.name;
        this._editData.autoAssignDeviceGroup = this._userGroup.autoAssignDeviceGroup;
        this._userGroup.appliedDeviceGroupIDList.forEach(devGroupID => {
            const devGroup: DeviceGroupInfo = this.devGroupSvc.getEnterpriseGroupByID(devGroupID);
            this._editData.devGroupMap[devGroupID] = {
                beforeCheck: true,
                checked: true, 
                groupType: DeviceGroupType.group, 
                name: devGroup ? devGroup.name : '' 
            };
        });
    }

    cancelEdit(): void {
        this._isInEdit = false;
        this._errorMessage = null;
        this._editData.groupName = '';
        this._editData.autoAssignDeviceGroup = false;
        this._editData.devGroupMap = {};
    }

    saveEdit(): void {
        this._page = DialogPage.submit;
        this._errorMessage = null;
        
        const checkedDeviceGroupIDList: string[] = [];
        let isDeviceGroupChanged: boolean = false;
        for (const devGroupID of Object.keys(this._editData.devGroupMap)) {
            if (this._editData.devGroupMap[devGroupID].beforeCheck !== this._editData.devGroupMap[devGroupID].checked) {
                isDeviceGroupChanged = true;
            }

            if (this._editData.devGroupMap[devGroupID].checked) {
                checkedDeviceGroupIDList.push(devGroupID);
            }
        }

        this.userSvc.updateUserGroup(this._userGroup, this._editData.groupName, this._editData.autoAssignDeviceGroup, isDeviceGroupChanged, checkedDeviceGroupIDList).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._errorMessage = res.errorMessage;
            this._page = DialogPage.result;

            if (!res.isFault) {
                this.cancelEdit();
            }
        });
    }

    removeUserGroup(): void {
        this.createUserGroupDlg(USER_DLG_GROUP_FUNC_REMOVE, this._userGroup);
    }

    private onActionComplete(ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void { }

    private onActionCancel(ret: { funcName: string }): void { }

    private createUserGroupDlg(userFuncName: string, userGroup: UserGroupInfo, other?: void): void {
        const item: UserDlgFuncItem = this.userDlgFuncSvc.getItemByName(userFuncName);
        if (item) {
            const viewContainerRef = this._userDlgFuncHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IUserDlgFuncCtrl<UserGroupInfo, void>>componentRef.instance).title = item.title;
            (<IUserDlgFuncCtrl<UserGroupInfo, void>>componentRef.instance).funcName = userFuncName;
            (<IUserDlgFuncCtrl<UserGroupInfo, void>>componentRef.instance).data = userGroup;
            (<IUserDlgFuncCtrl<UserGroupInfo, void>>componentRef.instance).other = other;
            (<IUserDlgFuncCtrl<UserGroupInfo, void>>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
            (<IUserDlgFuncCtrl<UserGroupInfo, void>>componentRef.instance).onActionCancelled = this.onActionCancel.bind(this);
        }
    }
}