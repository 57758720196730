import { Component } from '@angular/core';
import { DevFuncConfirmInterface } from '../../base/confirm/dev-func-confirm.interface';

@Component({
    templateUrl: './tag-update-confirm-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css']
})
export class TagUpdateConfirmSubFormComponent implements DevFuncConfirmInterface {

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            if (this._actionData.newlyAddedTagStr?.trim()) {
                this._newTags = this._actionData.newlyAddedTagStr.trim().split(',').map(t => t.trim());
            }

            this._removedTags = [...this._actionData.commonTags, ...this._actionData.uncommonTags].filter(t => t.toRemove).map(t => t.tagName);
            setTimeout(() => {
                this.allowSubmitCallback(this._newTags?.length == 0 && this._removedTags?.length == 0 ? false : true);
            });
        }
    }

    _newTags: string[] = [];
    _removedTags: string[] = [];

    allowSubmitCallback: (isAllow: boolean) => void;
}