<div>
  <p>
    Choose the settings you want to apply to all selected devices as common settings :
  </p>
  <div>
    <label for="batch-setting-category" class="txt-bold">
      1. Category :
    </label>
    <div id="batch-setting-category" class="category-block">
      <div *ngFor="let config of _configList" class="category">
        <button [id]="'dropdown' + config.name + 'SettingLink'" type="button"
          class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
          {{ config.name }}
        </button>
        <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
          [attr.aria-labelledby]="'dropdown' + config.name + 'SettingLink'">
          <ng-template ngFor let-c [ngForOf]="config.contentList">
            <li *ngIf="c.bShown" (click)="c.isConfig = true">
              {{ c.langKey | translate | capitalFirstWord }}
              <i *ngIf="c.isConfig" class="fas fa-check ml-auto"></i>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <label for="batch-setting-choosed" class="txt-bold">
      2. Chosen settings :
    </label>
    <div class="setting-block mt-1">
      <ng-template ngFor let-config [ngForOf]="_configList">
        <ng-template ngFor let-content [ngForOf]="config.contentList">
          <span *ngIf="content.isConfig" class="setting" [class.active]="_selectedConfig?.key === content.key"
            (click)="selectConfig(content)">
            {{ content.langKey | translate | capitalFirstWord }}
            <i class="fas fa-times ml-2" (click)="removeConfig(content)"></i>
          </span>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <div class="mt-2">
    <label for="batch-setting-detail" class="txt-bold">
      3. Setting detail :
    </label>
    <div *ngIf="_selectedConfig" id="batch-setting-detail" [ngSwitch]="_selectedConfig.key" class="setting-detail-block mt-1">
      <!-- Display orientation -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.DisplayOrientation">
        <div class="form-group">
          <div class="ctrl-content">
            <na-screen-orientation [data]="_selectedConfig.settingMap[constantSvc.DEVKEY_HD_VIDEO_ROTATION].value" (changed)="onBatchConfigDisplayOrientationChanged(_selectedConfig, $event)"></na-screen-orientation>
          </div>
        </div>
      </ng-template>
      <!-- volume -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.Volume">
        <div *ngIf="Enable_volume" class="form-group">
          <div class="ctrl-content">
            <na-volume [volume]="_selectedConfig.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL].value"
              (onVolumeChanged)="_selectedConfig.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL].value = $event">
            </na-volume>
          </div>
        </div>
      </ng-template>
      <!-- power save -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.Powersave">
        <div *ngIf="Enable_powersave" class="form-group">
          <div class="ctrl-content">
            <na-powersave [keyPair]="_selectedConfig.settingMap"
              (onPowersaveChanged)="onBatchConfigPowersaveChanged(_selectedConfig, $event)">
            </na-powersave>
          </div>
        </div>
      </ng-template>
      <!-- brightness -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.Brightness">
        <div *ngIf="Enable_brightness" class="form-group">
          <div class="ctrl-content">
            <na-brightness [data]="_selectedConfig.settingMap[constantSvc.DEVKEY_FAKE_BRIGHTNESS].value" (onBrightnessChanged)="onBatchConfigBrightnessChanged(_selectedConfig, $event)"></na-brightness>
          </div>
        </div>
      </ng-template>
      <!-- Appstart -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.AppStart">
        <div class="form-group">
          <div class="ctrl-content">
            <na-appstart [config]="_selectedConfig.settingMap"
              (onAppstartChanged)="onBatchConfigAppstartChanged(_selectedConfig, $event)"></na-appstart>
          </div>
        </div>
      </ng-template>
      <!-- QRCode -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.OverlayQRCode">
        <div class="form-group">
          <div class="ctrl-content">
            <na-overlay-qrcode [config]="_selectedConfig.settingMap"
              (onOverlayQRCodeChanged)="onBatchConfigOverlayQRCodeChanged(_selectedConfig, $event)"></na-overlay-qrcode>
          </div>
        </div>
      </ng-template>
      <!-- maintenance -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.MaintenancePlayback">
        <div *ngIf="Enable_maintenance" class="form-group">
          <div class="ctrl-content">
            <na-mtPlayback [data]="_selectedConfig.settingMap[constantSvc.DEVKEY_FAKE_MAINTENANCE].value"
              (updated)="onBatchConfigMaintenanceUpdated(_selectedConfig, $event)"></na-mtPlayback>
          </div>
        </div>
      </ng-template>
      <!-- Timezone -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.Timezone">
        <div class="form-group">
          <div class="ctrl-content">
            <na-timezone [data]="_selectedConfig.settingMap[constantSvc.DEVKEY_TIME_TIMEZONE].value" (changed)="onBatchConfigTimezoneChanged(_selectedConfig, $event)"></na-timezone>
          </div>
        </div>
      </ng-template>
      <!-- Time server -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.Timeserver">
        <div class="form-group">
          <div class="ctrl-content">
            <na-timeserver [source]="_selectedConfig.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value"
              [on]="_selectedConfig.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value"
              (changed)="onBatchConfigTimeserverChanged(_selectedConfig, $event)"></na-timeserver>
          </div>
        </div>
      </ng-template>
      <!-- Daily reboot -->
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.DailyReboot">
        <div class="form-group">
          <div class="ctrl-content">
            <na-dailyReboot [config]="_selectedConfig.settingMap" (onDataChanged)="onBatchConfigDailyRebootChanged(_selectedConfig, $event)"></na-dailyReboot>
          </div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="_enumConfigurableItemKey.LockScreen">
        <div class="form-group">
          <div class="ctrl-content">
            <na-lockScreen [data]="_selectedConfig.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN].value"
              (changed)="onBatchConfigTimeserverChanged(_selectedConfig, $event)"></na-lockScreen>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>