import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { takeUntil, debounceTime, map, concatMap, tap } from 'rxjs/operators';

import { DeviceInfo, OnlineStatus } from '../data/device-info';
import { DeviceService } from '../device.service';
import { OnOffSwitch, SortType } from '../../../lib/common/common.data';
import { DeviceGroupInfo, DEVICE_GROUP_ID_HOME } from '../group/dev-group.data';
import { DeviceGroupService } from '../group/dev-group.service';
import { HelperLib, REFRESH_DURATION } from '../../../lib/common/helper.lib';
import { ConstantService } from '../../../lib/common/constant.service';
import { DashboardView } from '../../user-preference.data';
import { UserPreferenceService } from '../../user-preference.service';
import { AccountService } from '../../../entry/account.service';
import { DisplayItem } from '../data/display-item';
import { DevFuncItem } from '../../devfunc/dev-func-item';
import { DevFuncInterface } from '../../devfunc/dev-func.interface';
import { AppConfigService } from '../../../app.config';
import { DevFuncDirective } from '../../devfunc/dev-func.directive';
import { DevFuncService } from '../../devfunc/dev-func.service';
import { PolicyService } from '../../../../app/content/setting/policy/policy.service';
import { PolicyInfo } from '../../../../app/content/setting/policy/policy.data';
import { AutoUnsubscribeComponent } from '../../../../app/content/virtual/auto-unsubscribe.component';
import { Logger } from '../../../../app/lib/common/logger';
import { SearchExpression, SearchFilterRule, SearchHelper } from '../../../../app/content/device/filter/lib/search.helper';
import { TranslateService } from '../../../../app/translate/translate.service';
import { AppliedFilterEvent } from '../filter/data/dev-adv-filter.data';

@Component({
    selector: 'na-dev-picker',
    templateUrl: './dev-picker.component.html',
    styleUrls: ['./dev-picker.component.css']
})
export class DevicePickerComponent extends AutoUnsubscribeComponent implements OnInit {
    readonly NUMBER_IN_PAGE_OPTIONS: number[] = [30, 100, 200];
    readonly SCREENSHOT_REFRESH_DURATION: number = 1200000;

    _deviceSource: DeviceInfo[] = [];

    _deviceList: DeviceInfo[] = []; // all devices
    _displayDevListByDevGroup: DeviceInfo[] = []; // devices under a device group
    _displayDevListByFilter: DeviceInfo[] = []; // devices under a device group & filter by filters
    _displayDevListByPage: DeviceInfo[] = []; // devices under a device group & filter by filters & filter by page
    _isRemoteCtrlPreCheckSupport: boolean = false;
    _viewType: DashboardView = DashboardView.table;
    _enumViewType: typeof DashboardView = DashboardView;

    _currentSortKey: string;
    _currentSortType: SortType;
    _enumSortType: typeof SortType = SortType;
    _enumOnlineType: typeof OnlineStatus = OnlineStatus;

    _devGroupSwitch: OnOffSwitch = OnOffSwitch.on;
    _refreshCounter: number = 0;
    _loading: boolean = false;
    _loadingDevices: boolean = false;
    set loadingDevices(v: boolean) {
        this._loadingDevices = v;
        this.onDeviceLoadingStatusChanged.emit(this._loadingDevices);
    }
    _exporting: boolean = false;
    _bAllDeviceSelectedUnderCurrentPage: boolean = false;

    _screenshotInfo: { [virtualId: string]: boolean } = {};

    _numberInPage: number = this.NUMBER_IN_PAGE_OPTIONS[0];
    _currentPage: number = 1;
    _selectedDeviceAmountByFilter: number = 0;

    _searchQuery: SearchExpression;
    _searchFieldList: { field: string, langKey: string, checked: boolean, disabled: boolean, valueGenerator: (d: DeviceInfo) => string | string[] }[] = [];
    _currentSearchValue: string = '';
    _currentSearchFieldsHint: string;

    _tableColMap: { [key: string]: DisplayItem } = {};
    _tableColumnList: DisplayItem[] = [];

    _isAdvanceFilterApplied: boolean = false;

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(800),
                concatMap((e: any) => {
                    this._currentSearchValue = e.target.value;
                    return this.generateSearchQueryAndFilterDevice(this._currentSearchValue);
                }),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => { });
        }
    }

    @ViewChild('reportLink', { static: true }) private _reportLinkRef: ElementRef;
    @ViewChild('dragImgSubstitute', { static: true }) private _dragImgRef: ElementRef;
    @ViewChild(DevFuncDirective, { static: true }) devFuncHost: DevFuncDirective;

    @Output() onDeviceSelectionChanged = new EventEmitter<number>();
    @Output() onDeviceLoadingStatusChanged = new EventEmitter<boolean>();

    constructor(
        private constantSvc: ConstantService,
        private accountSvc: AccountService,
        private devSvc: DeviceService,
        private devGroupSvc: DeviceGroupService,
        private userPrefSvc: UserPreferenceService,
        private policySvc: PolicyService,
        public devFuncSvc: DevFuncService,
        private translateSvc: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this._devGroupSwitch = this.devGroupSvc.groupSwitch;
        this._numberInPage = this.userPrefSvc.userPreference.home.device.pageCapacity;
        this.updateViewRelatedFunction();
        this.initColumnAndSearch();

        this.devGroupSvc.onActiveGroupChanged.pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe((ev: { group: DeviceGroupInfo }) => {
            this.refactorDevices(this._deviceSource, 'active group change');
            this.updateSelectedDeviceAmount();
        });

        this.devGroupSvc.onDevGroupSwitchChanged.pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe((groupSwitch: OnOffSwitch) => {
            this._devGroupSwitch = groupSwitch;
            this.updateTableColumnList();
            this.refactorDevices(this._deviceSource, 'group switch change');
        });

        this.devSvc.onDeviceFilterApplied.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: AppliedFilterEvent) => {
            this._isAdvanceFilterApplied = res.isApplied;
            if (res.isApplied) {
                if (res.sourceFilters.quickSearch) {
                    // recover the last search content
                    this._searchQuery = res.sourceFilters.quickSearch;
                    const lastSearchText = ((this._searchQuery.expressions[0] as SearchExpression)?.expressions[0] as SearchFilterRule)?.searchValue;
                    if (this._searchRef?.nativeElement && lastSearchText) {
                        this._currentSearchValue = lastSearchText;
                    }
                }
                else {
                    this._currentSearchValue = '';
                }
                this.changeDeviceSource(res.devices, 'Adv filter apply');
            }
            else {
                this._currentSearchValue = '';
                this.changeDeviceSource(this._deviceList, 'Adv filter reset');
            }
        });

        this._loading = true;
        this.loadingDevices = true;

        HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
            this.devSvc.getDevicesByBatch('device-overview-table.onInit').subscribe((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }) => {
                if (!res.isFault) {
                    this._deviceList = this._deviceList.concat(res.devices.filter(d => d.isPaired));
                    if (!this._isAdvanceFilterApplied) {
                        this.changeDeviceSource(this._deviceList, 'init');
                    }
                }

                if (!res.hasNext) {
                    this.loadingDevices = false;
                }

                this.updateSelectedDeviceAmount();
                this._loading = false;
            });
        });

        fromEvent(window, 'resize').pipe(
            debounceTime(500),
            takeUntil(this._unsubscribe$)
        ).subscribe(e => {
            this.updateViewRelatedFunction();
        });
    }

    private changeDeviceSource(source: DeviceInfo[], reason: string, options?: { skipCommonFilter?: boolean, forceUpdateScreenshot?: boolean }): void {
        this._deviceSource = source;
        this.refactorDevices(this._deviceSource, reason, options);
    }

    private updateViewRelatedFunction(): void {
        this._isRemoteCtrlPreCheckSupport = this.devSvc.isDeviceSupportRemoteCtrl();
        this._viewType = HelperLib.isMobileLayout() ? DashboardView.grid : (this.userPrefSvc.userPreference.home.device.view || DashboardView.table);
    }

    private initColumnAndSearch(): void {
        const lastTableColumns: { [key: string]: boolean } = this.userPrefSvc.userPreference.home.device.tableLayoutColumnOptionMap;
        // init table column
        this._tableColMap = {};
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] = new DisplayItem('name', this.constantSvc.DEVKEY_FAKE_DISPLAYNAME, 'key-name', false, true, lastTableColumns?.[this.constantSvc.DEVKEY_FAKE_DISPLAYNAME] ?? true);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_MODEL] = new DisplayItem('model', this.constantSvc.DEVKEY_INFO_MODEL, 'key-dev-model', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_INFO_MODEL] ?? true);
        this._tableColMap[this.constantSvc.DEVKEY_NET_LAN_MAC] = new DisplayItem('mac', this.constantSvc.DEVKEY_NET_LAN_MAC, 'key-dev-MAC', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_NET_LAN_MAC] ?? false);
        this._tableColMap[this.constantSvc.DEVKEY_NET_LAN_IP] = new DisplayItem('ip', this.constantSvc.DEVKEY_NET_LAN_IP, 'key-net-ip', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_NET_LAN_IP] ?? false);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_FW_VERSION] = new DisplayItem('fw', this.constantSvc.DEVKEY_INFO_FW_VERSION, 'key-dev-firmwareVersion', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_INFO_FW_VERSION] ?? true);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_APKVERSION] = new DisplayItem('apk', this.constantSvc.DEVKEY_INFO_APKVERSION, 'key-dev-apkVersion', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_INFO_APKVERSION] ?? false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] = new DisplayItem('heartbeat', this.constantSvc.DEVKEY_FAKE_HEARTBEAT, 'key-dev-heartbeat', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_FAKE_HEARTBEAT] ?? false);
        this._tableColMap[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] = new DisplayItem('warranty', this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE, 'key-warranty', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] ?? false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME] = new DisplayItem('group', this.constantSvc.DEVKEY_FAKE_GROUPNAME, 'key-group', false, false, lastTableColumns?.[this.constantSvc.DEVKEY_FAKE_GROUPNAME] ?? true);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_SCEP_STATUS] = new DisplayItem('scepStatus', this.constantSvc.DEVKEY_FAKE_SCEP_STATUS, 'SCEP status', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_FAKE_SCEP_STATUS] ?? true, false);
        this._tableColMap[this.constantSvc.DEVKEY_APPMETADATA] = new DisplayItem('metadata', this.constantSvc.DEVKEY_APPMETADATA, 'Metadata', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_APPMETADATA] ?? true, false);
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_TAG] = new DisplayItem('tag', this.constantSvc.DEVKEY_FAKE_TAG, 'Tag', true, true, lastTableColumns?.[this.constantSvc.DEVKEY_FAKE_TAG] ?? true, false);

        this.updateTableColumnList();

        // init search infos
        const lastSearchOptionVisibleMap: { [key: string]: boolean } = this.userPrefSvc.userPreference.home.device.searchOptions;
        this._searchFieldList = [];
        this._searchFieldList.push(...SearchHelper.getSupportedSearchCriteria(this.constantSvc).map(c => Object.assign(c, { checked: lastSearchOptionVisibleMap[c.field] ?? true, disabled: false })));
        this.updateSearchFieldStatus();
        this._searchQuery = { operator: 'AND', expressions: [{ operator: 'OR', expressions: [] }] };
        this.updateSearchHint();
    }

    private updateTableColumnList(): void {
        this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME].allowChoose = this._tableColMap[this.constantSvc.DEVKEY_FAKE_GROUPNAME].allowShow = this._devGroupSwitch === OnOffSwitch.off;
        this._tableColumnList = HelperLib.mapToList(this._tableColMap);
    }

    //get if remote control is support; if not, hide it.
    supportRemoteCtrl(device: DeviceInfo): boolean {
        return this.devSvc.isDeviceSupportRemoteCtrl(device);
    }

    refreshDevices(): void {
        this._refreshCounter = REFRESH_DURATION * 2;
        HelperLib.countdown(this._refreshCounter, 0, (remain_counter: number) => {
            this._refreshCounter = remain_counter;
        });

        this._loading = true;
        this.loadingDevices = true;
        this._deviceList = [];
        this._displayDevListByDevGroup = [];
        this._displayDevListByFilter = [];
        this._displayDevListByPage = [];
        this._screenshotInfo = {};

        let emitCount: number = 0;
        this.devSvc.getDevicesByBatch('device-overview-table.refresh', true).pipe(
            tap((res: any) => { emitCount++; }),
        ).subscribe((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.isFault) {
                this._deviceList = this._deviceList.concat(res.devices.filter(d => d.isPaired));
                this.refactorDevices(this._deviceSource, 'refresh', { forceUpdateScreenshot: emitCount === 1 ? true : false });
            }

            if (!res.hasNext) {
                this.loadingDevices = false;
            }

            this._loading = false;
        });
    }

    reduceAppFeedbackMetadata(metadata?: any): string {
        return metadata ? JSON.stringify(metadata).substring(0, 20) + ' ...' : '';
    }

    exportDevices(): void {
        this._exporting = true;

        // list policies first and print the policy name to which device group belongs
        this.policySvc.getPolicyList().pipe(
            concatMap((policies: PolicyInfo[]) => this.devSvc.exportDevices(this._displayDevListByFilter, this.devGroupSvc.getActiveGroup()?.id, this.devGroupSvc.getAllDeviceGroups(), policies)),
            map((c: { header: string, metadata?: string[], dataList: string[][], date: string }) => HelperLib.downloadCsv(this._reportLinkRef.nativeElement, 'DeviceList_' + c.date, c.header, c.dataList, c.metadata))
        ).subscribe(() => {
            this._exporting = false;
        });
    }

    changeNumberInPage(currentNumberInPage: number): void {
        if (this._numberInPage !== currentNumberInPage) {
            this._numberInPage = currentNumberInPage;
            this.userPrefSvc.changeHomeDevicePageCapacity(this._numberInPage);
            this.refactorDevices(this._deviceSource, 'numberInPage');
        }
    }

    changeTableColumnOption(col: DisplayItem): void {
        col.show = !col.show;
        this.userPrefSvc.changeHomeTableViewColumns(Object.values(this._tableColMap).map((col: DisplayItem) => ({ key: col.key, show: col.show })));
    }

    updateSearchField(searchKey: { key: string, langKey: string, checked: boolean, disabled: boolean }, checked: boolean): void {
        if (!searchKey.disabled) {
            searchKey.checked = checked;
        }

        // change disabled state of each search key
        this.updateSearchFieldStatus();

        this.updateSearchHint();
        this.userPrefSvc.changeHomeDeviceSearchOption(this._searchFieldList.map(s => ({ key: s.field, enabled: s.checked })));
        this.generateSearchQueryAndFilterDevice(this._currentSearchValue).subscribe();
    }

    private updateSearchFieldStatus(): void {
        const checkedList: { field: string, langKey: string, checked: boolean, disabled: boolean }[] = this._searchFieldList.filter(k => k.checked);
        if (checkedList.length === 1) {
            checkedList[0].disabled = true;
        }
        else {
            this._searchFieldList.forEach(sf => sf.disabled = false);
            // force all search fields to be checked if none of them is checked.
            if (checkedList.length == 0) {
                this._searchFieldList.forEach(sf => sf.checked = true);
            }
        }
    }

    private updateSearchHint(): void {
        this._currentSearchFieldsHint = `( ${this._searchFieldList.filter(s => s.checked).map(s => this.translateSvc.instant(s.langKey) || s.field).join(', ')} )`;
    }

    private generateSearchQueryAndFilterDevice(searchValue: string): Observable<{ isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }> {
        const subExpr = this._searchQuery.expressions[0] as SearchExpression;
        if (subExpr) {
            subExpr.expressions = this._searchFieldList.filter(s => s.checked).map(s => ({ id: `filter-${Date.now()}`, field: s.field, langKey: s.langKey, rule: 'contains', valueGenerator: s.valueGenerator, searchValue: searchValue }));
        }

        return this.devSvc.getDevicesByFilter('search', { quickSearch: this._searchQuery });
    }

    changeView(targetView: DashboardView): void {
        this._viewType = targetView;
        this.userPrefSvc.changeHomeDeviceView(this._viewType);
        if (this._viewType === DashboardView.grid) {
            this.update_screenshot_url();
        }
    }

    selectAllDevices(checked: boolean): void {
        this._bAllDeviceSelectedUnderCurrentPage = checked;
        this._displayDevListByFilter.forEach(d => d.isSelect = checked);
        this.updateSelectedDeviceAmount();
    }

    selectDevice(d: DeviceInfo, checked: boolean): void {
        d.isSelect = checked;
        this._bAllDeviceSelectedUnderCurrentPage = d.isSelect ? this.is_all_devices_selected_under_current_page() : false;
        this.updateSelectedDeviceAmount();
    }

    isTableViewShrink(): boolean {
        return this._devGroupSwitch == OnOffSwitch.on && this.devGroupSvc.getActiveGroup()?.id !== DEVICE_GROUP_ID_HOME;
    }

    onPageChange(currentPage: number): void {
        if (this._loading) {
            return;
        }

        if (this._currentPage !== currentPage) {
            this._currentPage = currentPage;
            this.refactorDevices(this._deviceSource, 'switchPage');
        }
    }

    private update_screenshot_url(force: boolean = false): void {
        if (this._deviceList.length === 0) { return; }

        this._displayDevListByPage.forEach((d: DeviceInfo) => { this.update_screenshot_for_device(d, force); });
    }

    private update_screenshot_for_device(dev: DeviceInfo, force: boolean = false): void {
        this._screenshotInfo[dev.virtualId] = force;
    }

    private updateSelectedDeviceAmount(): void {
        this._selectedDeviceAmountByFilter = this._displayDevListByFilter.filter(d => d.isSelect).length;
        this.onDeviceSelectionChanged.emit(this._selectedDeviceAmountByFilter);
    }

    private is_all_devices_selected_under_current_page(): boolean {
        for (const d of this._displayDevListByPage) {
            if (!d.isSelect) { return false; }
        }

        return true;
    }

    sortAscend(sortKey: string): void {
        this.sort(sortKey, SortType.ascend);
    }

    sortDescend(sortKey: string): void {
        this.sort(sortKey, SortType.descend);
    }

    private sort(sortKey: string, sortType: SortType): void {
        if (this._currentSortKey !== sortKey || this._currentSortType !== sortType) {
            this._currentSortKey = sortKey;
            this._currentSortType = sortType;
            this.refactorDevices(this._deviceSource, 'sort');
        }
    }

    onDragStart(e: DragEvent): void {
        const activeGroup: DeviceGroupInfo = this.devGroupSvc.getActiveGroup();

        e.dataTransfer.dropEffect = 'move';
        e.dataTransfer.setData('text/plain', activeGroup ? activeGroup.id : null);
        e.dataTransfer.setDragImage(this._dragImgRef.nativeElement, -20, -20);
    }

    supportBasicConfig(): boolean {
        return AppConfigService.configs.devPage.func.basicConfig.enableBatchConfig && this.accountSvc.hasScope_task_basicSetting();
    }

    supportDeviceSecurity(): boolean {
        return AppConfigService.configs.devPage.func.basicConfig.enableBatchConfig && this.accountSvc.hasScope_task_securitySetting();
    }

    supportFirmwareUpdate(): boolean {
        return AppConfigService.configs.devPage.func.enableFwUpdate && this.accountSvc.hasScope_task_firmwareUpdate();
    }

    supportIAdeaCareAPKUpdate(): boolean {
        return AppConfigService.configs.devPage.func.enableApkUpdate && this.accountSvc.hasScope_task_iadeaCareApkUpdate();
    }

    supportReboot(): boolean {
        return AppConfigService.configs.devPage.func.enableReboot && this.accountSvc.hasScope_task_reboot();
    }

    supportTroubleshoot(): boolean {
        return AppConfigService.configs.devPage.func.enableTroubleshoot && this.accountSvc.hasScope_task_troubleshoot();
    }

    supportClearCache(): boolean {
        return AppConfigService.configs.devPage.func.enableClearCache && this.accountSvc.hasScope_task_clearCache();
    }

    supportClearAppData(): boolean {
        return AppConfigService.configs.devPage.func.enableClearAppData && this.accountSvc.hasScope_task_clearAppData();
    }

    supportBatchCAScript(): boolean {
        return AppConfigService.configs.devPage.func.enableBatchCAScript && (this.accountSvc.hasScope_enterprise_admin() || this.accountSvc.hasScope_enterprise_editor());
    }

    private refactorByDeviceGroup(sourceDevices: DeviceInfo[]): { activeGroup: DeviceGroupInfo, devicesUnderGroup: DeviceInfo[] } {
        let devicesUnderGroup: DeviceInfo[] = sourceDevices;
        let activeGroup: DeviceGroupInfo;

        if (this._devGroupSwitch === OnOffSwitch.on) {
            activeGroup = this.devGroupSvc.getActiveGroup();
            if (activeGroup) {
                if (activeGroup.id !== DEVICE_GROUP_ID_HOME) {
                    devicesUnderGroup = sourceDevices.filter(d => d.groupID === activeGroup.id);
                }
            }
        }

        return { activeGroup: activeGroup, devicesUnderGroup: devicesUnderGroup };
    }

    private refactorDevices(sources: DeviceInfo[], reason?: string, options?: { skipCommonFilter?: boolean, forceUpdateScreenshot?: boolean }): void {
        Logger.logInfo('devPicker', 'refactor', `refactor devices with ${reason}`, options);
        // all source devices
        this._displayDevListByDevGroup = sources; //.slice();
        // filter by device group
        if (!options?.skipCommonFilter) {
            const { activeGroup, devicesUnderGroup } = this.refactorByDeviceGroup(this._displayDevListByDevGroup);
            this._displayDevListByDevGroup = devicesUnderGroup;
        }

        this._displayDevListByFilter = this._displayDevListByDevGroup.slice();

        // sort
        this._displayDevListByFilter = this._displayDevListByFilter.sort((a: DeviceInfo, b: DeviceInfo) => {
            let comp_a: string;
            let comp_b: string;
            switch (this._currentSortKey) {
                case this.constantSvc.DEVKEY_NET_LAN_MAC:
                    {
                        comp_a = a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC];
                        comp_b = b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] : b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC];
                    }
                    break;
                case this.constantSvc.DEVKEY_NET_LAN_IP:
                    {
                        comp_a = a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? a.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : a.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP];
                        comp_b = b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_CONNECTED] ? b.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_IP] : b.currentSettings[this.constantSvc.DEVKEY_NET_LAN_IP];
                    }
                    break;
                default:
                    {
                        comp_a = a.currentSettings[this._currentSortKey];
                        comp_b = b.currentSettings[this._currentSortKey];
                    }
                    break;
            }

            if (comp_a === comp_b) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return comp_a >= comp_b ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return comp_a >= comp_b ? -1 : 1;
                    }
            }
        });

        // filter by page
        if (this._displayDevListByFilter.length > this._numberInPage) {
            const startIndex = (this._currentPage - 1) * this._numberInPage;
            this._displayDevListByPage = this._displayDevListByFilter.slice(startIndex, startIndex + this._numberInPage);
        }
        else {
            this._displayDevListByPage = this._displayDevListByFilter.slice();
        }

        if (this._viewType === DashboardView.grid) {
            this.update_screenshot_url(options?.forceUpdateScreenshot);
        }

        this._bAllDeviceSelectedUnderCurrentPage = this.is_all_devices_selected_under_current_page();
        this.updateSelectedDeviceAmount();
    }

    playDevFunc(funcName: string): void {
        const devFuncItem: DevFuncItem = this.devFuncSvc.getFunctionByName(funcName);
        if (devFuncItem) {
            const viewContainerRef = this.devFuncHost.viewContainerRef;
            viewContainerRef.clear();
            const componentRef = viewContainerRef.createComponent(devFuncItem.component);

            (<DevFuncInterface>componentRef.instance).title = devFuncItem.title;
            (<DevFuncInterface>componentRef.instance).devices = this._displayDevListByFilter.filter(d => d.isSelect && d.virtualId);
            (<DevFuncInterface>componentRef.instance).minWidth = 1000;
        }
    }
}