import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DeviceInfo } from '../../device/data/device-info';
import { DLG_NAME_FIRMWARE_UPDATE, ConfigDialogPage } from '../base/base-config-data';
import { FwUpdateMethod, FirmwareUpdateScheduleInfo } from './firmware-data';
import { LicenseScopeType } from '../../license/license.data';
import { HelperLib } from '../../../lib/common/helper.lib';
import { Logger } from '../../../lib/common/logger';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class FirmwareUpdateFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this.className = 'Firmware-update-form';
        this._dialogName = DLG_NAME_FIRMWARE_UPDATE;
        this._licenseScopeType = LicenseScopeType.firmwareUpdate;

        super.ngOnInit();
    }

    protected allowGoNext(): boolean {
        if (this._page === ConfigDialogPage.action && this._actionData.fwUpdateMethod === FwUpdateMethod.Unknown) {
            return false;
        }

        return super.allowGoNext();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            this._actionData.fwUpdateSchedule = this._actionData.fwUpdateSchedule || new FirmwareUpdateScheduleInfo();
            this._actionData.devices = this._legalDevices; // DeviceInfo[]
            this._actionData.fwStatusMap = this._actionData.fwStatusMap || {}; // { [id: string]: { status: string, needUpdate: boolean } }
            this._actionData.fwUpdateMethod = this._actionData.fwUpdateMethod || FwUpdateMethod.Unknown;
            this._actionData.customDownloadLink = this._actionData.customDownloadLink || '';
        }

        super.goNext();
    }

    protected submit(): void {
        super.submit();

        Logger.logInfo(this.className, 'submit', 'Submit data = ', this._actionData);
        if (!this._actionData) {
            Logger.logError(this.className, 'submit', 'Firmware actionData is empty');
            return;
        }

        this.doFirmwareUpdate();
    }

    private doFirmwareUpdate(): void {
        Logger.logInfo(this.className, 'doFirmwareUpdate', 'Start firmware update');

        const reqDataList: { device: DeviceInfo, downloadLink: string, downloadVersion?: string, md5?: string }[] = [];

        this._actionData.devices.forEach((dev: DeviceInfo) => {
            const status: { status: string, needUpdate: boolean, link?: string, version?: string } = this._actionData.fwStatusMap[dev.virtualId];
            if (!status.needUpdate) { return; }

            let downloadLink: string = '';
            switch (this._actionData.fwUpdateMethod) {
                case FwUpdateMethod.FromURL:
                    {
                        downloadLink = this._actionData.customDownloadLink;
                    }
                    break;
                case FwUpdateMethod.Latest:
                    {
                        downloadLink = status.link;
                    }
                    break;
            }

            reqDataList.push({ device: dev, downloadLink: downloadLink });
        });

        let scheduleDate: Date;
        if (this._actionData.fwUpdateSchedule) {
            const schedule: FirmwareUpdateScheduleInfo = this._actionData.fwUpdateSchedule as FirmwareUpdateScheduleInfo;
            if (schedule?.enabled) {
                scheduleDate = schedule.toDate();
            }
        }

        this.devSvc.batchFirmwareUpdate(reqDataList, scheduleDate).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}