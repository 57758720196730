import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { SavedAdvanceFilterRawData } from './api.advFilter.data';


export class APIListSavedFilterManager extends APIBaseManager<void, void, void, SavedAdvanceFilterRawData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_ListSavedFilter';
        this._apiMethod = 'GET';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'advancedFilters';
    }
}