import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';
import { UIElementModule } from '../../../uiElement/uiElement.module';
import { UserOverviewComponent } from './user-overview.component';
import { UserService } from './user.service';
import { UserDetailComponent } from './user-detail.component';
import { UserRemoveDlgComponent } from './dlg/user/user-dlg-remove.component';
import { UserResetPwdDlgComponent } from './dlg/user/user-dlg-resetPwd.component';
import { UserInviteDlgComponent } from './dlg/user/user-dlg-invite.component';
import { UserConfirmEditDlgComponent } from './dlg/user/user-dlg-editConfirm.component';
import { UserBatchInviteDlgComponent } from './dlg/user/user-dlg-inviteBulk.component';
import { UserPolicyScopeComponent } from './policy/comp/user-policy-scope.component';
import { UserPolicyOverviewComponent } from './policy/user-policy-overview.component';
import { UserPolicyDetailComponent } from './policy/comp/user-policy-detail.component';
import { UserGroupViewComponent } from './group/user-group-view.component';
import { UserGroupDetailComponent } from './group/user-group-detail.component';
import { UserChangeGroupDlgComponent } from './dlg/user/user-dlg-changeGroup.component';
import { UserGroupRemoveDlgComponent } from './dlg/group/user-group-dlg-remove.component';
import { UserGroupNewDlgComponent } from './dlg/group/user-group-dlg-new.component';
import { UserPickupComponent } from './user-pickup.component';
import { UserBaseDlgComponent } from './dlg/user-dlg-base.component';
import { UserDlgFuncDirective } from './dlg/user-dlg-func.directive';
import { UserDlgFuncService } from './dlg/user-dlg-func.service';
import { UserRemoveDlgContentComponent } from './dlg/user/user-dlg-remove-content.component';
import { UserInviteDlgContentComponent } from './dlg/user/user-dlg-invite-content.component';
import { UserInviteBulkDlgContentComponent } from './dlg/user/user-dlg-inviteBulk-content.component';
import { UserChangeGroupDlgContentComponent } from './dlg/user/user-dlg-changeGroup-content.component';
import { UserResetPwdDlgContentComponent } from './dlg/user/user-dlg-resetPwd-content.component';
import { UserConfirmEditDlgContentComponent } from './dlg/user/user-dlg-editConfirm-content.component';
import { UserDlgContentFuncService } from './dlg/user-dlg-content-func.service';
import { UserGroupRemoveDlgContentComponent } from './dlg/group/user-group-dlg-remove-content.component';
import { UserGroupNewDlgContentComponent } from './dlg/group/user-group-dlg-new-content.component';
import { DeviceGroupModule } from '../../device/group/dev-group.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        CustomPipeModule,
        TranslateModule,
        UIElementModule,
        DeviceGroupModule
    ],
    providers: [
        UserService,
        UserDlgFuncService,
        UserDlgContentFuncService
    ],
    declarations: [
        UserOverviewComponent,
        UserDetailComponent,
        UserRemoveDlgComponent,
        UserResetPwdDlgComponent,
        UserConfirmEditDlgComponent,
        UserInviteDlgComponent,
        UserBatchInviteDlgComponent,
        UserChangeGroupDlgComponent,
        UserPolicyOverviewComponent,
        UserPolicyDetailComponent,
        UserPolicyScopeComponent,
        UserPickupComponent,
        UserGroupViewComponent,
        UserGroupDetailComponent,
        UserGroupNewDlgComponent,
        UserGroupNewDlgContentComponent,
        UserGroupRemoveDlgComponent,
        UserGroupRemoveDlgContentComponent,
        UserBaseDlgComponent,
        UserDlgFuncDirective,
        UserRemoveDlgContentComponent,
        UserInviteDlgContentComponent,
        UserInviteBulkDlgContentComponent,
        UserChangeGroupDlgContentComponent,
        UserResetPwdDlgContentComponent,
        UserConfirmEditDlgContentComponent
    ],
    exports: [
        UserPolicyScopeComponent,
        UserPolicyDetailComponent
    ]
})
export class UserModule { }