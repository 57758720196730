import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { SavedAdvanceFilterRawData } from './api.advFilter.data';

export type IUpdateSavedFilterPathParameter = {
    advancedFilterID: string;
};

export class APIUpdateSavedFilterManager extends APIBaseManager<IUpdateSavedFilterPathParameter, void, { savedAdvancedFilter: SavedAdvanceFilterRawData }, SavedAdvanceFilterRawData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateSavedFilter';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: IUpdateSavedFilterPathParameter, queryParameters: void): string {
        return super.getRequestURL() + `advancedFilters/${pathParameters.advancedFilterID}`;
    }
}