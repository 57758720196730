import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './app-config.model';
import { Logger } from './lib/common/logger';

@Injectable()
export class AppConfigService {
    static configs: IAppConfig;

    constructor(private http: HttpClient) { }

    load() {
        const commonEnvFile = 'assets/config/config.common.json';
        return new Promise<void>((resolve, reject) => {
            this.http.get<IAppConfig>(commonEnvFile).toPromise().then((commonRes: IAppConfig) => {
                AppConfigService.configs = commonRes;
                const specificEnvFile = `assets/config/config.${environment.name}.json`;
                this.http.get<IAppConfig>(specificEnvFile).toPromise().then((specificRes: IAppConfig) => {
                    //common setting
                    if (specificRes.common) {
                        if (!AppConfigService.configs.common) {
                            AppConfigService.configs.common = specificRes.common;
                        }
                        else {
                            AppConfigService.configs.common.buildTarget = specificRes.common.buildTarget || AppConfigService.configs.common.buildTarget;
                            AppConfigService.configs.common.company = specificRes.common.company || AppConfigService.configs.common.company;
                            AppConfigService.configs.common.appName = specificRes.common.appName || AppConfigService.configs.common.appName;
                            AppConfigService.configs.common.appVersion = specificRes.common.appVersion || AppConfigService.configs.common.appVersion;
                            AppConfigService.configs.common.releaseDate = specificRes.common.releaseDate || AppConfigService.configs.common.releaseDate;
                            AppConfigService.configs.common.logLevel = specificRes.common.logLevel ?? AppConfigService.configs.common.logLevel;

                            if (specificRes.common.theme) {
                                if (!AppConfigService.configs.common.theme) {
                                    AppConfigService.configs.common.theme = specificRes.common.theme;
                                }
                                else {
                                    AppConfigService.configs.common.theme.enabled = specificRes.common.theme.enabled ?? AppConfigService.configs.common.theme.enabled;
                                }
                            }

                            if (specificRes.common.reCaptcha) {
                                if (!AppConfigService.configs.common.reCaptcha) {
                                    AppConfigService.configs.common.reCaptcha = specificRes.common.reCaptcha;
                                }
                                else {
                                    AppConfigService.configs.common.reCaptcha.key = specificRes.common.reCaptcha.key || AppConfigService.configs.common.reCaptcha.key;
                                    AppConfigService.configs.common.reCaptcha.enabled = specificRes.common.reCaptcha.enabled ?? AppConfigService.configs.common.reCaptcha.enabled;
                                }
                            }
                        }
                    }

                    //server
                    if (specificRes.server) {
                        if (!AppConfigService.configs.server) {
                            AppConfigService.configs.server = specificRes.server;
                        }
                        else {
                            //api server
                            if (specificRes.server.api) {
                                if (!AppConfigService.configs.server.api) {
                                    AppConfigService.configs.server.api = specificRes.server.api;
                                }
                                else {
                                    AppConfigService.configs.server.api.baseUrl = specificRes.server.api.baseUrl || AppConfigService.configs.server.api.baseUrl;
                                    AppConfigService.configs.server.api.port = specificRes.server.api.port || AppConfigService.configs.server.api.port;
                                    AppConfigService.configs.server.api.protocol = specificRes.server.api.protocol || AppConfigService.configs.server.api.protocol;
                                    AppConfigService.configs.server.api.version = specificRes.server.api.version || AppConfigService.configs.server.api.version;
                                }
                            }
                            //file server
                            if (specificRes.server.fileServer) {
                                if (!AppConfigService.configs.server.fileServer) {
                                    AppConfigService.configs.server.fileServer = specificRes.server.fileServer;
                                }
                                else {
                                    AppConfigService.configs.server.fileServer.baseUrl = specificRes.server.fileServer.baseUrl || AppConfigService.configs.server.fileServer.baseUrl;
                                    AppConfigService.configs.server.fileServer.port = specificRes.server.fileServer.port || AppConfigService.configs.server.fileServer.port;
                                    AppConfigService.configs.server.fileServer.protocol = specificRes.server.fileServer.protocol || AppConfigService.configs.server.fileServer.protocol;
                                }
                            }
                            //firmware server
                            AppConfigService.configs.server.firmware = specificRes.server.firmware || AppConfigService.configs.server.firmware;
                            //apk server
                            AppConfigService.configs.server.apk = specificRes.server.apk || AppConfigService.configs.server.apk;
                        }
                    }

                    //setting
                    if (specificRes.setting) {
                        if (!AppConfigService.configs.setting) {
                            AppConfigService.configs.setting = specificRes.setting;
                        }
                        else {
                            AppConfigService.configs.setting.logoutUrl = specificRes.setting.logoutUrl || AppConfigService.configs.setting.logoutUrl;
                            if (specificRes.setting.login) {
                                if (!AppConfigService.configs.setting.login) {
                                    AppConfigService.configs.setting.login = specificRes.setting.login;
                                }
                                else {
                                    if (specificRes.setting.login.methods) {
                                        AppConfigService.configs.setting.login.methods = specificRes.setting.login.methods;
                                    }
                                }
                            }
                        }
                    }

                    //preference
                    if (specificRes.preference) {
                        if (!AppConfigService.configs.preference) {
                            AppConfigService.configs.preference = specificRes.preference;
                        }
                        else {
                            if (specificRes.preference.home) {
                                if (!AppConfigService.configs.preference.home) {
                                    AppConfigService.configs.preference.home = specificRes.preference.home;
                                }
                                else {
                                    AppConfigService.configs.preference.home.overviewLayout = specificRes.preference.home.overviewLayout || AppConfigService.configs.preference.home.overviewLayout;
                                }
                            }
                        }
                    }

                    //trigger datas
                    if (specificRes.trigger) {
                        if (!AppConfigService.configs.trigger) {
                            AppConfigService.configs.trigger = specificRes.trigger;
                        }
                        else {
                            AppConfigService.configs.trigger.idleNoticePeriod = specificRes.trigger.idleNoticePeriod || AppConfigService.configs.trigger.idleNoticePeriod;
                            AppConfigService.configs.trigger.tokenRefreshPeriod = specificRes.trigger.tokenRefreshPeriod || AppConfigService.configs.trigger.tokenRefreshPeriod;
                            AppConfigService.configs.trigger.heartbeatDisconnectPeriod = specificRes.trigger.heartbeatDisconnectPeriod || AppConfigService.configs.trigger.heartbeatDisconnectPeriod;
                            AppConfigService.configs.trigger.heartbeatOfflinePeriod = specificRes.trigger.heartbeatOfflinePeriod || AppConfigService.configs.trigger.heartbeatOfflinePeriod;
                        }
                    }

                    //login page
                    if (specificRes.loginPage) {
                        if (!AppConfigService.configs.loginPage) {
                            AppConfigService.configs.loginPage = specificRes.loginPage;
                        }
                        else {
                            AppConfigService.configs.loginPage.forgetPwdEnabled = specificRes.loginPage.forgetPwdEnabled ?? AppConfigService.configs.loginPage.forgetPwdEnabled;
                            AppConfigService.configs.loginPage.signupEnabled = specificRes.loginPage.signupEnabled ?? AppConfigService.configs.loginPage.signupEnabled;
                        }
                    }

                    //dev page
                    if (specificRes.devPage) {
                        if (!AppConfigService.configs.devPage) {
                            AppConfigService.configs.devPage = specificRes.devPage;
                        }
                        else {
                            //settings
                            AppConfigService.configs.devPage.pageLimit = specificRes.devPage.pageLimit || AppConfigService.configs.devPage.pageLimit;
                            //func
                            if (specificRes.devPage.func) {
                                if (!AppConfigService.configs.devPage.func) {
                                    AppConfigService.configs.devPage.func = specificRes.devPage.func;
                                }
                                else {
                                    AppConfigService.configs.devPage.func.enableAlert = specificRes.devPage.func.enableAlert ?? AppConfigService.configs.devPage.func.enableAlert;
                                    AppConfigService.configs.devPage.func.enableApkUpdate = specificRes.devPage.func.enableApkUpdate ?? AppConfigService.configs.devPage.func.enableApkUpdate;
                                    AppConfigService.configs.devPage.func.enableFwUpdate = specificRes.devPage.func.enableFwUpdate ?? AppConfigService.configs.devPage.func.enableFwUpdate;
                                    AppConfigService.configs.devPage.func.enableDevicePair = specificRes.devPage.func.enableDevicePair ?? AppConfigService.configs.devPage.func.enableDevicePair;
                                    AppConfigService.configs.devPage.func.enableReboot = specificRes.devPage.func.enableReboot ?? AppConfigService.configs.devPage.func.enableReboot;
                                    AppConfigService.configs.devPage.func.enableScreenshotRefresh = specificRes.devPage.func.enableScreenshotRefresh ?? AppConfigService.configs.devPage.func.enableScreenshotRefresh;
                                    AppConfigService.configs.devPage.func.enableRemoteControl = specificRes.devPage.func.enableRemoteControl ?? AppConfigService.configs.devPage.func.enableRemoteControl;
                                    AppConfigService.configs.devPage.func.enableClearCache = specificRes.devPage.func.enableClearCache ?? AppConfigService.configs.devPage.func.enableClearCache;
                                    AppConfigService.configs.devPage.func.enableClearAppData = specificRes.devPage.func.enableClearAppData ?? AppConfigService.configs.devPage.func.enableClearAppData;
                                    AppConfigService.configs.devPage.func.enableTroubleshoot = specificRes.devPage.func.enableTroubleshoot ?? AppConfigService.configs.devPage.func.enableTroubleshoot;
                                    AppConfigService.configs.devPage.func.enableUserActivity = specificRes.devPage.func.enableUserActivity ?? AppConfigService.configs.devPage.func.enableUserActivity;
                                    AppConfigService.configs.devPage.func.enableSecurity = specificRes.devPage.func.enableSecurity ?? AppConfigService.configs.devPage.func.enableSecurity;
                                    AppConfigService.configs.devPage.func.enableLock = specificRes.devPage.func.enableLock ?? AppConfigService.configs.devPage.func.enableLock;
                                    AppConfigService.configs.devPage.func.enableUnlock = specificRes.devPage.func.enableUnlock ?? AppConfigService.configs.devPage.func.enableUnlock;
                                    AppConfigService.configs.devPage.func.enableCAScript = specificRes.devPage.func.enableCAScript ?? AppConfigService.configs.devPage.func.enableCAScript;
                                    AppConfigService.configs.devPage.func.enableBatchCAScript = specificRes.devPage.func.enableBatchCAScript ?? AppConfigService.configs.devPage.func.enableBatchCAScript;            
                                    //basic config
                                    if (specificRes.devPage.func.basicConfig) {
                                        if (!AppConfigService.configs.devPage.func.basicConfig) {
                                            AppConfigService.configs.devPage.func.basicConfig = specificRes.devPage.func.basicConfig;
                                        }
                                        else {
                                            AppConfigService.configs.devPage.func.basicConfig.enableSingleConfig = specificRes.devPage.func.basicConfig.enableSingleConfig ?? AppConfigService.configs.devPage.func.basicConfig.enableSingleConfig;
                                            AppConfigService.configs.devPage.func.basicConfig.enableBatchConfig = specificRes.devPage.func.basicConfig.enableBatchConfig ?? AppConfigService.configs.devPage.func.basicConfig.enableBatchConfig;
                                            if (specificRes.devPage.func.basicConfig.element) {
                                                if (!AppConfigService.configs.devPage.func.basicConfig.element) {
                                                    AppConfigService.configs.devPage.func.basicConfig.element = specificRes.devPage.func.basicConfig.element;
                                                }
                                                else {
                                                    AppConfigService.configs.devPage.func.basicConfig.element.contentUrl = specificRes.devPage.func.basicConfig.element.contentUrl ?? AppConfigService.configs.devPage.func.basicConfig.element.contentUrl;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.appStartExtraOption = specificRes.devPage.func.basicConfig.element.appStartExtraOption ?? AppConfigService.configs.devPage.func.basicConfig.element.appStartExtraOption;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.overlay = specificRes.devPage.func.basicConfig.element.overlay ?? AppConfigService.configs.devPage.func.basicConfig.element.overlay;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.dailyReboot = specificRes.devPage.func.basicConfig.element.dailyReboot ?? AppConfigService.configs.devPage.func.basicConfig.element.dailyReboot;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.name = specificRes.devPage.func.basicConfig.element.name ?? AppConfigService.configs.devPage.func.basicConfig.element.name;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.orientation = specificRes.devPage.func.basicConfig.element.orientation ?? AppConfigService.configs.devPage.func.basicConfig.element.orientation;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.resolution = specificRes.devPage.func.basicConfig.element.resolution ?? AppConfigService.configs.devPage.func.basicConfig.element.resolution;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.timezone = specificRes.devPage.func.basicConfig.element.timezone ?? AppConfigService.configs.devPage.func.basicConfig.element.timezone;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.timeserver = specificRes.devPage.func.basicConfig.element.timeserver ?? AppConfigService.configs.devPage.func.basicConfig.element.timeserver;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.HDCP = specificRes.devPage.func.basicConfig.element.HDCP ?? AppConfigService.configs.devPage.func.basicConfig.element.HDCP;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.adb = specificRes.devPage.func.basicConfig.element.adb ?? AppConfigService.configs.devPage.func.basicConfig.element.adb;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.volume = specificRes.devPage.func.basicConfig.element.volume ?? AppConfigService.configs.devPage.func.basicConfig.element.volume;
                                                    if (specificRes.devPage.func.basicConfig.element.screenOff) {
                                                        if (!AppConfigService.configs.devPage.func.basicConfig.element.screenOff) {
                                                            AppConfigService.configs.devPage.func.basicConfig.element.screenOff = specificRes.devPage.func.basicConfig.element.screenOff;
                                                        }
                                                        else {
                                                            AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled = specificRes.devPage.func.basicConfig.element.screenOff.enabled ?? AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled;
                                                        }
                                                    }
                                                    AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback = specificRes.devPage.func.basicConfig.element.maintenancePlayback ?? AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.failSafe = specificRes.devPage.func.basicConfig.element.failSafe ?? AppConfigService.configs.devPage.func.basicConfig.element.failSafe;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.screenSaver = specificRes.devPage.func.basicConfig.element.screenSaver ?? AppConfigService.configs.devPage.func.basicConfig.element.screenSaver;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.powersave = specificRes.devPage.func.basicConfig.element.powersave ?? AppConfigService.configs.devPage.func.basicConfig.element.powersave;
                                                    AppConfigService.configs.devPage.func.basicConfig.element.brightness = specificRes.devPage.func.basicConfig.element.brightness ?? AppConfigService.configs.devPage.func.basicConfig.element.brightness;
                                                }
                                            }
                                        }
                                    }
                                    //net config
                                    if (specificRes.devPage.func.netConfig) {
                                        if (!AppConfigService.configs.devPage.func.netConfig) {
                                            AppConfigService.configs.devPage.func.netConfig = specificRes.devPage.func.netConfig;
                                        }
                                        else {
                                            AppConfigService.configs.devPage.func.netConfig.enableSingleConfig = specificRes.devPage.func.netConfig.enableSingleConfig ?? AppConfigService.configs.devPage.func.netConfig.enableSingleConfig;
                                            AppConfigService.configs.devPage.func.netConfig.enableBatchConfig = specificRes.devPage.func.netConfig.enableBatchConfig ?? AppConfigService.configs.devPage.func.netConfig.enableBatchConfig;
                                            if (specificRes.devPage.func.netConfig.element) {
                                                if (!AppConfigService.configs.devPage.func.netConfig.element) {
                                                    AppConfigService.configs.devPage.func.netConfig.element = specificRes.devPage.func.netConfig.element;
                                                }
                                                else {
                                                    AppConfigService.configs.devPage.func.netConfig.element.ethernet = specificRes.devPage.func.netConfig.element.ethernet ?? AppConfigService.configs.devPage.func.netConfig.element.ethernet;
                                                    AppConfigService.configs.devPage.func.netConfig.element.wifi = specificRes.devPage.func.netConfig.element.wifi ?? AppConfigService.configs.devPage.func.netConfig.element.wifi;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //setting page
                    if (specificRes.settingPage) {
                        if (!AppConfigService.configs.settingPage) {
                            AppConfigService.configs.settingPage = specificRes.settingPage;
                        }
                        else {
                            AppConfigService.configs.settingPage.enabled = specificRes.settingPage.enabled ?? AppConfigService.configs.settingPage.enabled;
                            AppConfigService.configs.settingPage.defaultTab = specificRes.settingPage.defaultTab || AppConfigService.configs.settingPage.defaultTab;
                            //system
                            if (specificRes.settingPage.system) {
                                if (!AppConfigService.configs.settingPage.system) {
                                    AppConfigService.configs.settingPage.system = specificRes.settingPage.system;
                                }
                                else {
                                    AppConfigService.configs.settingPage.system.enabled = specificRes.settingPage.system.enabled ?? AppConfigService.configs.settingPage.system.enabled;
                                    AppConfigService.configs.settingPage.system.generalConfigEnabled = specificRes.settingPage.system.generalConfigEnabled ?? AppConfigService.configs.settingPage.system.generalConfigEnabled;
                                    AppConfigService.configs.settingPage.system.advanceConfigEnabled = specificRes.settingPage.system.advanceConfigEnabled ?? AppConfigService.configs.settingPage.system.advanceConfigEnabled;
                                }
                            }
                            //alert
                            if (specificRes.settingPage.alert) {
                                if (!AppConfigService.configs.settingPage.alert) {
                                    AppConfigService.configs.settingPage.alert = specificRes.settingPage.alert;
                                }
                                else {
                                    AppConfigService.configs.settingPage.alert.enabled = specificRes.settingPage.alert.enabled ?? AppConfigService.configs.settingPage.alert.enabled;
                                }
                            }
                            //report
                            if (specificRes.settingPage.report) {
                                if (!AppConfigService.configs.settingPage.report) {
                                    AppConfigService.configs.settingPage.report = specificRes.settingPage.report;
                                }
                                else {
                                    AppConfigService.configs.settingPage.report.enabled = specificRes.settingPage.report.enabled ?? AppConfigService.configs.settingPage.report.enabled;
                                }
                            }
                            //lan tool
                            if (specificRes.settingPage.lanTool) {
                                if (!AppConfigService.configs.settingPage.lanTool) {
                                    AppConfigService.configs.settingPage.lanTool = specificRes.settingPage.lanTool;
                                }
                                else {
                                    AppConfigService.configs.settingPage.lanTool.enabled = specificRes.settingPage.lanTool.enabled ?? AppConfigService.configs.settingPage.lanTool.enabled;
                                    AppConfigService.configs.settingPage.lanTool.winUri = specificRes.settingPage.lanTool.winUri || AppConfigService.configs.settingPage.lanTool.winUri;
                                    AppConfigService.configs.settingPage.lanTool.macUri = specificRes.settingPage.lanTool.macUri || AppConfigService.configs.settingPage.lanTool.macUri;
                                    AppConfigService.configs.settingPage.lanTool.webUri = specificRes.settingPage.lanTool.webUri || AppConfigService.configs.settingPage.lanTool.webUri;
                                }
                            }
                        }
                    }

                    //troubleshoot page
                    if (specificRes.troubleshootPage) {
                        if (!AppConfigService.configs.troubleshootPage) {
                            AppConfigService.configs.troubleshootPage = specificRes.troubleshootPage;
                        }
                        else {
                            AppConfigService.configs.troubleshootPage.enabled = specificRes.troubleshootPage.enabled ?? AppConfigService.configs.troubleshootPage.enabled;
                        }
                    }

                    //license page
                    if (specificRes.licensePage) {
                        if (!AppConfigService.configs.licensePage) {
                            AppConfigService.configs.licensePage = specificRes.licensePage;
                        }
                        else {
                            AppConfigService.configs.licensePage.enabled = specificRes.licensePage.enabled ?? AppConfigService.configs.licensePage.enabled;
                            //element
                            if (specificRes.licensePage.element) {
                                if (!AppConfigService.configs.licensePage.element) {
                                    AppConfigService.configs.licensePage.element = specificRes.licensePage.element;
                                }
                                else {
                                    AppConfigService.configs.licensePage.element.enabledAddLicense = specificRes.licensePage.element.enabledAddLicense ?? AppConfigService.configs.licensePage.element.enabledAddLicense;
                                    AppConfigService.configs.licensePage.element.enabledReallocateLicense = specificRes.licensePage.element.enabledReallocateLicense ?? AppConfigService.configs.licensePage.element.enabledReallocateLicense;
                                    AppConfigService.configs.licensePage.element.enabledLicenseKnowledge = specificRes.licensePage.element.enabledLicenseKnowledge ?? AppConfigService.configs.licensePage.element.enabledLicenseKnowledge
                                }
                            }
                        }
                    }

                    Logger.setLogLevel(AppConfigService.configs.common.logLevel);
                    Logger.logInfo('AppConfigSvc', 'load', 'Config = ', AppConfigService.configs);
                    resolve();
                }).catch((error: any) => {
                    reject(`Could not load file '${specificEnvFile}': ${JSON.stringify(error)}`);
                });
            }).catch((error: any) => {
                reject(`Could not load file '${commonEnvFile}': ${JSON.stringify(error)}`);
            });
        });
    }
}