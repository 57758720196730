import { Injectable } from '@angular/core';
import { DEV_SAVED_FILTER_FUNC_CREATE, DEV_SAVED_FILTER_FUNC_REMOVE, DEV_SAVED_FILTER_FUNC_EDIT, DeviceSavedFilterFuncItem, DEV_SAVED_FILTER_FUNC_APPLY } from './dev-saved-filter-func.def';
import { DeviceSavedFilterEditDlgComponent } from './dev-saved-filter-edit-dlg.component';
import { DeviceSavedFilterRemoveDlgComponent } from './dev-saved-filter-remove-dlg.component';


@Injectable()
export class DeviceSavedFilterFuncService {
  private _items: DeviceSavedFilterFuncItem[] = [];

  constructor() {
    this._items = [
      new DeviceSavedFilterFuncItem(DeviceSavedFilterEditDlgComponent, DEV_SAVED_FILTER_FUNC_APPLY, 'Apply advanced filters'),
      new DeviceSavedFilterFuncItem(DeviceSavedFilterEditDlgComponent, DEV_SAVED_FILTER_FUNC_CREATE, 'Create saved filters'),
      new DeviceSavedFilterFuncItem(DeviceSavedFilterEditDlgComponent, DEV_SAVED_FILTER_FUNC_EDIT, 'Update saved filters'),
      new DeviceSavedFilterFuncItem(DeviceSavedFilterRemoveDlgComponent, DEV_SAVED_FILTER_FUNC_REMOVE, 'Remove saved filter')
    ];
  }

  getAllItems(): DeviceSavedFilterFuncItem[] {
    return this._items;
  }

  getItemByName(name: string): DeviceSavedFilterFuncItem {
    return this._items.find(i => i.name === name);
  }
}
