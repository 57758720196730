import { Injectable } from '@angular/core';
import { DeviceGroupFuncItem } from './group-func.def';
import { TranslateService } from '../../../../../app/translate/translate.service';
import { DeviceGroupCreateDlgComponent } from './group-dlg-create.component';
import { DeviceGroupDeleteDlgComponent } from './group-dlg-delete.component';
import { DeviceGroupMoveDlgComponent } from './group-dlg-move.component';
import { DEVICE_GROUP_FUNC_CREATE, DEVICE_GROUP_FUNC_DELETE, DEVICE_GROUP_FUNC_EXCLUDE, DEVICE_GROUP_FUNC_MOVE } from '../dev-group.data';
import { DeviceGroupExcludeDlgComponent } from './group-dlg-exclude.component';

@Injectable()
export class DeviceGroupFuncService {
  private _funcs: DeviceGroupFuncItem[] = [];

  constructor(private translateSvc: TranslateService) {
    this._funcs = [
      new DeviceGroupFuncItem(DeviceGroupCreateDlgComponent, DEVICE_GROUP_FUNC_CREATE, 'Create group'),
      new DeviceGroupFuncItem(DeviceGroupDeleteDlgComponent, DEVICE_GROUP_FUNC_DELETE, 'Delete group'),
      new DeviceGroupFuncItem(DeviceGroupMoveDlgComponent, DEVICE_GROUP_FUNC_MOVE, 'Move group'),
      new DeviceGroupFuncItem(DeviceGroupExcludeDlgComponent, DEVICE_GROUP_FUNC_EXCLUDE, 'Remove from group')
    ];
  }

  getAllFuncs(): DeviceGroupFuncItem[] {
    return this._funcs;
  }

  getFunctionByName(name: string): DeviceGroupFuncItem {
    return this._funcs.find(i => i.name === name);
  }
}
