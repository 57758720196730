<div [id]="_dialogName" class='modal-dialog config-dlg' role="document">
  <div class='modal-content'>
    <div class='modal-header'>
      <span class="modal-title">{{ _title | capitalFirstWord }}</span>
      <button #dlgClose type="button" class="close" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- for checking page, unified template -->
      <div *ngIf="_page === _enumPage.checking" class="container-fluid">
        <ng-template [ngIf]="_loading">
          <div class="progress">
            <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
              Validating license for using {{ _title }} function</div>
          </div>
        </ng-template>
        <ng-template [ngIf]="!_loading">
          <div *ngIf="_devices.length === 0; else templateHasDevice">
            <div class="warning">Please select a device</div>
          </div>
          <ng-template #templateHasDevice>
            <div *ngIf="_bSpecific; else templateMultiDevice" class="container-fluid">
              <div *ngIf="_licenseForbiddenDevices.length > 0">
                Cannot use {{ _title }} function because these devices do not have valid license.
                <div class="warning">{{ _licenseForbiddenDevices | mergeDeviceNamePipe }}</div>
              </div>
              <div *ngIf="_offlineForbiddenDevices.length > 0">
                Cannot use {{ _title }} because these devices are offline.<div class="warning">
                  {{ _offlineForbiddenDevices | mergeDeviceNamePipe }}</div>
              </div>
              <p *ngIf="_confirmErrorMessage" class="warning">{{ _confirmErrorMessage }}</p>
            </div>
            <ng-template #templateMultiDevice>
              <div class="row">
                <div class="col-lg-6 col-12 px-0 pr-lg-2" [ngClass]="_legalDevices.length === 0 ? 'col-lg-12' : ''">
                  <ul class="hint-block">
                    <li *ngIf="_legal_device_count === 0 && _legalDevices.length > 0" class="warning">
                      Please select a device.
                    </li>
                    <li *ngIf="_legal_device_count > DEBUG_DEVICE_LIMIT" class="warning">
                      Support at most {{ DEBUG_DEVICE_LIMIT }} devices to do {{ _title }} at the same time. Please
                      re-select devices
                    </li>
                    <li *ngIf="_policyForbiddenDevices.length > 0">
                      Cannot use {{ _title }} function because these devices have policy applied.
                      (#{{ _policyForbiddenDevices.length }})
                      <div class="warning">{{ _policyForbiddenDevices | mergeDeviceNamePipe }}</div>
                    </li>
                    <li *ngIf="_licenseForbiddenDevices.length > 0">
                      Cannot use {{ _title }} function because these devices do not have valid license.
                      (#{{ _licenseForbiddenDevices.length }})
                      <div class="warning">{{ _licenseForbiddenDevices | mergeDeviceNamePipe }}</div>
                    </li>
                    <li *ngIf="_offlineForbiddenDevices.length > 0">
                      Cannot use {{ _title }} because these devices are offline.
                      (#{{ _offlineForbiddenDevices.length }})
                      <div class="warning">{{ _offlineForbiddenDevices | mergeDeviceNamePipe }}</div>
                    </li>
                    <li *ngIf="_confirmErrorMessage" class="warning">{{ _confirmErrorMessage }}</li>
                  </ul>
                </div>
                <div *ngIf="_legalDevices.length > 0" class="col-lg-6 col-12" [ngClass]="_policyForbiddenDevices.length + _licenseForbiddenDevices.length + _offlineForbiddenDevices.length === 0 ? 'col-lg-12': ''">
                  <li *ngIf="_legalDevices.length > 0">
                    Only devices listed on the table have valid license to use {{ _title }} function.
                    (#{{ _legalDevices.length }})
                  </li>
                  <div class="list-group my-1">
                    <div class="list-group-item list-group-header black">
                      <div class="row">
                        <div class="col title d-flex align-items-center">
                          <input type="checkbox" id="config-devs-th" class="cmn-checkbox"
                            [checked]="isAllDeviceSelected()" (change)="selectAllDevice($event)">
                          <label for="config-devs-th">
                            <svg>
                              <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select devices</span>
                          </label>
                          <span class="ml-1">Device name</span>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-content list-group-content-modal">
                      <div *ngFor="let device of _legalDevices;" class="list-group-item list-group-item-action">
                        <div class="row align-items-center">
                          <div class="col d-flex align-items-center text-break">
                            <input [id]="'config-dev-' + device.virtualId" type="checkbox" class="cmn-checkbox"
                              [checked]="device.isSelect" (change)="selectDevice(device)" />
                            <label [for]="'config-dev-' + device.virtualId">
                              <svg>
                                <use xlink:href="#checkmark" />
                              </svg>
                              <span class="sr-only">Select or deselect device {{device?.virtualName}}</span>
                            </label>
                            <span class="ml-1">{{ device?.virtualName }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
      <!-- for action template, defined by each config dialog -->
      <div>
        <ng-template dev-func-action-host></ng-template>
      </div>
      <!-- for confirm template, defined by each config dialog -->
      <div>
        <ng-template dev-func-confirm-host></ng-template>
      </div>
      <!-- for submit page, unified template -->
      <div *ngIf="_page === _enumPage.submit">
        <div class="progress">
          <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
            Updating the changes... Please wait...
          </div>
        </div>
      </div>
      <!-- for result page, unified template -->
      <div *ngIf="_page === _enumPage.result" class="container-fluid">
        <div *ngIf="_resultErrorList.length === 0 && !_errorMessage" class="row">
          <p>
            {{ _result_msg_success }}
          </p>
        </div>
        <div *ngIf="_resultErrorList.length > 0 || _errorMessage">
          <p>Some errors occurred while applying settings. Please try again.</p>
          <div *ngIf="_resultErrorList.length > 0">
            <ul class="warning-block" style="list-style:none">
              <li *ngFor="let resultError of _resultErrorList">
                <label>{{ resultError.virtualDeviceName }}</label>
                <span>&nbsp;&nbsp;:&nbsp;&nbsp;</span>
                <span>{{ resultError.errorMessage }}</span>
              </li>
            </ul>
          </div>
          <div *ngIf="_errorMessage">
            <p class="warning-block">{{ _errorMessage }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="_page !== _enumPage.result && _errorMessage" class="mt-2">
        <div class="warning-block">
          {{ _errorMessage }}
        </div>
      </div>
      <div class="row control-block d-flex justify-content-center">
        <button *ngIf="allowGoBack()" type="button" class="btn btn-sm btn-action btn-light" (click)="goBack()">
          <span class="fas fa-angle-double-left icon-left"></span>
          <span>Prev</span>
        </button>
        <button *ngIf="allowCancel()" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal"
          (click)="cancel()">
          <span class="fas fa-times-circle icon-left"></span>
          <span>Cancel</span>
        </button>
        <button *ngIf="allowGoNext()" type="button" class="btn btn-sm btn-action btn-light"
          [disabled]="!isPageValid(_page)" (click)="goNext()">
          <span>Next</span>
          <span class="fas fa-angle-double-right icon-right"></span>
        </button>
        <button *ngIf="_page === _enumPage.confirm" type="button" [disabled]="!_allowSubmit" class="btn btn-sm btn-action btn-light" (click)="goNext();submit()">
          <span class="fas fa-check-circle icon-left"></span>
          <span>Apply</span>
        </button>
        <button *ngIf="allowClose()" type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal"
          (click)="close()">
          <span class="fas fa-times-circle icon-left"></span>
          <span>Close</span>
        </button>
        <button *ngIf="allowCustomAction()" type="button" [disabled]="!isCustomActionValid()"
          class="btn btn-sm btn-action btn-light" (click)="doCustomAction()">
          <span>{{ _customActionName }}</span>
        </button>
      </div>
    </div>
  </div>
</div>