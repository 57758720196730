import { Component } from "@angular/core";
import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { IDeviceSavedFilterFuncCtrl } from "./dev-saved-filter-func.def";
import { DeviceSavedFilterInfo } from "../data/dev-saved-filter.data";
import { DeviceService } from "../../device.service";
import { AccountService } from "../../../../../app/entry/account.service";

@Component({
    templateUrl: './dev-saved-filter-remove-dlg.component.html'
})
export class DeviceSavedFilterRemoveDlgComponent extends VirtualDlgComponent implements IDeviceSavedFilterFuncCtrl {
    title: string;
    funcName: string;
    data?: DeviceSavedFilterInfo;
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _updating: boolean = false;
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private devSvc: DeviceService) {
        super(accountSvc)
    }

    removeFilter(): void {
        this._updating = true;
        this.devSvc.removeDeviceSavedFilter(this.data).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;
            if (!res.isFault) {
                this.closeDlg();
            }
            else {
                this._errorMessage = res.errorMessage;
            }
        });
    }
}