import { HttpClient } from '@angular/common/http';

import { APIWrapperBase } from '../../api.wrapper.base';
import { APIListPermissionSetManager } from './api.permissionSet.list';
import { APICreateSavedFilterManager } from './filter/api.advFilter.create';
import { APIListSavedFilterManager } from './filter/api.advFilter.list';
import { APIDeleteSavedFilterManager } from './filter/api.advFilter.delete';
import { APIUpdateSavedFilterManager } from './filter/api.advFilter.update';

export class APIOtherWrapper extends APIWrapperBase {
    ListPermissionSet: APIListPermissionSetManager;
    CreateSavedFilter: APICreateSavedFilterManager;
    ListSavedFilters: APIListSavedFilterManager;
    DeleteSavedFilter: APIDeleteSavedFilterManager;
    UpdateSavedFilter: APIUpdateSavedFilterManager;

    constructor(private http: HttpClient) {
        super();

        this.ListPermissionSet = new APIListPermissionSetManager(http);
        this.CreateSavedFilter = new APICreateSavedFilterManager(http);
        this.ListSavedFilters = new APIListSavedFilterManager(http);
        this.DeleteSavedFilter = new APIDeleteSavedFilterManager(http);
        this.UpdateSavedFilter = new APIUpdateSavedFilterManager(http);
    }

    public resetServerConfig(protocol: string, baseUrl: string, port: number): void {
        let targets = [
            this.ListPermissionSet,
            this.CreateSavedFilter,
            this.ListSavedFilters,
            this.DeleteSavedFilter,
            this.UpdateSavedFilter
        ];

        targets.forEach(t => t.resetServerConfig(protocol, baseUrl, port));
    }
}