import { DeviceGroupInfo } from "../group/dev-group.data";

export class VirtualDeviceUIInfo {
    id: string;
    name: string;
    pairingCode: string;
    pairedID: string;
    group: DeviceGroupInfo;
    status: VirtualDeviceStatusType;
    actions: VirtualDeviceActions;
    errorMessage?: number | string;

    constructor(id: string, name: string, group: DeviceGroupInfo, pairingCode: string, pairedId: string, status: VirtualDeviceStatusType, actions?: VirtualDeviceActions) {
        this.id = id;
        this.name = name;
        this.group = group;
        this.pairingCode = pairingCode;
        this.pairedID = pairedId;
        this.status = status;
        this.actions = actions;
    }
}

export class VirtualDeviceActions {
    Pair: VirtualDeviceAction;
    UnPair: VirtualDeviceAction;
    Cancel: VirtualDeviceAction;
    Delete: VirtualDeviceAction;

    constructor(allowPair: boolean, allowUnpair: boolean, allowCancel: boolean, allowDelete: boolean) {
        this.Pair = new VirtualDeviceAction(VirtualDeviceActionType.Pair, allowPair);
        this.UnPair = new VirtualDeviceAction(VirtualDeviceActionType.UnPair, allowUnpair);
        this.Cancel = new VirtualDeviceAction(VirtualDeviceActionType.Cancel, allowCancel);
        this.Delete = new VirtualDeviceAction(VirtualDeviceActionType.Delete, allowDelete);
    }

    private update_action_status(allowPair: boolean, allowUnpair: boolean, allowCancel: boolean, allowDelete: boolean) {
        this.Pair.allow = allowPair;
        this.UnPair.allow = allowUnpair;
        this.Cancel.allow = allowCancel;
        this.Delete.allow = allowDelete;
    }

    updateActionStatus(virtualDeviceStatus: VirtualDeviceStatusType): void {
        switch (virtualDeviceStatus) {
            case VirtualDeviceStatusType.None:
            case VirtualDeviceStatusType.Created:
                {
                    this.update_action_status(true, false, false, true);
                }
                break;
            case VirtualDeviceStatusType.Paired:
                {
                    this.update_action_status(false, true, false, false);
                }
                break;
            case VirtualDeviceStatusType.Syncing:
                {
                    this.update_action_status(false, true, false, false);
                }
                break;
            default:
                {
                    this.update_action_status(false, false, false, false);
                }
                break;
        }
    }

    static getActions(status: VirtualDeviceStatusType): VirtualDeviceActions {
        switch (status) {
            case VirtualDeviceStatusType.None:
            case VirtualDeviceStatusType.Created:
                {
                    return VirtualDeviceActions.getNotPairActions();
                }
            case VirtualDeviceStatusType.Paired:
                {
                    return VirtualDeviceActions.getPairedActions();
                }
            case VirtualDeviceStatusType.Syncing:
                {
                    return new VirtualDeviceActions(false, true, false, false);
                }
            default:
                {
                    return new VirtualDeviceActions(false, false, false, false);
                }
        }
    }

    static getPairedActions(): VirtualDeviceActions {
        return new VirtualDeviceActions(false, true, false, false);
    }

    static getNotPairActions(): VirtualDeviceActions {
        return new VirtualDeviceActions(true, false, false, true);
    }
}

export class VirtualDeviceAction {
    type: VirtualDeviceActionType;
    allow: boolean;

    constructor(type: VirtualDeviceActionType, allow: boolean) {
        this.type = type;
        this.allow = allow;
    }
}

export enum VirtualDeviceActionType {
    Pair = 'Pair',
    UnPair = 'UnPair',
    Cancel = 'Cancel',
    Delete = 'Delete'
}

export enum VirtualDeviceStatusType {
    None = 'None',
    Created = 'Created',
    Paired = 'Paired',
    Pairing = 'Pairing',
    Syncing = 'Syncing',
    Unpairing = 'Unpairing',
    Cancelling = 'Cancelling',
    Removing = 'Removing'
}

export enum VirtualDeviceStatusHint {
    None = 'None',
    DeviceCreated = 'DeviceCreated',
    DevicePaired = 'DevicePaired',
    DeviceSynced = 'DeviceSynced',
    Failed = 'Failed'
}
