import { DeviceInfo } from "../../../../../app/content/device/data/device-info";
import { ConstantService } from "../../../../../app/lib/common/constant.service";
import { TranslateService } from "../../../../../app/translate/translate.service";

export type SearchFilterRule = {
    id: string;
    field: string;
    langKey?: string;
    rule: 'startsWith' | 'endsWith' | 'contains' | 'equal';
    searchValue: string;
    valueGenerator?: (o: any) => string | string[];
}

export type SearchExpression = {
    operator: 'AND' | 'OR';
    expressions: (SearchFilterRule | SearchExpression)[];
}

export const SearchRules = ['contains', 'equal', 'starts with', 'ends with', 'not contains'];
export const SearchOperators = ['AND', 'OR'];

export class SearchHelper {
    static matchesRule(dataToBeSearch: string | string[], rule: string, searchInput: string): boolean {
        switch (rule) {
            case 'contains': {
                if (Array.isArray(dataToBeSearch)) {
                    for (let d of dataToBeSearch) {
                        if (d.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
                            return true;
                        }
                    }

                    return false;
                }
                else {
                    return dataToBeSearch.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase());
                }    
            }
            case 'equal': {
                if (Array.isArray(dataToBeSearch)) {
                    for (let d of dataToBeSearch) {
                        if (d.toLocaleLowerCase() === searchInput.toLocaleLowerCase()) {
                            return true;
                        }
                    }

                    return false;
                }
                else {
                    return dataToBeSearch.toLocaleLowerCase() === searchInput.toLocaleLowerCase();
                }  
            }
            case 'starts with': {
                if (Array.isArray(dataToBeSearch)) {
                    for (let d of dataToBeSearch) {
                        if (d.toLocaleLowerCase().startsWith(searchInput.toLocaleLowerCase())) {
                            return true;
                        }
                    }

                    return false;
                }
                else {
                    return dataToBeSearch.toLocaleLowerCase().startsWith(searchInput.toLocaleLowerCase());
                }
            };
            case 'ends with': {
                if (Array.isArray(dataToBeSearch)) {
                    for (let d of dataToBeSearch) {
                        if (d.toLocaleLowerCase().endsWith(searchInput.toLocaleLowerCase())) {
                            return true;
                        }
                    }

                    return false;
                }
                else {
                    return dataToBeSearch.toLocaleLowerCase().endsWith(searchInput.toLocaleLowerCase());
                }
            }
            case 'not contains': {
                if (Array.isArray(dataToBeSearch)) {
                    for (let d of dataToBeSearch) {
                        if (d.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())) {
                            return false;
                        }
                    }

                    return true;
                }
                else {
                    return !dataToBeSearch.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
                } 
            };
            default: return false;
        }
    }

    static copySearchExpression(expr: SearchExpression): SearchExpression {
        return expr ? {
            operator: expr.operator,
            expressions: expr.expressions.map(subExpr => {
                return typeof subExpr === 'object' && subExpr != null ? (
                    'operator' in subExpr ? SearchHelper.copySearchExpression(subExpr) : { ...subExpr, valueGenerator: subExpr.valueGenerator }
                ) : subExpr
            })
        } : null;
    }

    static resetSearchExpression(expr: SearchExpression): SearchExpression {
        expr.expressions.forEach(subExpr => {
            if ('operator' in subExpr) {
                SearchHelper.resetSearchExpression(subExpr);
            }
            else {
                subExpr.id = '';
                subExpr.field = '';
                subExpr.langKey = '';
                subExpr.searchValue = '';
            }
        });

        return expr;
    }

    static evaluateExpressionToDevice(d: DeviceInfo, expr: SearchExpression): boolean {
        const results: boolean[] = expr.expressions.map(subExpr => {
            if ('operator' in subExpr) {
                return SearchHelper.evaluateExpressionToDevice(d, subExpr);
            }

            return SearchHelper.matchesRule(subExpr.valueGenerator?.(d) || '', subExpr.rule, subExpr.searchValue);
        });

        return expr.operator === 'AND' ? results.every(Boolean) : results.some(Boolean);
    }

    static getSupportedSearchCriteria(constantSvc: ConstantService): { field: string, langKey: string, valueGenerator: (d: DeviceInfo) => string | string[] }[] {
        return [
            {
                field: constantSvc.DEVKEY_INFO_PNAME, langKey: 'key-dev-name', valueGenerator: (d: DeviceInfo) => d.virtualName || d.currentSettings[constantSvc.DEVKEY_INFO_PNAME] || ''
            },
            {
                field: constantSvc.DEVKEY_NET_LAN_MAC, langKey: 'key-dev-MAC', valueGenerator: (d: DeviceInfo) => {
                    if (d.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !d.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]) {
                        return [d.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC], (d.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC] || '').replace(/:/g, '')];
                    }

                    return [d.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC], (d.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] || '').replace(/:/g, '')];
                }
            },
            {
                field: constantSvc.DEVKEY_NET_LAN_IP, langKey: 'key-net-ip', valueGenerator: (d: DeviceInfo) => {
                    if (d.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !d.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]) {
                        return d.currentSettings[constantSvc.DEVKEY_NET_WIFI_IP] || '';
                    }

                    return d.currentSettings[constantSvc.DEVKEY_NET_LAN_IP] || '';
                }
            },
            {
                field: constantSvc.DEVKEY_FAKE_TAG, langKey: 'key-tag', valueGenerator: (d: DeviceInfo) => Array.from(d.virtualDeviceTags).join('') || ''
            }
        ];
    }

    static isExpressionValid(expr: SearchExpression): boolean {
        const results: boolean[] = expr.expressions.map(subExpr => {
            if ('operator' in subExpr) {
                return SearchHelper.isExpressionValid(subExpr);
            }

            return subExpr.searchValue && subExpr.field ? true : false;
        });

        return results.every(Boolean);
    }

    static isExpressionHasValue(expr: SearchExpression): boolean {
        for (let subExpr of expr.expressions) {
            if ('operator' in subExpr) {
                if (SearchHelper.isExpressionHasValue(subExpr)) {
                    return true;
                }
            }
            else if (subExpr.searchValue) {
                return true;
            }
        }

        return false;
    }

    static importSearchQueryFromPlaintext(constantSvc: ConstantService, query: string): SearchExpression {
        if (!query) return null;

        const searchCriteria: { [field: string]: { field: string, langKey: string, valueGenerator: (d: DeviceInfo) => string | string[] } } = Object.fromEntries(SearchHelper.getSupportedSearchCriteria(constantSvc).map(cr => [cr.field, cr]));
        try {
            let searchQuery: SearchExpression = JSON.parse(query);
            SearchHelper.importSearchCriteria(searchQuery, searchCriteria);

            return searchQuery;
        }
        catch (e) {
            return null;
        }
    }

    private static importSearchCriteria(expr: SearchExpression, criteria: { [field: string]: { field: string, langKey: string, valueGenerator: (d: DeviceInfo) => string | string[] } }): void {
        expr.expressions.forEach(subExpr => {
            if ('operator' in subExpr) {
                SearchHelper.importSearchCriteria(subExpr, criteria);
            }
            else {
                const { field } = subExpr;
                if (field in criteria) {
                    const { langKey, valueGenerator } = criteria[field];
                    subExpr.langKey = langKey;
                    subExpr.valueGenerator = valueGenerator;
                }
            }
        });
    }

    static exportSearchQueryToPlaintext(translateSvc: TranslateService, expr: SearchExpression): string {
        const results: string[] = expr.expressions.map(subExpr => {
            if ('operator' in subExpr) {
                return SearchHelper.exportSearchQueryToPlaintext(translateSvc, subExpr);
            }

            return `("${translateSvc.instant(subExpr.langKey, subExpr.field)}" ${subExpr.rule} "${subExpr.searchValue}")`;
        });

        return expr.operator === 'AND' ? results.join(' > ') : results.join(' OR ');
    }

    /*
    static translateSearchQueryToMongoCmd(expr: SearchExpression | SearchFilterRule): any {
        if ('operator' in expr) {
            const operator = expr.operator === 'AND' ? '$and' : '$or';
            return { [operator]: expr.expressions.map(e => SearchHelper.translateSearchQueryToMongoCmd(e)) }
        }
        else {
            const { field, rule, searchValue } = expr;
            switch (rule) {
                case 'startsWith':
                    return { [field]: { $regex: `^${searchValue}`, $options: 'i' } };
                case 'endsWith':
                    return { [field]: { $regex: `${searchValue}$`, $options: 'i' } };
                case 'contains':
                    return { [field]: { $regex: searchValue, $options: 'i' } };
                case 'equal':
                    return { [field]: searchValue };
                default:
                    throw `Unsupport rule: ${rule}`;
            }
        }
    }
    */
}

/*
const searchQuery: SearchExpression = {
 operator: "AND",
 expressions: [
   {
     operator: "OR",
     expressions: [
       { field: "email", rule: "contains", value: "@gmail.com" },
       { field: "phone", rule: "endsWith", value: "1234" },
     ],
   },
   {
     operator: "AND",
     expressions: [
       { field: "email", rule: "contains", value: "@gmail.com" }
     ],
   }
 ],
};
*/