import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PluginPairingRequestInfo, PluginSpaceInfo } from "../../plugin.data";
import { AppStartOverlayInfo, DeviceInfo, OnlineStatus } from "app/content/device/data/device-info";
import { ConstantService } from "app/lib/common/constant.service";
import { catchError, concatMap, debounceTime, map, takeUntil } from "rxjs/operators";
import { ConfigurableItemKey, DialogPage } from "app/lib/common/common.data";
import { Observable, Subscription, fromEvent, of } from "rxjs";
import { AutoUnsubscribeComponent } from "app/content/virtual/auto-unsubscribe.component";
import { DeviceService } from "app/content/device/device.service";
import { HelperLib } from "app/lib/common/helper.lib";
import { WorkplacePluginService } from "../../plugin.service";
import { Router } from "@angular/router";
import { PluginHelper } from "../../plugin.helper";
import { PolicyDataGroupConfig, PolicyDataGroupConfigItem, PolicyInfo, PolicyType } from "app/content/setting/policy/policy.data";
import { PolicyService } from "app/content/setting/policy/policy.service";
import { PairingContentAssignMode } from "../svcnow.data";
import { PolicyRawInfo } from "app/API/v1/device/policy/api.policy.common";
import { DeviceGroupInfo, DeviceGroupType } from "app/content/device/group/dev-group.data";
import { Logger } from "app/lib/common/logger";
import { DeviceGroupService } from "app/content/device/group/dev-group.service";
import { IPluginInstance } from "app/API/v1/Plugin/api.plugin.data";

@Component({
    templateUrl: './svcnow-pairing.component.html',
    styleUrls: ['./svcnow.style.css', './svcnow-pairing.component.css']
})
export class ServiceNowPairingComponent extends AutoUnsubscribeComponent implements OnInit {
    private readonly TAG: string = '3rdAppPair';
    readonly PLAYER_CONTENT_URL: string = 'https://servicenow.for-workplace.com';
    private readonly DEFAULT_PLAYER_POLICY_NAME: string = 'Connect to ServiceNow';
    readonly NUM_IN_PAGE: number = 30;
    _appname: string;
    _isAppRegistered: boolean = false;

    _availableDeviceMap: { [playerID: string]: { dev: DeviceInfo, selected: boolean } } = {};
    _displayAvailableDeviceList: { dev: DeviceInfo, selected: boolean }[] = [];
    _availableDeviceCount: number;
    _devicePairedMap: { [playerID: string]: { fromSpaceID: string, toSpaceID: string } } = {};
    _deviceToBePairedList: { dev: DeviceInfo, fromSpaceID: string, fromSpaceName: string, toSpaceID: string, toSpaceName: string }[] = [];
    _deviceToBeUnPairedList: { dev: DeviceInfo, fromSpaceID: string, fromSpaceName: string, toSpaceID: string, toSpaceName: string }[] = [];
    _allPairedDeviceGroupMap: { [groupID: string]: { groupName: string, configurationPolicy: PolicyInfo } } = {};
    _pairingDatas: PluginPairingRequestInfo[] = [];

    _spaceMap: { [spaceID: string]: PluginSpaceInfo } = {};
    _displaySpaceList: PluginSpaceInfo[] = [];
    _space: PluginSpaceInfo;
    _spaceDevices: { deviceID: string, dev: DeviceInfo, selected: boolean }[] = [];

    _policyList: PolicyInfo[] = [];
    _policy: PolicyInfo;
    _pairingContentAssignMode: PairingContentAssignMode = PairingContentAssignMode.Policy;
    _enumPairingContentAssignMode: typeof PairingContentAssignMode = PairingContentAssignMode;

    _loadingSpace: boolean = false;
    _loadingDevice: boolean = false;
    _loadingPolicy: boolean = false;
    _updating: boolean = false;

    _enumOnlineType: typeof OnlineStatus = OnlineStatus;
    _enumPage: typeof DialogPage = DialogPage;
    _page: DialogPage = DialogPage.action;
    _currentPage: number = 1;

    _message: string;
    _searchDeviceSubscription: Subscription;
    _searchRoomSubscription: Subscription;
    _searchReservableModuleSDubscription: Subscription;

    _reservableModuleMap: { [id: string]: { id: string, name: string, checkinBeforeMeetingMinute: number } } = {};
    _changedReservableModules: { [spaceID: string]: { from: string, to: string } } = {};
    _displayedReservableModules: { id: string, name: string, checkinBeforeMeetingMinute: number }[];

    private _searchReservableModuleRef: ElementRef;
    @ViewChild('searchReservableModule')
    set searchReservableModule(v: ElementRef) {
        if (v && this._searchReservableModuleRef !== v) {
            if (this._searchReservableModuleSDubscription) {
                this._searchReservableModuleSDubscription.unsubscribe();
            }

            this._searchReservableModuleRef = v;
            this._searchReservableModuleSDubscription = fromEvent(this._searchReservableModuleRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactorReservableModules(e.target.value.toLocaleLowerCase());
            });
        }
    }

    private _searchRoomRef: ElementRef;
    @ViewChild('searchRoom')
    set searchRoom(v: ElementRef) {
        if (v && this._searchRoomRef !== v) {
            if (this._searchRoomSubscription) {
                this._searchRoomSubscription.unsubscribe();
            }

            this._searchRoomRef = v;
            this._searchRoomSubscription = fromEvent(this._searchRoomRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactorSpaces(e.target.value.toLocaleLowerCase());
            });
        }
    }

    private _searchDeviceRef: ElementRef;
    @ViewChild('searchDevices')
    set searchDevices(v: ElementRef) {
        if (v && this._searchDeviceRef !== v) {
            if (this._searchDeviceSubscription) {
                this._searchDeviceSubscription.unsubscribe();
            }

            this._searchDeviceRef = v;
            this._searchDeviceSubscription = fromEvent(this._searchDeviceRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactorAvailableDevices(e.target.value.toLocaleLowerCase());
            });
        }
    }

    @ViewChild('btnPolicyAssignWarning') _btnPolicyAssignWarning: ElementRef;

    constructor(
        private router: Router,
        private constantSvc: ConstantService,
        private devSvc: DeviceService,
        private devGroupSvc: DeviceGroupService,
        private policySvc: PolicyService,
        private pluginSvc: WorkplacePluginService) {
        super();
    }

    ngOnInit(): void {
        this._appname = PluginHelper.getPluginAppCategory(this.router.url);

        this.refresh();
    }

    private initSpaces(force: boolean = false): Observable<{ isFault: boolean, error?: number | string, errorMessage?: string }> {
        this._loadingSpace = true;
        this._spaceMap = {};
        this._displaySpaceList = [];

        return this.pluginSvc.getPairingConfig(this._appname, force).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: PluginPairingRequestInfo[] }) => {
                if (!res.isFault) {
                    this._devicePairedMap = {};
                    this._spaceMap = res.data?.reduce((prev, curr) => {
                        curr.devices.forEach(d => {
                            this._devicePairedMap[d.deviceID] = { fromSpaceID: curr.spaceID, toSpaceID: curr.spaceID };
                        });

                        prev[curr.spaceID] = new PluginSpaceInfo(curr);
                        return prev;
                    }, {});

                    this.refactorSpaces();
                }

                this._loadingSpace = false;
                Logger.logInfo(this.TAG, 'initSpaces', 'Paired status: ', this._devicePairedMap);
                return { isFault: res.isFault, error: res.error, errorMessage: res.errorMessage };
            })
        );
    }

    selectSpace(space: PluginSpaceInfo): void {
        if (this._space !== space) {
            Logger.logInfo(this.TAG, 'selectSpace', 'Select space: ', space);

            if (this._space && this._changedReservableModules[this._space.spaceID] && this._changedReservableModules[this._space.spaceID].from === this._changedReservableModules[this._space.spaceID].to) {
                delete this._changedReservableModules[this._space.spaceID];
            }

            this._space = space;
            this._spaceDevices = this.getDevicesUnderSpace(this._space);

            this._changedReservableModules[this._space.spaceID] = this._changedReservableModules[this._space.spaceID] || { from: this._space.reservableModuleID, to: this._space.reservableModuleID };

            if (this._searchReservableModuleRef) {
                this._searchReservableModuleRef.nativeElement.value = '';
                this.refactorReservableModules();
            }
        }
    }

    selectPairedDevice(pairedDevice: { dev: DeviceInfo, selected: boolean }): void {
        pairedDevice.selected = !pairedDevice.selected;
    }

    selectAvailableDevice(d: { dev: DeviceInfo, selected: boolean }): void {
        d.selected = !d.selected;
    }

    hasDeviceUnderRoomSelected(): boolean {
        if (!this._space) {
            return false;
        }

        return this._spaceDevices.find(d => d.selected) ? true : false;
    }

    hasAvailableDeviceSelected(): boolean {
        return Object.keys(this._availableDeviceMap).find((deviceID: string) => this._availableDeviceMap[deviceID].selected) ? true : false;
    }

    private getDevicesUnderSpace(space: PluginSpaceInfo): { deviceID: string, dev: DeviceInfo, selected: boolean }[] {
        if (!space) {
            return [];
        }

        return Object.keys(this._devicePairedMap)
            .filter((deviceID: string) => this._devicePairedMap[deviceID].toSpaceID === space.spaceID && this._availableDeviceMap[deviceID])
            .map((deviceID: string) => ({ deviceID: deviceID, dev: this._availableDeviceMap[deviceID].dev, selected: false }))
    }

    releaseDevices(): void {
        if (!this.hasDeviceUnderRoomSelected()) {
            return;
        }

        this._spaceDevices.forEach(d => {
            if (!d.selected) {
                return;
            }

            if (!this._devicePairedMap[d.deviceID].fromSpaceID) {
                delete this._devicePairedMap[d.deviceID];
            }
            else {
                this._devicePairedMap[d.deviceID].toSpaceID = '';
            }
        });
        this._spaceDevices = this.getDevicesUnderSpace(this._space);
    }

    addDevices(): void {
        if (!this._space) {
            alert('Please select a room first');
            return;
        }

        Object.keys(this._availableDeviceMap).forEach((deviceID: string) => {
            if (!this._availableDeviceMap[deviceID].selected) {
                return;
            }

            this._availableDeviceMap[deviceID].selected = false;
            if (this._devicePairedMap[deviceID]) {
                this._devicePairedMap[deviceID].toSpaceID = this._space.spaceID;
            }
            else {
                this._devicePairedMap[deviceID] = { fromSpaceID: '', toSpaceID: this._space.spaceID };
            }
        });

        this._spaceDevices = this.getDevicesUnderSpace(this._space);
    }

    getPairedSpaceNameByDeviceID(dev: DeviceInfo): string {
        const spaceID: string = this._devicePairedMap[dev.currentSettings[this.constantSvc.DEVKEY_INFO_PID]]?.toSpaceID;
        return this._spaceMap[spaceID]?.spaceName;
    }

    refactorReservableModules(searchText?: string): void {
        this._displayedReservableModules = HelperLib.mapToList(this._reservableModuleMap);
        if (searchText) {
            this._displayedReservableModules = this._displayedReservableModules.filter(rm => rm.name.toLocaleLowerCase().indexOf(searchText) == 0);
        }
    }

    refactorSpaces(searchText?: string): void {
        this._displaySpaceList = HelperLib.mapToList(this._spaceMap);
        if (searchText) {
            this._displaySpaceList = this._displaySpaceList.filter(space => space.spaceName.toLocaleLowerCase().indexOf(searchText) >= 0);
        }
    }

    refactorAvailableDevices(searchText?: string): void {
        this._displayAvailableDeviceList = Object.keys(this._availableDeviceMap).map((devID: string) => this._availableDeviceMap[devID]);
        if (searchText) {
            this._displayAvailableDeviceList = this._displayAvailableDeviceList.filter(d => d.dev.virtualName.toLocaleLowerCase().indexOf(searchText) >= 0);
        }

        this._availableDeviceCount = this._displayAvailableDeviceList.length;

        if (this._displayAvailableDeviceList.length > this.NUM_IN_PAGE) {
            const startIndex = (this._currentPage - 1) * this.NUM_IN_PAGE;
            this._displayAvailableDeviceList = this._displayAvailableDeviceList.slice(startIndex, startIndex + this.NUM_IN_PAGE);
        }
        else {
            this._displayAvailableDeviceList = this._displayAvailableDeviceList.slice();
        }
    }

    hasReservableModuleChanged(): boolean {
        for (let changed of Object.values(this._changedReservableModules)) {
            if (changed.from !== changed.to) {
                return true;
            }
        }
        return false;
    }

    hasDeviceUpdate(): boolean {
        return Object.keys(this._devicePairedMap).find((deviceID: string) => this._devicePairedMap[deviceID].fromSpaceID !== this._devicePairedMap[deviceID].toSpaceID) ? true : false;
    }

    onPageChanged(currentPage: number): void {
        if (this._loadingDevice) {
            return;
        }

        if (this._currentPage !== currentPage) {
            this._currentPage = currentPage;
            this.refactorAvailableDevices();
        }
    }

    refresh(forceRoom: boolean = false, forceDevice: boolean = false): void {
        this.reset();

        this._loadingSpace = true;
        this.pluginSvc.getReservableModules(this._appname, forceRoom).pipe(
            concatMap((reservableRet: { isFault: boolean, error?: number | string, errorMessage?: string, data?: { id: string, name: string, checkinBeforeMeetingMinute: number }[] }) => {
                if (!reservableRet.isFault) {
                    this._reservableModuleMap = reservableRet.data.reduce((prev, curr) => {
                        prev[curr.id] = curr;
                        return prev;
                    }, {});

                    this.refactorReservableModules();
                }

                return this.initSpaces(forceRoom);
            }),
            concatMap((spaceRet: { isFault: boolean, error?: number | string, errorMessage?: string }) => {
                this._isAppRegistered = spaceRet.error === 'E_APP_NOT_REGISTER' ? false : true;
                this._message = spaceRet.errorMessage;

                this._loadingDevice = true;
                return this.devSvc.getDevicesByBatch('device-overview-table.refresh', forceDevice).pipe(
                    map((devRet: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
                        if (!devRet.isFault) {
                            this._availableDeviceMap = devRet.devices.reduce((prev, curr) => {
                                if (curr.isPaired && curr.currentSettings?.[this.constantSvc.DEVKEY_INFO_PID]) {
                                    prev[curr.currentSettings[this.constantSvc.DEVKEY_INFO_PID]] = { dev: curr, selected: false };
                                }

                                return prev;
                            }, this._availableDeviceMap)
                        }

                        return devRet;
                    })
                );
            })
        ).subscribe((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
            if (!res.hasNext) {
                this._loadingDevice = false;
                this.refactorAvailableDevices();
            }
        });
    }

    reset(): void {
        Object.keys(this._devicePairedMap).forEach((deviceID: string) => {
            if (!this._devicePairedMap[deviceID].fromSpaceID) {
                delete this._devicePairedMap[deviceID];
            }
            else {
                this._devicePairedMap[deviceID].toSpaceID = this._devicePairedMap[deviceID].fromSpaceID;
            }
        });

        this._spaceDevices = this.getDevicesUnderSpace(this._space);
        this._changedReservableModules = {};
        this._space = null;
    }

    selectPolicy(policy: PolicyInfo): void {
        this._policy = policy;
    }

    selectContentAssignMode(mode: PairingContentAssignMode): void {
        this._pairingContentAssignMode = mode;
    }

    backToPairingSelection(): void {
        this._page = DialogPage.action;
        this._message = '';
        this._deviceToBePairedList = [];
        this._deviceToBeUnPairedList = [];
        this._pairingDatas = [];
        this._allPairedDeviceGroupMap = {};
    }

    gotoConfirmPage(): void {
        this._page = DialogPage.confirm;

        Logger.logInfo(this.TAG, 'gotoConfirmPage', 'changed reservable modules: ', this._changedReservableModules);

        // generate pairing confirmation infos.
        Object.keys(this._devicePairedMap).forEach((deviceID: string) => {
            const spaceState: { fromSpaceID: string, toSpaceID: string } = this._devicePairedMap[deviceID];
            if (spaceState.fromSpaceID == spaceState.toSpaceID) {
                return;
            }

            if (this._availableDeviceMap[deviceID]?.dev) {
                if (spaceState.toSpaceID) {
                    this._deviceToBePairedList.push({
                        dev: this._availableDeviceMap[deviceID]?.dev,
                        fromSpaceName: this._spaceMap[spaceState.fromSpaceID]?.spaceName,
                        fromSpaceID: spaceState.fromSpaceID,
                        toSpaceName: this._spaceMap[spaceState.toSpaceID]?.spaceName,
                        toSpaceID: spaceState.toSpaceID
                    });
                }
                else {
                    this._deviceToBeUnPairedList.push({
                        dev: this._availableDeviceMap[deviceID].dev,
                        fromSpaceName: this._spaceMap[spaceState.fromSpaceID]?.spaceName,
                        fromSpaceID: spaceState.fromSpaceID,
                        toSpaceName: '',
                        toSpaceID: ''
                    });
                }
            }
        });

        if (this._deviceToBePairedList.length + this._deviceToBeUnPairedList.length > 0) {
            this._loadingPolicy = true;
            this.policySvc.getPolicyList().subscribe((policies: PolicyInfo[]) => {
                this._loadingPolicy = false;
                this._policyList = policies.filter(p => {
                    if (p.type !== PolicyType.Configuration) {
                        return false;
                    }
    
                    if (!p.source?.PluginInstance) {
                        return false;
                    }
    
                    return true;
                });
    
                if (this._policyList.length > 0) {
                    this._policy = this._policyList.find(p => p.name === this.DEFAULT_PLAYER_POLICY_NAME) || this._policyList[0];
                }
            });
        }
    }

    changeReservableModule(space: PluginSpaceInfo, targetReservableModule: { id: string, name: string, checkinBeforeMeetingMinute: number }): void {
        if (!this._changedReservableModules[space.spaceID]) {
            return;
        }

        this._changedReservableModules[space.spaceID].to = targetReservableModule.id;
    }

    private createAppPlaybackPolicy(): PolicyInfo {
        // create a new Servicenow policy with specific AppStart settings
        const pNew: PolicyInfo = new PolicyInfo('policy-' + PolicyService.POLICY_INDEX++, this.DEFAULT_PLAYER_POLICY_NAME);
        pNew.type = PolicyType.Configuration;
        pNew.data = new PolicyDataGroupConfig();
        (pNew.data as PolicyDataGroupConfig).configMap[ConfigurableItemKey.AppStart] = new PolicyDataGroupConfigItem(ConfigurableItemKey.AppStart, 'Appstart', {
            valid: true,
            checked: true,
            defaultSettingMap: {
                [this.constantSvc.DEVKEY_APPSTART_CONTENTURL]: { langKey: 'Content URL', value: this.PLAYER_CONTENT_URL },
                [this.constantSvc.DEVKEY_APPSTART_OVERLAY]: { langKey: 'Overlay', value: new AppStartOverlayInfo() },
            }
        });

        this.constantSvc.getAppstartExtraList().forEach(extra => {
            (pNew.data as PolicyDataGroupConfig).configMap[ConfigurableItemKey.AppStart].settingMap[extra.property] = { langKey: extra.displayName, value: extra.type === 'checkbox' ? false : 0 };
        });

        (pNew.data as PolicyDataGroupConfig).configMap[ConfigurableItemKey.AppStart].settingMap[this.constantSvc.DEVKEY_APPSTART_EXTRA_TRUST].value = true;
        (pNew.data as PolicyDataGroupConfig).configMap[ConfigurableItemKey.AppStart].settingMap[this.constantSvc.DEVKEY_APPSTART_EXTRA_NETCONNECTRELOAD].value = true;
        (pNew.data as PolicyDataGroupConfig).configMap[ConfigurableItemKey.AppStart].settingMap[this.constantSvc.DEVKEY_APPSTART_EXTRA_IGNORECERTERROR].value = true;
        (pNew.data as PolicyDataGroupConfig).configMap[ConfigurableItemKey.AppStart].settingMap[this.constantSvc.DEVKEY_APPSTART_EXTRA_WEBAPPRESPONSETIMEOUT].value = 300;

        const pluginInstance: IPluginInstance = this.pluginSvc.getPluginInstance();
        if (pluginInstance) {
            pNew.sourceType = 'PluginInstance';
            pNew.source = pluginInstance.pluginInstanceID;
        }

        return pNew;
    }

    confirmApplyingPolicyToDeviceGroups(): void {
        const affectedSpaceIDs: { [spaceID: string]: boolean } = this._deviceToBePairedList.concat(this._deviceToBeUnPairedList).reduce((prev, curr) => {
            if (curr.fromSpaceID && curr.toSpaceID) {
                prev[curr.fromSpaceID] = true;
                prev[curr.toSpaceID] = true;
            }
            else if (curr.fromSpaceID) {
                prev[curr.fromSpaceID] = true;
            }
            else {
                prev[curr.toSpaceID] = true;
            }

            return prev;
        }, {});

        let allPairedDevices: DeviceInfo[] = [];
        this._pairingDatas = Object.keys(affectedSpaceIDs).map((spaceID: string) => {
            const devicesUnderSpace: { deviceID: string, dev: DeviceInfo, selected: boolean }[] = this.getDevicesUnderSpace(this._spaceMap[spaceID]);
            allPairedDevices = allPairedDevices.concat(devicesUnderSpace.map(d => d.dev));

            return Object.assign(this._spaceMap[spaceID], {
                devices: devicesUnderSpace.map((d: { deviceID: string, dev: DeviceInfo, selected: boolean }) => ({
                    deviceID: d.dev.currentSettings[this.constantSvc.DEVKEY_INFO_PID],
                    deviceName: d.dev.virtualName,
                    isPaired: d.dev.isPaired,
                    pairingDateTime: new Date()
                }))
            });
        });

        this._allPairedDeviceGroupMap = allPairedDevices.reduce((prev, curr) => {
            if (!prev[curr.groupID]) {
                const devGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(curr.groupID);
                const configurationPolicyID: string = devGroup.policies[PolicyType.Configuration]?.[0];
                const policy: PolicyInfo = configurationPolicyID ? this._policyList.find(p => p.id === configurationPolicyID) : null;
                if (devGroup) {
                    prev[curr.groupID] = { groupName: devGroup.name, configurationPolicy: policy };
                }
            }

            return prev;
        }, {});

        if (this._pairingContentAssignMode == PairingContentAssignMode.Policy && this._deviceToBePairedList.length > 0) {
            this._btnPolicyAssignWarning?.nativeElement.click();
            return;
        }

        this.applyPolicyToDeviceGroups();
    }

    applyPolicyToDeviceGroups(): void {
        this._updating = true;

        of(true).pipe(
            concatMap(() => {
                // if to create a new policy for the paired devices
                if (this._pairingContentAssignMode === PairingContentAssignMode.Policy && this._deviceToBePairedList.length > 0 && !this._policy) {
                    Logger.logInfo(this.TAG, 'applyPolicyToDeviceGroups', 'create a new customized policy');
                    const pNew: PolicyInfo = this.createAppPlaybackPolicy();
                    return this.policySvc.createPolicy(pNew).pipe(
                        map((res: { data?: PolicyRawInfo, error: number | string, errorMessage?: string }) => {
                            if (res.error !== 0) {
                                throw res.errorMessage;
                            }

                            pNew.id = res.data.policyID;

                            return pNew;
                        })
                    );
                }

                return of(this._policy);
            }),
            concatMap((policy: PolicyInfo) => {
                // if to assign policy to the device group of the paired devices.
                if (this._pairingContentAssignMode === PairingContentAssignMode.Policy && this._deviceToBePairedList.length > 0 && policy) {
                    Logger.logInfo(this.TAG, 'applyPolicyToDeviceGroups', `assign all paired devices\' group to policy ${policy.name}`);

                    return this.policySvc.assignPolicy(policy, Object.keys(this._allPairedDeviceGroupMap).map((devGroupID: string) => ({ id: devGroupID, type: DeviceGroupType.group })));
                }

                return of(true);
            }),
            concatMap(() => {
                if (this._pairingDatas.length > 0) {
                    return this.pluginSvc.updatePairingConfig(this._appname, this._pairingDatas);
                }

                return of({ isFault: false });
            }),
            concatMap((res: { isFault: boolean, errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                if (this.hasReservableModuleChanged()) {
                    let data = Object.keys(this._changedReservableModules).map((spaceID: string) => {
                        return {
                            spaceID: spaceID,
                            reservableModuleID: this._changedReservableModules[spaceID].to,
                            checkinBeforeMeetingMinute: this._reservableModuleMap[this._changedReservableModules[spaceID].to].checkinBeforeMeetingMinute || 0
                        }
                    });

                    return this.pluginSvc.updateReservableModules(this._appname, data);
                }

                return of({ isFault: false });
            }),
            catchError(error => of({ isFault: true, errorMessage: error }))
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;

            if (res.isFault) {
                this._message = res.errorMessage;
                return;
            }

            this.refresh(true);
            this.backToPairingSelection();
            this._space = null;
        });
    }
}