<div>
    <div *ngIf="!_loading">
        <div>
            <p>Create tags for the following device(s). Seperate tags by comma.</p>
            <div>
                <div class="form-group">
                    <div class="d-flex align-items-center mb-1">
                        <label for="dev-tag-name">Tag(s)</label>
                    </div>
                    <input #newTagStr id="dev-tag-name" type="text" class="form-control"
                        [value]="_actionData?.newlyAddedTagStr">
                </div>
            </div>
            <div *ngIf="_duplicateTags.length > 0" class="warning-block">
                Duplicate tag(s): {{ _duplicateTags.join(',') }}
            </div>
        </div>
        <div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <div>Existing tags <small class="ml-2">(Click X to remove)</small></div>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit"
                    [disabled]="_removedTags.length === 0" (click)="resetRemovedTag()">Reset</button>
            </div>
            <div class="exist-tags">
                <div *ngIf="_bSpecific || _devices.length == 1; else templateMultiDevice" class="tag-block">
                    <span *ngFor="let tag of _commonTags" class="tag-item" [class.d-none]="tag.toRemove">
                        <i class="fas fa-times action" (click)="markTagToRemove(tag)"></i>
                        <span class="content">{{ tag.tagName }}</span>
                    </span>
                </div>
                <ng-template #templateMultiDevice>
                    <div>
                        <label>Common tags:</label>
                        <div class="tag-block">
                            <span *ngFor="let tag of _commonTags" class="tag-item" [class.d-none]="tag.toRemove">
                                <i class="fas fa-times action" (click)="markTagToRemove(tag)"></i>
                                <span class="content">{{ tag.tagName }}</span>
                            </span>
                        </div>
                    </div>
                    <div>
                        <label>Uncommon tags:</label>
                        <div class="tag-block">
                            <span *ngFor="let tag of _uncommonTags" class="tag-item" [class.d-none]="tag.toRemove">
                                <i class="fas fa-times action" (click)="markTagToRemove(tag)"></i>
                                <span class="content">{{ tag.tagName }}</span>
                            </span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="_removedTags.length > 0" class="red-1">{{ _removedTags.length }} tag{{_removedTags.length > 1 ?
                's' : ''}} {{ _removedTags.length > 1 ? 'are' : 'is'}} removed.</div>
        </div>
    </div>
    <ng-template #loading>
        <div class="loading-block">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
    <div class="mt-3">
        <label for="dev-label-devices">Device(s)</label>
        <div id="dev-label-devices" class="list-group">
            <div class="list-group-item list-group-header black">
                <div class="row">
                    <div class="col title">
                        Device name
                    </div>
                    <div class="col title">
                        Device model
                    </div>
                </div>
            </div>
            <div class="list-group-content list-group-content-modal">
                <div *ngFor="let dev of _actionData?.deviceList;" class="list-group-item list-group-item-action">
                    <div class="row align-items-center">
                        <div class="col text-break">
                            {{ dev.virtualName }}
                        </div>
                        <div class="col text-break">
                            {{ dev.currentSettings[constantSvc.DEVKEY_INFO_MODELDESC] ||
                            dev.currentSettings[constantSvc.DEVKEY_INFO_MODEL] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>