export const LANG_EN_NAME: string = 'en';

export const LANG_EN_TRANS = {
    //device
    'key-dev-manage': 'add/remove device',
    'key-dev-tagname': 'tag name',
    'key-dev-name': 'device name',
    'key-dev-group': 'device group',
    'key-dev-model': 'model',
    'key-dev-time': 'device local time',
    'key-dev-mainMAC': 'primary MAC address',
    'key-dev-MAC': 'MAC',
    'key-dev-lanMAC': 'lan MAC',
    'key-dev-wifiMAC': 'Wi-Fi MAC',
    'key-dev-firmwareVersion': 'firmware version',
    'key-dev-apkVersion': 'APK version',
    'key-dev-warrantyExpireDate': 'warranty expiration date',
    'key-dev-refresh': 'Refresh devices',
    'key-dev-activity': 'activities',
    'key-dev-activity-track': 'device activities',
    'key-dev-activity-refresh': 'refresh user activity status',
    'key-dev-firmware': 'firmware',
    'key-dev-firmwareUpdate': 'update firmware',
    'key-dev-firmwareDownload': 'download firmware',
    'key-dev-firmwareInstall': 'install firmware',
    'key-dev-apkUpdate': 'update IAdeaCare APK',
    'key-dev-apkDownload': 'download APK',
    'key-dev-apkInstall': 'install APK',
    'key-dev-reboot': 'reboot',
    'key-dev-heartbeat': 'Heartbeat',
    'key-dev-pairCode': 'pairing code',
    'key-dev-pairAction': 'action',
    'key-dev-overview': 'device overview',
    'key-dev-amount': 'number of device(s)',
    'key-dev-basicConfig': 'basic',
    'key-dev-netConfig': 'network',
    'key-dev-timezone': 'time zone',
    'key-dev-resolution': 'output resolution',
    'key-dev-orientation': 'display orientation',
    'key-dev-dailyRebootEnable': 'enable daily reboot',
    'key-dev-dailyRebootTime': 'daily reboot time',
    'key-dev-dailyReboot': 'daily reboot',
    'key-dev-screenCaptureTime': 'last screen capture time',
    'key-dev-screenCaptureUpdate': 'refresh screenshot',
    'key-dev-usbDebugEnable': 'USB debugging',
    'key-dev-tcpDebugEnable': 'TCP debugging',
    'key-dev-hdcpEnable': 'HDCP',
    'key-dev-optionRefresh': 'available options refresh',
    'key-dev-settingRefresh': 'current setting refresh',
    'key-dev-licenseRefresh': 'license reload',
    'key-dev-contentUrl': 'content URL',
    'key-dev-appstart': 'AppStart',
    'key-tag': 'tag',

    //network
    'key-net-ip': 'IP',
    'key-net-gateway': 'gateway',
    'key-net-mask': 'netmask',
    'key-net-dns1': 'dns1',
    'key-net-dns2': 'dns2',
    'key-net-useStatic': 'use static IP',
    'key-net-selectSSID': 'select SSID',
    'key-net-enableDHCP': 'enable DHCP',
    'key-net-enableLan': 'enable ethernet',
    'key-net-enableWifi': 'enable Wi-Fi',

    //firmware
    'key-firmware-linkRemind': 'please provide the download URL for your firmware file',
    'key-firmware-family': 'applicable models',

    //general
    'key-login': 'login',
    'key-logout': 'logout',
    'key-signup': 'sign up',
    'key-search': 'search',
    'key-lang': 'language',
    'key-theme': 'theme',
    'key-general': 'general',
    'key-network': 'network',
    'key-license': 'license',
    'key-warranty': 'warranty expiry date',
    'key-expireDate': 'expiration date',
    'key-issueDate': 'issue date',
    'key-beginDate': 'start date',
    'key-actDate': 'activation date',
    'key-endDate': 'finish date',
    'key-status': 'status',
    'key-activity': 'activity',
    'key-lastTrackTime': 'last activity tracking time',
    'key-close': 'close',
    'key-refresh': 'refresh',
    'key-update': 'update',
    'key-cancel': 'cancel',
    'key-apply': 'apply',
    'key-currentVer': 'current version',
    'key-latestVer': 'latest version',
    'key-fromUrl': 'from specific URL',
    'key-updateMethod': 'update method',
    'key-version': 'version',
    'key-releaseDate': 'release date',
    'key-detail': 'detail',
    'key-advanceCtrl': 'advanced control',
    'key-device': 'device',
    'key-ok': 'OK',
    'key-questionnaire': 'questionnaire',
    'key-noAvail': 'not available',
    'key-finish': 'finish',
    'key-fail': 'fail',
    'key-pending': 'pending',
    'key-inProgress': 'in progress',
    'key-next': 'next',
    'key-prev': 'prev',
    'key-password': 'password',
    'key-security': 'security',
    'key-ethernet': 'ethernet',
    'key-wifi': 'wifi',
    'key-name': 'name',
    'key-type': 'type',
    'key-usage': 'usage / total',
    'key-duration': 'duration',
    'key-day': 'day',
    'key-assignee': 'assignee',
    'key-product': 'product key',
    'key-volume' : 'volume',
    'key-volumeMax': 'max volume',
    'key-screenoff': 'screen off',
    'key-maintenancePlayback': 'maintenance playback',
    'key-lockScreen': 'lock screen',
    'key-powersave': 'power save',
    'key-screenSaver': 'screen saver',
    'key-timeServer': 'time server',
    'key-timeServerLocation': 'time server sync location',
    'key-failSafe': 'FailSafe',
    'key-debug': 'debugging',
    'key-group': 'group',

    //view
    'key-view-changeCol': 'edit column',
    'key-view-table': 'table view',
    'key-view-grid': 'grid view',

    //troubleshoot
    'key-troubleshoot': 'troubleshoot',
    
    //nav
    'key-nav-home': 'home',
    'key-nav-setting': 'setting',
    'key-nav-troubleshoot': 'troubleshoot',
    'key-nav-subscribe': 'subscription',
}