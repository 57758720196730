export const DLG_NAME_ISSUE_REPORT: string = 'dlg-issue-report';
export const DLG_NAME_REBOOT: string = 'dlg-reboot';
export const DLG_NAME_FIRMWARE_UPDATE: string = 'dlg-firmware-update';
export const DLG_NAME_APK_UPDATE: string = 'dlg-apk-update';
export const DLG_NAME_BASIC_CONFIG: string = 'dlg-basic-config';
export const DLG_NAME_MULTI_BASIC_CONFIG: string = 'dlg-multi-basic-config';
export const DLG_NAME_NET_CONFIG: string = 'dlg-net-config';
export const DLG_NAME_ALERT: string = 'dlg-alert';
export const DLG_NAME_SECURITY: string = 'dlg-security';
export const DLG_NAME_CLEARCACHE: string = 'dlg-clearCache';
export const DLG_NAME_CLEARAPPDATA: string = 'dlg-clearData';
export const DLG_NAME_OTP: string = 'dlg-otp';
export const DLG_NAME_SCRIPT: string = 'dlg-script';
export const DLG_NAME_TAG_UPDATE: string = 'dlg-tagUpdate';

export enum ConfigDialogPage {
    checking = 1,
    action,
    confirm,
    submit,
    result
}