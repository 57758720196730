import { Component } from '@angular/core';
import { of } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DeviceInfo, AppStartInfo, AppStartOverlayInfo } from '../../device/data/device-info';
import { LicenseScopeType } from '../../license/license.data';
import { DLG_NAME_MULTI_BASIC_CONFIG, ConfigDialogPage } from '../base/base-config-data';
import { MultiConfigItem } from './multi-config-data';
import { ConfigurableItemKey } from '../../../../app/lib/common/common.data';
import { AppConfigService } from '../../../app.config';
import { LockScreenInfo } from '../../../uiElement/schedule/screen/screen.data';
import { MaintenancePlaybackInfo } from '../../../uiElement/maintenancePlayback/mtPlayback.data';
import { PolicyType } from '../../../../app/content/setting/policy/policy.data';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class MultiBasicConfigFormComponent extends BaseConfigFormComponent {
    private _multiConfigGeneralList: MultiConfigItem[];
    private _multiConfigContentList: MultiConfigItem[];
    private _multiConfigScheduleList: MultiConfigItem[];

    readonly Enable_name: boolean = AppConfigService.configs.devPage.func.basicConfig.element.name;
    readonly Enable_contentUrl: boolean = AppConfigService.configs.devPage.func.basicConfig.element.contentUrl;
    readonly Enable_overlay: boolean = AppConfigService.configs.devPage.func.basicConfig.element.overlay;
    readonly Enable_timezone: boolean = AppConfigService.configs.devPage.func.basicConfig.element.timezone;
    readonly Enable_timeserver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.timeserver;
    readonly Enable_resolution: boolean = AppConfigService.configs.devPage.func.basicConfig.element.resolution;
    readonly Enable_orientation: boolean = AppConfigService.configs.devPage.func.basicConfig.element.orientation;
    //readonly Enable_hdcp: boolean = AppConfigService.configs.devPage.func.basicConfig.element.HDCP;
    readonly Enable_dailyReboot: boolean = AppConfigService.configs.devPage.func.basicConfig.element.dailyReboot;
    readonly Enable_volume: boolean = AppConfigService.configs.devPage.func.basicConfig.element.volume;
    readonly Enable_screenOff: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled;
    readonly Enable_maintenancePlayback: boolean = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
    readonly Enable_failSafe: boolean = AppConfigService.configs.devPage.func.basicConfig.element.failSafe;
    readonly Enable_screensaver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenSaver;
    readonly Enable_powersave: boolean = AppConfigService.configs.devPage.func.basicConfig.element.powersave;
    readonly Enable_brightness: boolean = AppConfigService.configs.devPage.func.basicConfig.element.brightness;

    ngOnInit(): void {
        this._dialogName = DLG_NAME_MULTI_BASIC_CONFIG;
        this._licenseScopeType = LicenseScopeType.taskConfig;
        this._policyLockType = PolicyType.Configuration;

        super.ngOnInit();
    }

    private initGeneralConfig(): void {
        this._multiConfigGeneralList = [
            {
                key: ConfigurableItemKey.DisplayOrientation,
                langKey: 'key-dev-orientation',
                isValid: true,
                bShown: this.Enable_orientation,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION] = { langKey: 'Display orientation', value: undefined };
                }
            },
            {
                key: ConfigurableItemKey.Volume,
                langKey: 'key-volume',
                isValid: true,
                bShown: this.Enable_volume,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL] = { langKey: 'Volume', value: 0 }
                }
            },
            {
                key: ConfigurableItemKey.Powersave,
                langKey: 'key-powersave',
                isValid: true,
                bShown: this.Enable_powersave,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE] = { langKey: 'Disable power save', value: true };
                    self.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT] = { langKey: 'Power save timeout', value: 10 };
                    self.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION] = { langKey: 'Power save action', value: 'sleep' };
                }
            },
            {
                key: ConfigurableItemKey.Brightness,
                langKey: 'brightness',
                isValid: true,
                bShown: this.Enable_brightness,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_FAKE_BRIGHTNESS] = { langKey: 'Brightness level', value: { data: [], cmd: '' } };
                }
            }
        ];

        this._multiConfigGeneralList.forEach(config => config.reset(config));
    }

    private initContentConfig(): void {
        this._multiConfigContentList = [
            {
                key: ConfigurableItemKey.AppStart,
                langKey: 'AppStart',
                isValid: true,
                bShown: this.Enable_contentUrl,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL] = { langKey: 'Content URL', value: '' };
                    this.constantSvc.getAppstartExtraList().forEach(extra => {
                        self.settingMap[extra.property] = { langKey: extra.displayName, value: false };
                    });
                }
            },
            {
                key: ConfigurableItemKey.OverlayQRCode,
                langKey: 'QR code overlay',
                isValid: true,
                bShown: this.Enable_overlay,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY] = { langKey: 'Overlay', value: new AppStartOverlayInfo() };
                }
            },
            {
                key: ConfigurableItemKey.MaintenancePlayback,
                langKey: 'key-maintenancePlayback',
                isValid: true,
                bShown: this.Enable_maintenancePlayback,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE] = {
                        langKey: 'key-maintenancePlayback',
                        value: new MaintenancePlaybackInfo()
                    }
                }
            }
        ];

        this._multiConfigContentList.forEach(config => config.reset(config));
    }

    private initScheduleConfig(): void {
        this._multiConfigScheduleList = [
            {
                key: ConfigurableItemKey.Timezone,
                langKey: 'Timezone',
                isValid: true,
                bShown: this.Enable_timezone,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_TIME_TIMEZONE] = { langKey: 'Timezone', value: undefined }
                }
            },
            {
                key: ConfigurableItemKey.Timeserver,
                langKey: 'key-timeServer',
                isValid: true,
                bShown: this.Enable_timeserver,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED] = { langKey: 'Enable time server', value: false };
                    self.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE] = { langKey: 'key-timeServerLocation', value: 'default' };
                }
            },
            {
                key: ConfigurableItemKey.DailyReboot,
                langKey: 'Daily reboot',
                isValid: true,
                bShown: this.Enable_dailyReboot,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED] = { langKey: 'key-dev-dailyRebootEnable', value: false };
                    self.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME] = { langKey: 'key-dev-dailyRebootTime', value: '04:00' };
                    self.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT] = { langKey: 'key-dev-dailyReboot', value: { days: [], time: '04:00' } };
                }
            },
            {
                key: ConfigurableItemKey.LockScreen,
                langKey: 'key-lockScreen',
                isValid: true,
                bShown: this.Enable_screensaver || this.Enable_screenOff,
                reset: (self: MultiConfigItem) => {
                    self.settingMap = self.settingMap || {};
                    self.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN] = {
                        langKey: 'key-lockScreen',
                        value: new LockScreenInfo()
                    }
                }
            }
        ];

        this._multiConfigScheduleList.forEach(config => config.reset(config));
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            if (!this._actionData.generalConfigList) {
                this.initGeneralConfig();
                this._actionData.generalConfigList = this._multiConfigGeneralList;
            }
            if (!this._actionData.contentConfigList) {
                this.initContentConfig();
                this._actionData.contentConfigList = this._multiConfigContentList;
            }
            if (!this._actionData.scheduleConfigList) {
                this.initScheduleConfig();
                this._actionData.scheduleConfigList = this._multiConfigScheduleList;
            }
        }

        super.goNext();
    }

    protected isPageValid(page: ConfigDialogPage): boolean {
        if (!super.isPageValid(page)) {
            return false;
        }

        if (this._page === ConfigDialogPage.action) {
            let valid: boolean = true;
            for (let config of this._multiConfigGeneralList.concat(this._multiConfigContentList).concat(this._multiConfigScheduleList)) {
                if (config.isConfig && !config.isValid) {
                    valid = false;
                    break;
                }
            }

            return valid;
        }

        return true;
    }

    protected submit(): void {
        super.submit();

        let appStartItem: MultiConfigItem;
        let qrcodeItem: MultiConfigItem;
        const configList: MultiConfigItem[] = [];

        for (let configItem of this._actionData.generalConfigList.concat(this._actionData.scheduleConfigList)) {
            if (configItem.isConfig) {
                configList.push(configItem);
            }
        }
        for (let configItem of this._actionData.contentConfigList) {
            switch (configItem.key) {
                case ConfigurableItemKey.AppStart:
                    {
                        appStartItem = configItem;
                    }
                    break;
                case ConfigurableItemKey.OverlayQRCode:
                    {
                        qrcodeItem = configItem;
                    }
                    break;
                default:
                    {
                        if (configItem.isConfig) {
                            configList.push(configItem);
                        }
                    }
            }
        }

        const configItemMap: { [virtualDeviceID: string]: { dev: DeviceInfo, itemsToApply: { name: string, value: any, origin?: any, langKey?: string }[] } } = {};

        let lockScreenSettingData: LockScreenInfo = null;
        let maintenanceSettingData: MaintenancePlaybackInfo = null;
        let brightnessSettingData: { data: { time: number, brightness: number }[], cmd: string } = null;
        let appStartSettingData: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } = null;
        let bSetBatchTask: boolean = false;
        this._legalDevices.filter(d => d.isSelect).forEach((d: DeviceInfo) => {
            const itemsToApply: { name: string, value: any, origin?: any, langKey?: string }[] = [];

            // for appstart integration
            if (appStartItem.isConfig || qrcodeItem.isConfig) {
                // apply default value for origin & value
                const originAppStartInfo: AppStartInfo = (d.currentSettings[this.constantSvc.DEVKEY_APPSTART] as AppStartInfo);
                const appStartOrigin: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } = {
                    data: d.currentSettings[this.constantSvc.DEVKEY_APPSTART_CONTENTURL],
                    extras: {}
                };

                const appStartUpdated: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } = {
                    data: d.currentSettings[this.constantSvc.DEVKEY_APPSTART_CONTENTURL],
                    extras: {}
                };
                // set origin value for each device
                if (d.currentSettings[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME]) {
                    appStartUpdated.packageName = d.currentSettings[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME];
                }
                if (d.currentSettings[this.constantSvc.DEVKEY_APPSTART_CLASSNAME]) {
                    appStartUpdated.className = d.currentSettings[this.constantSvc.DEVKEY_APPSTART_CLASSNAME];
                }
                if (d.currentSettings[this.constantSvc.DEVKEY_APPSTART_ACTION]) {
                    appStartUpdated.action = d.currentSettings[this.constantSvc.DEVKEY_APPSTART_ACTION];
                }

                const qrcodeOriginData = originAppStartInfo.overlay.toSettingData();
                if (qrcodeOriginData) {
                    appStartOrigin.overlay = appStartUpdated.overlay = qrcodeOriginData;
                }
                this.constantSvc.getAppstartExtraList().forEach(extra => {
                    appStartOrigin.extras[extra.property] = (d.currentSettings[this.constantSvc.DEVKEY_APPSTART] as AppStartInfo).extras[extra.property];
                    appStartUpdated.extras[extra.property] = (d.currentSettings[this.constantSvc.DEVKEY_APPSTART] as AppStartInfo).extras[extra.property];
                });

                // apply modified data if required
                if (!appStartSettingData) {
                    if (appStartItem.isConfig) {
                        appStartUpdated.data = appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL].value;
                        if (appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME]?.value) {
                            appStartUpdated.packageName = appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME]?.value;
                        }
                        //use when we allow package name, class name, action to be modified on future
                        if (appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_CLASSNAME]?.value) {
                            appStartUpdated.className = appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_CLASSNAME]?.value;
                        }
                        if (appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_ACTION]?.value) {
                            appStartUpdated.action = appStartItem.settingMap[this.constantSvc.DEVKEY_APPSTART_ACTION]?.value;
                        }
                        this.constantSvc.getAppstartExtraList().forEach(extra => {
                            appStartUpdated.extras[extra.property] = appStartItem.settingMap[extra.property].value;
                        });
                    }
                    if (qrcodeItem.isConfig) {
                        appStartUpdated.overlay = qrcodeItem.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value.toSettingData()
                    }

                    appStartSettingData = appStartUpdated;
                }

                itemsToApply.push({
                    name: 'app.start',
                    value: appStartSettingData,
                    origin: appStartOrigin
                });
            }

            for (let config of configList) {
                switch (config.key) {
                    case ConfigurableItemKey.LockScreen:
                        {
                            if (!lockScreenSettingData) {
                                lockScreenSettingData = config.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN].value;
                            }
                        }
                        break;
                    case ConfigurableItemKey.MaintenancePlayback:
                        {
                            if (!maintenanceSettingData) {
                                maintenanceSettingData = config.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE].value;
                            }
                        }
                        break;
                    case ConfigurableItemKey.Brightness:
                        {
                            brightnessSettingData = config.settingMap[this.constantSvc.DEVKEY_FAKE_BRIGHTNESS].value;
                        }
                        break;
                    default:
                        {
                            Object.keys(config.settingMap).forEach((configName: string) => {
                                let originData: any = d.currentSettings[configName];
                                //extract timezone id if match
                                if (configName === this.constantSvc.DEVKEY_TIME_TIMEZONE) {
                                    const timezone_pieces: string[] = d.currentSettings[configName].split(',');
                                    if (timezone_pieces.length === 3) {
                                        originData = timezone_pieces[2];
                                    }
                                }

                                itemsToApply.push({
                                    name: configName,
                                    value: config.settingMap[configName].value,
                                    origin: originData === undefined ? '' : originData,
                                    langKey: config.settingMap[configName].langKey
                                });
                            });
                        }
                        break;
                }
            }

            if (itemsToApply.length > 0) {
                bSetBatchTask = true;
                configItemMap[d.virtualId] = {
                    dev: d,
                    itemsToApply: itemsToApply
                };
            }
        });

        const errorList: string[] = [];
        of(true).pipe(
            concatMap(() => {
                if (lockScreenSettingData || maintenanceSettingData) {
                    return this.devSvc.batchScheduleUpdate(this._legalDevices.filter(d => d.isSelect).map((dev: DeviceInfo) => { return { device: dev, lockScreenData: lockScreenSettingData, maintenanceData: maintenanceSettingData, options: { updated: { appStart: appStartSettingData } } } })).pipe(
                        map((scheduleResultList: { device: DeviceInfo, isFault: boolean, errorMessage?: string }[]) => {
                            scheduleResultList.filter(r => r.isFault).forEach(r => {
                                errorList.push(r.errorMessage);
                            });

                            return true;
                        })
                    );
                }

                return of(true);
            }),
            concatMap(() => {
                if (brightnessSettingData) {
                    return this.devSvc.batchDoCustomAction(this._legalDevices.filter(d => d.isSelect), {
                        actionID: this.constantSvc.TASKTYPE_SCRIPT,
                        subject: 'Custom script',
                        resourceData: { cmd: brightnessSettingData.cmd }
                    });
                }

                return of(true);
            }),
            concatMap(() => {
                if (bSetBatchTask) {
                    return this.devSvc.batchBasicConfig(Object.keys(configItemMap).map(vID => { return { device: configItemMap[vID].dev, configData: configItemMap[vID].itemsToApply } })).pipe(
                        map(res => {
                            if (res.isFault()) {
                                errorList.push(res.errorMessage);
                            }

                            return res;
                        })
                    )
                }

                return of(null);
            })
        ).subscribe(res => {
            this._page++;

            if (errorList.length > 0) {
                this._errorMessage = errorList.join(', ');
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });
    }
}