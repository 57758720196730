import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, concatMap, delay, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { AccountService } from "../../../entry/account.service";
import { IPluginDataCalendar, IPluginDataPairing, IPluginDataProvider, PluginCalendarInfo, PluginPairingRequestInfo, PluginProviderInfo, PluginResponse, PluginSpaceInfo } from "./plugin.data";
import { Logger } from "../../../lib/common/logger";
import { AppConfigService } from "../../../app.config";
import { IPluginInstance } from "app/API/v1/Plugin/api.plugin.data";

@Injectable()
export class WorkplacePluginService {
    private readonly TAG: string = 'pluginSvc';
    private readonly PLUGIN_SERVER_URI: string = 'https://servicenow.plugins.oniadea.com/workplace'; //'http://localhost:3000/workplace';
    private readonly UPDATE_DURATION: number = 300000;

    _calendarProviderInfo: { data: PluginProviderInfo, iadeaCareAccessInfo: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean }, lastUpdateTime: Date };
    _calendarInfo: { data: PluginCalendarInfo, lastUpdateTime: Date };
    _pairingInfo: { data: PluginPairingRequestInfo[], lastUpdateTime: Date };
    _reservableModuleInfo: { data: { id: string, name: string, checkinBeforeMeetingMinute: number }[], lastUpdateTime: Date };
    _pluginInstance: IPluginInstance;

    constructor(private http: HttpClient, private accountSvc: AccountService) { }

    reset(): void {
        this._calendarProviderInfo = null;
        this._calendarInfo = null;
        this._pairingInfo = null;
    }

    isPluginRegistered(): boolean {
        return this._calendarProviderInfo?.data?.isValid();
    }

    savePluginInstance(pInstance: IPluginInstance): void {
        this._pluginInstance = pInstance;
    }

    getPluginInstance(): IPluginInstance {
        return this._pluginInstance;
    }

    getCalendarProviderConfig(pluginName: string): Observable<{ isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }> {
        if (!this._calendarProviderInfo?.data?.isValid() || (Date.now() - this._calendarProviderInfo?.lastUpdateTime?.getTime() > this.UPDATE_DURATION)) {
            return this.queryPluginServer<IPluginDataProvider>(this.accountSvc.token, pluginName, 'provider', null, { method: 'GET', queryParams: [{ key: 'id', value: this.accountSvc.enterpriseID }] }).pipe(
                map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: IPluginDataProvider }) => {
                    Logger.logInfo(this.TAG, 'getCalendarProviderConfig', 'query res: ', res);
                    if (res.isFault) {
                        return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                    }

                    this._calendarProviderInfo = {
                        data: new PluginProviderInfo(res.data),
                        iadeaCareAccessInfo: {
                            accessKeyID: res.data.iadeaCareAccessInfo.accessKeyID,
                            accessKeyRemark: res.data.iadeaCareAccessInfo.accessKeyRemark,
                            isAccessKeyEnabled: res.data.iadeaCareAccessInfo.isAccessKeyEnabled
                        },
                        lastUpdateTime: new Date()
                    };

                    if (!this.isPluginRegistered()) {
                        throw 'Plugin is not registered';
                    }

                    return { isFault: false, data: this._calendarProviderInfo?.data, iadeaCareAccessInfo: this._calendarProviderInfo?.iadeaCareAccessInfo };
                })
            );
        }

        return of({ isFault: false, data: this._calendarProviderInfo.data, iadeaCareAccessInfo: this._calendarProviderInfo?.iadeaCareAccessInfo });
    }

    createCalendarProviderConfig(pluginName: string, data: Partial<IPluginDataProvider>): Observable<{ isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }> {
        return this.queryPluginServer<Partial<IPluginDataProvider>>(this.accountSvc.token, pluginName, 'provider', data, { method: 'POST' }).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: Partial<IPluginDataProvider> }) => {
                Logger.logInfo(this.TAG, 'createCalendarProviderConfig', 'query res: ', res);
                if (res.isFault) {
                    return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                }

                this._calendarProviderInfo = {
                    data: new PluginProviderInfo(res.data),
                    iadeaCareAccessInfo: {
                        accessKeyID: res.data.iadeaCareAccessInfo.accessKeyID,
                        accessKeyRemark: res.data.iadeaCareAccessInfo.accessKeyRemark,
                        isAccessKeyEnabled: res.data.iadeaCareAccessInfo.isAccessKeyEnabled
                    },
                    lastUpdateTime: new Date()
                };

                return { isFault: false, data: this._calendarProviderInfo?.data, iadeaCareAccessInfo: this._calendarProviderInfo?.iadeaCareAccessInfo };
            })
        );
    }

    removeCalendarProviderConfig(pluginName: string): Observable<{ isFault: boolean, error?: number | string, errorMessage?: string, data?: any }> {
        return this.queryPluginServer<void>(this.accountSvc.token, pluginName, 'provider', null, { method: 'DELETE', queryParams: [{ key: 'id', value: this.accountSvc.enterpriseID }] }).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: void }) => {
                if (!res.isFault) {
                    this.reset();
                }

                return res;
            })
        );
    }

    updateCalendarProviderConfig(pluginName: string, data: Partial<IPluginDataProvider>): Observable<{ isFault: boolean, errorMessage?: string, data?: PluginProviderInfo, iadeaCareAccessInfo?: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean } }> {
        return this.queryPluginServer<Partial<IPluginDataProvider>>(this.accountSvc.token, pluginName, 'provider', data, { method: 'PUT' }).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: Partial<IPluginDataProvider> }) => {
                Logger.logInfo(this.TAG, 'updateCalendarProviderConfig', 'query res: ', res);
                if (res.isFault) {
                    return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                }

                this._calendarProviderInfo = {
                    data: new PluginProviderInfo(res.data),
                    iadeaCareAccessInfo: {
                        accessKeyID: res.data.iadeaCareAccessInfo.accessKeyID,
                        accessKeyRemark: res.data.iadeaCareAccessInfo.accessKeyRemark,
                        isAccessKeyEnabled: res.data.iadeaCareAccessInfo.isAccessKeyEnabled
                    },
                    lastUpdateTime: new Date()
                };

                return { isFault: false, data: this._calendarProviderInfo?.data, iadeaCareAccessInfo: this._calendarProviderInfo?.iadeaCareAccessInfo };
            })
        );
    }

    getCalendarConfig(pluginName: string, force: boolean = false): Observable<{ isFault: boolean, errorMessage?: string, data?: PluginCalendarInfo }> {
        if (force || !this._calendarInfo?.data || (Date.now() - this._calendarInfo?.lastUpdateTime?.getTime() > this.UPDATE_DURATION)) {
            return this.queryPluginServer<IPluginDataCalendar>(this.accountSvc.token, pluginName, 'calendar', null, { method: 'GET', queryParams: [{ key: 'id', value: this.accountSvc.enterpriseID }] }).pipe(
                map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: IPluginDataCalendar }) => {
                    Logger.logInfo(this.TAG, 'getCalendarConfig', 'query res: ', res);
                    if (res.isFault) {
                        return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                    }

                    this._calendarInfo = {
                        data: new PluginCalendarInfo(`${this.PLUGIN_SERVER_URI}/svcnow/config/calendar/image`, this.accountSvc.enterpriseID, this.accountSvc.token, res.data),
                        lastUpdateTime: new Date()
                    };

                    return { isFault: false, data: this._calendarInfo?.data };
                })
            );
        }

        return of({ isFault: false, data: this._calendarInfo?.data });
    }

    updateCalendarConfig(pluginName: string, data: Partial<IPluginDataCalendar>): Observable<{ isFault: boolean, errorMessage?: string, data?: PluginCalendarInfo }> {
        return this.queryPluginServer<Partial<IPluginDataCalendar>>(this.accountSvc.token, pluginName, 'calendar', data, { method: 'POST' }).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: Partial<IPluginDataCalendar> }) => {
                Logger.logInfo(this.TAG, 'updateCalendarConfig', 'query res: ', res);
                if (res.isFault) {
                    return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                }

                this._calendarInfo = {
                    data: new PluginCalendarInfo(`${this.PLUGIN_SERVER_URI}/svcnow/config/calendar/image`, this.accountSvc.enterpriseID, this.accountSvc.token, res.data),
                    lastUpdateTime: new Date()
                };

                return { isFault: false, data: this._calendarInfo?.data };
            })
        );
    }

    updateCalendarImage(pluginName: string, files: { file: File, fieldname: string, filename: string }[]): any {
        const formData = new FormData();
        files.forEach(f => {
            formData.append(f.fieldname, f.file);
            formData.append('name', f.filename);
        });

        return this.queryPluginServer<FormData>(this.accountSvc.token, pluginName, 'calendar/image', formData, { method: 'POST', queryParams: [{ key: 'id', value: this.accountSvc.enterpriseID }] });
    }

    getReservableModules(pluginName: string, force: boolean = false): Observable<{ isFault: boolean, error?: number | string, errorMessage?: string, data?: { id: string, name: string, checkinBeforeMeetingMinute: number }[] }> {
        if (force || !this._reservableModuleInfo || (Date.now() - this._reservableModuleInfo?.lastUpdateTime?.getTime() > this.UPDATE_DURATION)) {
            return this.queryPluginServer<{ id: string, name: string, checkinBeforeMeetingMinute: number }[]>(this.accountSvc.token, pluginName, 'reservableModules', null, { method: 'GET', queryParams: [{ key: 'id', value: this.accountSvc.enterpriseID }, { key: 'force', value: force }] }).pipe(
                map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: { id: string, name: string, checkinBeforeMeetingMinute: number }[] }) => {
                    Logger.logInfo(this.TAG, 'getReservableModules', 'query res: ', res);
                    if (res.isFault) {
                        return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                    }

                    this._reservableModuleInfo = {
                        data: res.data,
                        lastUpdateTime: new Date()
                    };

                    return { isFault: false, data: this._reservableModuleInfo.data };
                })
            )
        }

        return of({ isFault: false, data: this._reservableModuleInfo.data });
    }

    updateReservableModules(pluginName: string, datas: { spaceID: string, reservableModuleID: string, checkinBeforeMeetingMinute: number }[]): Observable<{ isFault: boolean, errorMessage?: string, data?: any }> {
        return this.queryPluginServer<any>(this.accountSvc.token, pluginName, 'reservable', datas, { method: 'POST' }).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: any }) => {
                Logger.logInfo(this.TAG, 'updateReservableModules', 'query res: ', res);
                if (res.isFault) {
                    return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                }

                return { isFault: false, };
            })
        );
    }

    getPairingConfig(pluginName: string, force: boolean = false): Observable<{ isFault: boolean, error?: number | string, errorMessage?: string, data?: PluginPairingRequestInfo[] }> {
        if (force || !this._pairingInfo || (Date.now() - this._pairingInfo?.lastUpdateTime?.getTime() > this.UPDATE_DURATION)) {
            return this.queryPluginServer<IPluginDataPairing[]>(this.accountSvc.token, pluginName, 'pair', null, { method: 'GET', queryParams: [{ key: 'id', value: this.accountSvc.enterpriseID }, { key: 'force', value: force }] }).pipe(
                map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: Partial<IPluginDataPairing[]> }) => {
                    Logger.logInfo(this.TAG, 'getPairingConfig', 'query res: ', res);
                    if (res.isFault) {
                        return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                    }

                    this._pairingInfo = {
                        data: res.data.map(d => Object.assign(new PluginSpaceInfo(d), { devices: d.devices })),
                        lastUpdateTime: new Date()
                    };

                    return { isFault: false, data: this._pairingInfo?.data };
                })
            );
        }

        return of({ isFault: false, data: this._pairingInfo?.data });
    }

    updatePairingConfig(pluginName: string, data: Partial<PluginPairingRequestInfo[]>): Observable<{ isFault: boolean, errorMessage?: string, data?: any }> {
        return this.queryPluginServer<Partial<PluginPairingRequestInfo[]>>(this.accountSvc.token, pluginName, 'pair', data, { method: 'POST' }).pipe(
            map((res: { isFault: boolean, error?: number | string, errorMessage?: string, data?: Partial<PluginPairingRequestInfo[]> }) => {
                Logger.logInfo(this.TAG, 'updatePairingConfig', 'query res: ', res);
                if (res.isFault) {
                    return { isFault: true, error: res.error, errorMessage: res.errorMessage };
                }

                this._pairingInfo = {
                    data: res.data.map(d => Object.assign(new PluginSpaceInfo(d), { devices: d.devices })),
                    lastUpdateTime: new Date()
                };

                return { isFault: false, data: this._pairingInfo?.data };
            })
        );
    }

    private queryPluginServer<T>(token: string, pluginName: string, subpath: string, data: any, options?: { method: string, queryParams?: { key: string, value: any }[] }): Observable<{ isFault: boolean, error?: number | string, errorMessage?: string, data?: T }> {
        Logger.logInfo(this.TAG, 'queryPluginServer', 'input arguments: ', arguments);
        return of(`${this.PLUGIN_SERVER_URI}/${pluginName}/config/${subpath}`).pipe(
            concatMap((url: string) => {
                let header: HttpHeaders = new HttpHeaders();
                header = header.set('Accept', 'application/json');
                header = header.set('Authorization', 'Bearer ' + token);
                header = header.set('X-API-URL', AppConfigService.configs.server.api.baseUrl);

                let param: HttpParams = new HttpParams();
                options?.queryParams?.forEach((p: { key: string, value: any }) => {
                    param = param.set(p.key, p.value);
                });

                switch (options?.method) {
                    case 'DELETE':
                        {
                            return this.http.delete(url, { headers: header, params: param }).pipe(
                                delay(1000),
                                map((res: PluginResponse<T>) => {
                                    if (res.error !== 0) {
                                        throw res;
                                    }

                                    return { isFault: false, data: res.data };
                                })
                            );
                        }
                    case 'PUT':
                        {
                            let reqBody: any;
                            if (data instanceof FormData) {
                                reqBody = data;
                            }
                            else {
                                reqBody = {
                                    account: {
                                        enterpriseID: this.accountSvc.enterpriseID,
                                        enterpriseName: this.accountSvc.enterpriseName,
                                        userAccountID: this.accountSvc.accountID,
                                        userAccountName: this.accountSvc.accountName
                                    },
                                    data: data
                                };
                            }

                            return this.http.put(url, reqBody, { headers: header, params: param }).pipe(
                                delay(1000),
                                map((res: PluginResponse<T>) => {
                                    if (res.error !== 0) {
                                        throw res;
                                    }

                                    return { isFault: false, data: res.data };
                                })
                            )
                        }
                    case 'POST':
                        {
                            let reqBody: any;
                            if (data instanceof FormData) {
                                reqBody = data;
                            }
                            else {
                                reqBody = {
                                    account: {
                                        enterpriseID: this.accountSvc.enterpriseID,
                                        enterpriseName: this.accountSvc.enterpriseName,
                                        userAccountID: this.accountSvc.accountID,
                                        userAccountName: this.accountSvc.accountName
                                    },
                                    data: data
                                };
                            }

                            return this.http.post(url, reqBody, { headers: header, params: param }).pipe(
                                delay(1000),
                                map((res: PluginResponse<T>) => {
                                    if (res.error !== 0) {
                                        throw res;
                                    }

                                    return { isFault: false, data: res.data };
                                })
                            )
                        }
                    default:
                    case 'GET':
                        {
                            return this.http.get(url, { headers: header, params: param }).pipe(
                                map((res: PluginResponse<T>) => {
                                    if (res.error !== 0) {
                                        throw res;
                                    }

                                    return { isFault: false, data: res.data };
                                })
                            );
                        }
                }
            }),
            catchError((err) => {
                Logger.logError(this.TAG, 'queryPluginServer', 'exception: ', err);
                return of({ isFault: true, error: err.error, errorMessage: err.errorMessage || `[${err.status}] ${err.statusText}` })
            })
        );
    }
}