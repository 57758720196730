import { NgModule } from '@angular/core';
import { DomainNameValidatorDirective } from './domain-name-validator.directive';
import { EqualValidator } from './equal-validator.directive';
import { UppercaseDirective } from './uppcase.directive';
import { LazyLoadImageDirective } from './lazy-load-img.directive';

@NgModule({
    declarations: [
        EqualValidator,
        UppercaseDirective,
        DomainNameValidatorDirective,
        LazyLoadImageDirective
    ],
    exports: [
        EqualValidator,
        UppercaseDirective,
        DomainNameValidatorDirective,
        LazyLoadImageDirective  
    ]
})
export class CustomDirectiveModule { }