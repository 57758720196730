import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConstantService } from "../../lib/common/constant.service";
import { IUIElement } from "../uiElement.interface";

import { PowerSaveInfo } from "./power-save.data";

@Component({
    selector: 'na-powersave',
    templateUrl: './power-save.component.html',
    styleUrls: ['../uiElement.style.css']
})
export class PowerSaveComponent implements OnInit, IUIElement {

    _info: PowerSaveInfo = new PowerSaveInfo();

    @Input('keyPair')  _keyPair: { [name: string]: { value: any } };
    @Input('showBorder') _showBorder: boolean = false;
    @Input('disabled') _disabled: boolean = false;
    @Input('unsupportReason') _unsupportReason: string;
    @Input('lock') _lockInfo: { isSync: boolean, policyID: string, policyName: string };

    @Output() onPowersaveChanged = new EventEmitter<{ data: PowerSaveInfo, isValid: boolean }>();

    constructor(private constantSvc: ConstantService) {}

    ngOnInit(): void {
        if (this._keyPair) {
            this._info.enabled = !this._keyPair[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE].value;
            this._info.timeout = this._keyPair[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT].value;
        }
    }

    onTimeoutChange(timeout: number): void {
        this._info.timeout = timeout > 300 ? 300 : timeout < 1 ? 1 : timeout;
        this.updatePowersaveChange();
    }

    enablePowersave(checked: boolean): void {
        if (this._info.enabled !== checked) {
            this._info.enabled = checked;
            this.updatePowersaveChange();
        }
    }

    private updatePowersaveChange(): void {
        this.onPowersaveChanged.emit({ data: this._info, isValid: true })
    }
}