import { BasicConfigItem } from './device-config.item';
import { ConfigurableItemKey } from '../../../../../app/lib/common/common.data';
import { AppStartInfo, AppStartOverlayInfo } from '../../../device/data/device-info';
import { MaintenancePlaybackInfo } from '../../../../uiElement/maintenancePlayback/mtPlayback.data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { LockScreenInfo } from '../../../../uiElement/schedule/screen/screen.data';
import { AppStartPlaybackMode } from '../../../../uiElement/appstart/appstart.data';

export const MENU_GENERAL: string = 'menu-general';
export const MENU_CONTENT: string = 'menu-content';
export const MENU_CONTENT_APPSTART: string = 'menu-content-appstart';
export const MENU_CONTENT_QRCODE: string = 'menu-content-qrcode';
export const MENU_CONTENT_MAINTENANCE: string = 'menu-content-maintenance';
export const MENU_SCHEDULE: string = 'menu-schedule';
export const MENU_SCHEDULE_TIME: string = 'menu-schedule-time';
export const MENU_SCHEDULE_REBOOT: string = 'menu-schedule-reboot';
export const MENU_SCHEDULE_LOCKSCREEN: string = 'menu-schedule-lockScreen';

export class DeviceBasicConfigControl {
    private _name: BasicConfigItem<string> = new BasicConfigItem<string>();
    get name(): string { return this._name.settingMap[this.constantSvc.DEVKEY_INFO_PNAME].value; }
    set name(v: string) { this._name.settingMap[this.constantSvc.DEVKEY_INFO_PNAME].value = v; }
    get ctrl_name(): BasicConfigItem<string> { return this._name; }

    // appstart
    private _appStart: BasicConfigItem<any> = new BasicConfigItem<any>();
    get appStart(): BasicConfigItem<any> { return this._appStart; }
    private _appStartPlaybackMode: AppStartPlaybackMode;
    set appstartPlaybackMode(v: AppStartPlaybackMode) { this._appStartPlaybackMode = v; }

    // qrcode
    private _qrcode: BasicConfigItem<AppStartOverlayInfo> = new BasicConfigItem<AppStartOverlayInfo>();
    get ctrl_qrcode(): BasicConfigItem<AppStartOverlayInfo> { return this._qrcode; }
    get qrcode(): AppStartOverlayInfo { return this._qrcode.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value; }

    // powersave
    private _powersave: BasicConfigItem<any> = new BasicConfigItem<any>();
    get ctrl_powersave(): BasicConfigItem<any> { return this._powersave; }

    // resolution
    private _resolution: BasicConfigItem<string> = new BasicConfigItem<string>();
    get resolution(): string { return this._resolution.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_FORMAT].value; }
    set resolution(v: string) { this._resolution.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_FORMAT].value = v; }

    // orientation
    private _orientation: BasicConfigItem<(string | number)> = new BasicConfigItem<(string | number)>();
    get orientation(): string | number { return this._orientation.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION].value; }
    set orientation(orientation: string | number) { this._orientation.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION].value = orientation; }

    // brightness
    private _brightness: BasicConfigItem<{ data: { time: number, brightness: number }[], cmd: string }> = new BasicConfigItem<{ data: { time: number, brightness: number }[], cmd: string }>();
    get brightness(): { data: { time: number, brightness: number }[], cmd: string } { return this._brightness.settingMap[this.constantSvc.DEVKEY_FAKE_BRIGHTNESS].value; }
    set brightness(brightness: { data: { time: number, brightness: number }[], cmd: string }) { this._brightness.settingMap[this.constantSvc.DEVKEY_FAKE_BRIGHTNESS].value = brightness; }

    // volume
    private _volume: BasicConfigItem<number> = new BasicConfigItem<number>();
    get volume(): number { return this._volume.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX].value; }
    set volume(v: number) { this._volume.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX].value = v; }
    get volumeMax(): number { return this._volume.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX].value; }
    set volumeMax(v: number) { this._volume.settingMap[this.constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX].value = v; }

    // debugger
    private _enableADBDebug: BasicConfigItem<boolean> = new BasicConfigItem<boolean>();
    get enableUSBDebug(): boolean { return this._enableADBDebug.settingMap[this.constantSvc.DEVKEY_DEBUG_ADB_ENABLED].value; }
    set enableUSBDebug(v: boolean) {
        this._enableADBDebug.settingMap[this.constantSvc.DEVKEY_DEBUG_ADB_ENABLED].value = v;
        if (!v) {
            this.enableTCPDebug = false;
        }
    }
    get enableTCPDebug(): boolean { return this._enableADBDebug.settingMap[this.constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED].value; }
    set enableTCPDebug(v: boolean) { this._enableADBDebug.settingMap[this.constantSvc.DEVKEY_DEBUG_ADB_TCP_ENABLED].value = v; }

    private _enableHDCP: BasicConfigItem<boolean> = new BasicConfigItem<boolean>();
    get enableHDCP(): boolean { return this._enableHDCP.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_HDCP_ENABLED].value; }
    set enableHDCP(v: boolean) {
        this._enableHDCP.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_HDCP_ENABLED].value = v;
    }

    // timezone
    private _timezone: BasicConfigItem<string> = new BasicConfigItem<string>();
    get timezone(): string { return this._timezone.settingMap[this.constantSvc.DEVKEY_TIME_TIMEZONE].value; }
    set timezone(v: string) { this._timezone.settingMap[this.constantSvc.DEVKEY_TIME_TIMEZONE].value = v; }

    // timeserver
    private _timeserver: BasicConfigItem<any> = new BasicConfigItem<any>();
    get ctrl_timeserver(): BasicConfigItem<any> { return this._timeserver; }
    get enableTimeserver(): boolean { return this._timeserver.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value; }
    set enableTimeserver(v: boolean) { this._timeserver.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value = v; }
    get timeserverSyncLocation(): string { return this._timeserver.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value; }
    set timeserverSyncLocation(v: string) { this._timeserver.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value = v; }

    // dailyReboot
    private _dailyReboot: BasicConfigItem<any> = new BasicConfigItem<any>();
    get dailyReboot(): BasicConfigItem<any> { return this._dailyReboot; }
    set enableDailyReboot(v: boolean) { this._dailyReboot.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED].value = v; }
    set dailyRebootTime(v: string) { this._dailyReboot.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME].value = v; }
    set dailyReboot(v: any) { this._dailyReboot.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT].value = v; }

    // maintenance playback
    private _maintenancePlayback: BasicConfigItem<any> = new BasicConfigItem<any>();
    get ctrl_maintenancePlayback(): BasicConfigItem<any> { return this._maintenancePlayback; }
    get maintenancePlayback(): any { return this._maintenancePlayback.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE].value; }
    set maintenancePlayback(v: any) { this._maintenancePlayback.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE].value = v; }

    // lock screen
    private _lockScreen: BasicConfigItem<any> = new BasicConfigItem<any>();
    get ctrl_lockScreen(): BasicConfigItem<any> { return this._lockScreen; }
    get lockScreen(): any { return this._lockScreen.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN].value; }
    set lockScreen(v: any) { this._lockScreen.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN].value = v; }

    constructor(private constantSvc: ConstantService) { }

    init(
        name: { key: string, value: string },
        appStart: { key: string, value: AppStartInfo },
        resolution: { key: string, value: string },
        orientation: { key: string, value: string | number },
        volumeItem: {
            volume: { key: string, value: number },
            volumeMax: { key: string, value: number }
        },
        debugItem: {
            enableUSB: { key: string, value: boolean },
            enableTCP: { key: string, value: boolean }
        },
        enableHDCP: { key: string, value: boolean },
        timezone: { key: string, value: string },
        timeserverItem: {
            enableTimeserver: { key: string, value: boolean },
            syncLocation: { key: string, value: string }
        },
        dailyRebootItem: {
            enableDailyReboot: { key: string, value: boolean },
            rebootTime: { key: string, value: string },
            reboot: { key: string, value: { days: string[], time: string } }
        },
        scheduleItem: {
            maintenancePlayback: { key: string, value: any },
            lockScreen: { key: string, value: any }
        },
        powerSaveItem: {
            disablePowersave: { key: string, value: boolean },
            powersaveTimeout: { key: string, value: number },
            powersaveAction: { key: string, value: string }
        },
        brightness: { key: string, value: { data: { time: number, brightness: number }[], cmd: string } }
    ) {
        this._name.init(ConfigurableItemKey.PlayerName, 'key-dev-name', [{ key: name.key, value: name.value, langKey: 'key-dev-name' }]);

        this._appStart.init(ConfigurableItemKey.AppStart, 'key-dev-appstart', [
            { key: this.constantSvc.DEVKEY_APPSTART_CONTENTURL, value: appStart.value?.uri },
            { key: this.constantSvc.DEVKEY_APPSTART_PACKAGENAME, value: appStart.value?.packageName },
            { key: this.constantSvc.DEVKEY_APPSTART_CLASSNAME, value: appStart.value?.className },
            { key: this.constantSvc.DEVKEY_APPSTART_ACTION, value: appStart.value?.action },
            ...this.constantSvc.getAppstartExtraList().map(extra => ({ key: extra.property, value: appStart.value?.extras[extra.property] }))
        ]);
        this._qrcode.init(ConfigurableItemKey.OverlayQRCode, 'QR code', [
            {
                key: this.constantSvc.DEVKEY_APPSTART_OVERLAY,
                value: appStart.value?.overlay,
                copyFunc: (a: AppStartOverlayInfo) => { return a ? a.copy() : a },
                equalFunc: (a: AppStartOverlayInfo, b: AppStartOverlayInfo) => { return AppStartOverlayInfo.equal(a, b); }
            }
        ]);
        this._powersave.init(ConfigurableItemKey.Powersave, 'Power save', [
            { key: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE, value: true },
            { key: this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT, value: 10 }
        ]);

        this._resolution.init(ConfigurableItemKey.Resolution, 'key-dev-resolution', [{ key: resolution.key, value: resolution.value, langKey: 'key-dev-resolution' }]);
        this._orientation.init(ConfigurableItemKey.DisplayOrientation, 'key-dev-orientation', [{ key: orientation.key, value: orientation.value, langKey: 'key-dev-orientation' }]);
        this._volume.init(ConfigurableItemKey.Volume, 'key-volume', [
            { key: volumeItem.volume.key, value: volumeItem.volume.value, langKey: 'key-volume' },
            { key: volumeItem.volumeMax.key, value: volumeItem.volumeMax.value, langKey: 'key-volumeMax', needOutput: false }
        ]);
        this._enableADBDebug.init(ConfigurableItemKey.ADB, 'key-debug', [
            {
                key: debugItem.enableUSB.key, value: debugItem.enableUSB.value, langKey: 'key-dev-usbDebugEnable'
            },
            {
                key: debugItem.enableTCP.key, value: debugItem.enableTCP.value, langKey: 'key-dev-tcpDebugEnable'
            }
        ]);
        this._enableHDCP.init(ConfigurableItemKey.HDCP, 'key-dev-hdcpEnable', [{ key: enableHDCP.key, value: enableHDCP.value, langKey: 'key-dev-hdcpEnable' }]);
        this._timezone.init(ConfigurableItemKey.Timezone, 'key-dev-timezone', [{ key: timezone.key, value: timezone.value, langKey: 'key-dev-timezone' }]);
        this._timeserver.init(ConfigurableItemKey.Timeserver, 'key-timeServer', [
            {
                key: timeserverItem.enableTimeserver.key, value: timeserverItem.enableTimeserver.value, langKey: 'key-timeServer'
            },
            {
                key: timeserverItem.syncLocation.key, value: timeserverItem.syncLocation.value, langKey: 'key-timeServerLocation'
            }
        ]);
        this._dailyReboot.init(ConfigurableItemKey.DailyReboot, 'key-dev-dailyRebootTime', [
            {
                key: dailyRebootItem.enableDailyReboot.key, value: dailyRebootItem.enableDailyReboot.value, langKey: 'key-dev-dailyRebootEnable'
            },
            {
                key: dailyRebootItem.reboot.key, value: dailyRebootItem.reboot.value, langKey: 'key-dev-dailyReboot',
            },
            {
                key: dailyRebootItem.rebootTime.key, value: dailyRebootItem.rebootTime.value, langKey: 'key-dev-dailyRebootTime'
            }
        ]);
        this._maintenancePlayback.init(ConfigurableItemKey.MaintenancePlayback, 'key-maintenancePlayback', [
            {
                key: scheduleItem.maintenancePlayback.key,
                value: scheduleItem.maintenancePlayback.value,
                langKey: 'key-maintenancePlayback',
                copyFunc: (a: MaintenancePlaybackInfo) => { return a ? a.copy() : a },
                equalFunc: (a: MaintenancePlaybackInfo, b: MaintenancePlaybackInfo) => { return MaintenancePlaybackInfo.equal(a, b); }
            }
        ]);
        this._lockScreen.init(ConfigurableItemKey.LockScreen, 'key-lockScreen', [
            {
                key: scheduleItem.lockScreen.key,
                value: scheduleItem.lockScreen.value,
                langKey: 'key-lockScreen',
                copyFunc: (a: LockScreenInfo) => { return a ? a.copy() : a },
                equalFunc: (a: LockScreenInfo, b: LockScreenInfo) => { return LockScreenInfo.equal(a, b); }
            }
        ]);
        this._powersave.init(ConfigurableItemKey.Powersave, 'Power save', [
            { key: powerSaveItem.disablePowersave.key, value: powerSaveItem.disablePowersave.value, langKey: 'Disable power save' },
            { key: powerSaveItem.powersaveTimeout.key, value: powerSaveItem.powersaveTimeout.value, langKey: 'Power save timeout' },
            { key: powerSaveItem.powersaveAction.key, value: powerSaveItem.powersaveAction.value, langKey: 'Power save action', needCompare: false }
        ]);
        this._brightness.init(ConfigurableItemKey.Brightness, 'Brightness', [
            { key: brightness.key, value: brightness.value, langKey: 'Brightness level', }
        ]);
    }

    clear(): void {
        this._name.clear();
        this._timezone.clear();
        this._orientation.clear();
        this._resolution.clear();
        this._dailyReboot.clear();
        this._volume.clear();
        this._qrcode.clear();
        this._powersave.clear();
        this._lockScreen.clear();
    }

    hasChangeItems(): boolean {
        return this.getChangeItems().length > 0;
    }

    getAllConfigurableItems(): BasicConfigItem<any>[] {
        return [
            this._name,
            this._appStart,
            this._qrcode,
            this._powersave,
            this._resolution,
            this._orientation,
            this._volume,
            this._enableADBDebug,
            this._enableHDCP,
            this._timezone,
            this._timeserver,
            this._dailyReboot,
            this._maintenancePlayback,
            this._lockScreen,
            this._brightness
        ];
    }

    getChangeItems(): { name: string, value: any, langKey?: string, secure?: boolean, warning?: boolean, isChanged?: boolean, origin?: any }[] {
        const changeItems: { name: string, value: any, langKey?: string, secure?: boolean, warning?: boolean, isChanged?: boolean, origin?: any }[] = [];
        let bAppStartChange: boolean = false;
        const appstartExtras = this.constantSvc.getAppstartExtraList();
        this.getAllConfigurableItems().filter(item => item.isChanged()).forEach((item: BasicConfigItem<any>) => {
            item.transformToSettingData().forEach(i => {
                if (i.name === this.constantSvc.DEVKEY_APPSTART_CONTENTURL ||
                    i.name === this.constantSvc.DEVKEY_APPSTART_PACKAGENAME ||
                    i.name === this.constantSvc.DEVKEY_APPSTART_ACTION ||
                    i.name === this.constantSvc.DEVKEY_APPSTART_CLASSNAME ||
                    i.name === this.constantSvc.DEVKEY_APPSTART_OVERLAY ||
                    i.name === this.constantSvc.DEVKEY_APPSTART_SCREENSAVER ||
                    appstartExtras.find(extra => extra.property === i.name)) {
                    bAppStartChange = true;
                }
                else {
                    changeItems.push(i);
                }
            });
        });

        if (bAppStartChange) {
            const appStartUpdated: { data?: string, packageName?: string, className?: string, action?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } = {
                data: this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL].value,
                extras: {}
            }
            //set packageName, className, or action to the original value depends on the appstart playback mode.
            //url | apk playbackmode is reserved for future use
            switch (this._appStartPlaybackMode) {
                case AppStartPlaybackMode.url:
                    {
                        //do nothing, keep data only.
                    }
                    break;
                default:
                case AppStartPlaybackMode.apk:
                    {
                        if (this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME].value) {
                            appStartUpdated.packageName = this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_PACKAGENAME].value;
                        }
                        if (this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_CLASSNAME].value) {
                            appStartUpdated.className = this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_CLASSNAME].value;
                        }
                        if (this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_ACTION].value) {
                            appStartUpdated.action = this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_ACTION].value;
                        }
                    }
                    break;
            }

            appstartExtras.forEach(extra => { appStartUpdated.extras[extra.property] = this._appStart.settingMap[extra.property].value; });
            const overlayTargetData = this._qrcode.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value.toSettingData();
            if (overlayTargetData) {
                appStartUpdated.overlay = overlayTargetData;
            }

            const appStartOrigin: { data?: string, extras?: { [key: string]: any }, overlay?: { data: string, type: string, extras?: any, styles?: any[] } } = {
                data: this._appStart.settingMap[this.constantSvc.DEVKEY_APPSTART_CONTENTURL].origin,
                extras: {}
            }
            appstartExtras.forEach(extra => { appStartOrigin.extras[extra.property] = this._appStart.settingMap[extra.property].origin; });
            const overlayOriginData = this._qrcode.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].origin.toSettingData();
            if (overlayOriginData) {
                appStartOrigin.overlay = overlayOriginData;
            }

            changeItems.push({
                name: 'app.start',
                value: appStartUpdated,
                origin: appStartOrigin
            });
        }

        return changeItems;
    }

    skip(): void {
        this.clear();
    }
}
