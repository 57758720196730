import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';
import { SavedAdvanceFilterRawData } from './api.advFilter.data';

export type IDeleteSavedFilterPathParameter = {
    advancedFilterID: string;
};

export class APIDeleteSavedFilterManager extends APIBaseManager<IDeleteSavedFilterPathParameter, void, void, SavedAdvanceFilterRawData[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_DeleteSavedFilter';
        this._apiMethod = 'DELETE';
    }

    protected getRequestURL(pathParameters: IDeleteSavedFilterPathParameter, queryParameters: void): string {
        return super.getRequestURL() + `advancedFilters/${pathParameters.advancedFilterID}`;
    }
}