<div>
    <div *ngIf="_newTags.length === 0 && _removedTags.length === 0; else templateUpdatedTags">
        Nothing to update
    </div>
    <ng-template #templateUpdatedTags>
        <div *ngIf="_newTags.length > 0">
            <label>New tag(s)</label>
            <div class="border p-2">
                <span *ngFor="let tag of _newTags; let last = last">
                    {{ tag }}<ng-container *ngIf="!last">, </ng-container> 
                </span>
            </div>
        </div>
        <div *ngIf="_removedTags.length > 0">
            <label>Removed tag(s)</label>
            <div class="border p-2">
                <span *ngFor="let tag of _removedTags; let last = last">
                    {{ tag }}<ng-container *ngIf="!last">, </ng-container> 
                </span>
            </div>
        </div>
    </ng-template>
</div>