<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" [disabled]="_updating" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div>
                Are you sure to remove the filter <strong>{{ data?.name }}</strong>
            </div>
            <div *ngIf="_updating" class="loading-box mx-auto mt-2"></div>
            <div *ngIf="_errorMessage" class="mx-auto mt-2 text-center warning-block">{{ _errorMessage }}</div>
            <div class='mt-4 mb-2 d-flex justify-content-center'>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_updating"
                    data-dismiss="modal">
                    <i class="fas fa-times mr-1"></i>
                    Cancel
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_updating" (click)="removeFilter()">
                    <i class="fas fa-check mr-1"></i>
                    OK
                </button>
            </div>
        </div>
    </div>
</div>