import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { LicenseScopeType } from 'app/content/license/license.data';
import { ConfigDialogPage, DLG_NAME_TAG_UPDATE } from '../base/base-config-data';
import { HelperLib } from 'app/lib/common/helper.lib';
import { CustomResponse } from 'app/lib/common/common.data';
import { DeviceInfo } from 'app/content/device/data/device-info';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class TagUpdateFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_TAG_UPDATE;
        // all devices can set tag
        this._licenseScopeType = LicenseScopeType.all;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        switch (this._page) {
            case ConfigDialogPage.checking:
                {
                    const { commonTags, uncommonTags } = this.getAllCommonAndUncommonTags(this._legalDevices);
                    this._actionData.commonTags = Array.from(commonTags).map(c => ({ tagName: c, toRemove: false }));
                    this._actionData.uncommonTags = Array.from(uncommonTags).map(c => ({ tagName: c, toRemove: false }));
                    this._actionData.newlyAddedTagStr = '';
                }
                break;
        }

        super.goNext();
    }

    private getAllCommonAndUncommonTags(devices: DeviceInfo[]): { commonTags: Set<string>, uncommonTags: Set<string> } {
        if (devices.length === 0) {
            return { commonTags: new Set<string>(), uncommonTags: new Set<string>() };
        }

        const allTagSets: Set<string>[] = devices.map(d => d.virtualDeviceTags);
        const commonTags = allTagSets.reduce((acc, curr) => new Set([...acc].filter(tag => curr.has(tag))));
        const allTags = allTagSets.reduce((acc, curr) => new Set([...acc, ...curr]));
        const uncommonTags = new Set([...allTags].filter(tag => !commonTags.has(tag)));

        return { commonTags, uncommonTags };
    }

    protected submit(): void {
        super.submit();

        let newTags: string[] = [];
        if (this._actionData.newlyAddedTagStr?.trim()) {
            newTags = this._actionData.newlyAddedTagStr.split(',').map(t => t.trim()) || [];
        }

        const removedTags: string[] = [...this._actionData.commonTags, ...this._actionData.uncommonTags].filter(t => t.toRemove).map(t => t.tagName) || [];

        this.devSvc.updateDeviceTags(
            newTags.length > 0 ? { labelName: newTags, virtualDeviceIDList: this._legalDevices.map(d => d.virtualId) } : null,
            removedTags.length > 0 ? { labelName: removedTags, virtualDeviceIDList: this._legalDevices.map(d => d.virtualId) } : null
        ).subscribe((res: CustomResponse<any>) => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });

    }
}