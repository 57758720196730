import { Component, ElementRef, ViewChild } from '@angular/core';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { VirtualDlgComponent } from '../../../../../app/content/virtual/dlg/virtual-dlg.component';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { AccountService } from '../../../../../app/entry/account.service';
import { DeviceService } from '../../../../../app/content/device/device.service';
import { DeviceInfo } from '../../../../../app/content/device/data/device-info';

@Component({
    templateUrl: './tag-update-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './tag-update-action-subform.component.css']
})
export class TagUpdateActionSubFormComponent extends VirtualDlgComponent implements DevFuncActionInterface {
    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        this._bSpecific = this._actionData.bSpecific;
        this._devices = this._actionData.deviceList;
        this._commonTags = this._actionData.commonTags;
        this._uncommonTags = this._actionData.uncommonTags;
        this.updateRemovedTagStatistic();
    }

    actionStatusUpdateHandler?: (isValid: boolean) => void;

    _loading: boolean = false;
    _bSpecific: boolean = false;
    _devices: DeviceInfo[] = [];

    _currentTags: Set<string> = new Set();
    _commonTags: { tagName: string, toRemove: boolean }[] = [];
    _uncommonTags: { tagName: string, toRemove: boolean }[] = [];
    _removedTags: string[] = [];
    _duplicateTags: string[] = [];

    private _newlyTagSubscription: Subscription;
    private _newTagRef: ElementRef;
    @ViewChild('newTagStr')
    set newTagStr(v: ElementRef) {
        if (v && this._newTagRef !== v) {
            if (this._newlyTagSubscription) {
                this._newlyTagSubscription.unsubscribe();
            }

            this._newTagRef = v;
            this._newlyTagSubscription = fromEvent(this._newTagRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._actionData.newlyAddedTagStr = e.target.value;
            });
        }
    }

    constructor(protected accountSvc: AccountService, private constantSvc: ConstantService, private devSvc: DeviceService) {
        super(accountSvc)
    }

    ngOnInit(): void {
        this._currentTags = new Set(this._devices.flatMap(d => Array.from(d.virtualDeviceTags)));

        super.ngOnInit();
    }

    markTagToRemove(tag: { tagName: string, toRemove: boolean }): void {
        tag.toRemove = true;
        this.updateRemovedTagStatistic();
    }

    resetRemovedTag(): void {
        [...this._commonTags, ...this._uncommonTags].forEach(t => t.toRemove = false);
        this.updateRemovedTagStatistic();
    }

    private updateRemovedTagStatistic(): void {
        this._removedTags = [...this._commonTags, ...this._uncommonTags].filter(t => t.toRemove).map(t => t.tagName);
    }
}