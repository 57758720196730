<div>
  <div class="mb-1">
    Following are the setting(s) to apply :
  </div>
  <div class="list-group mb-2">
    <div class="list-group-item list-group-header black">
      <div class="row">
        <div class="col-3 title">
          Item
        </div>
        <div class="col-9 title text-break">
          Value
        </div>
      </div>
    </div>
    <div class="list-group-content list-group-hoverless">
      <ng-template ngFor let-config [ngForOf]="_configList">
        <div *ngIf="config.isConfig" class="list-group-item">
          <div class="row">
            <div class="col-md-3 col-12 d-flex align-items-center text-break">
              <strong>{{ config.langKey | translate | capitalFirstWord }}</strong>
            </div>
            <div class="col-md-9 col-12">
              <div [ngSwitch]="config.key">
                <ng-template [ngSwitchCase]="_enumConfigKey.LockScreen">
                  <na-lockScreen-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_LOCKSCREEN].value">
                  </na-lockScreen-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.AppStart">
                  <na-appstart-preview [keyPair]="config.settingMap" [target]="'value'"></na-appstart-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.OverlayQRCode">
                  <na-overlay-qrcode-preview [data]="config.settingMap[constantSvc.DEVKEY_APPSTART_OVERLAY].value">
                  </na-overlay-qrcode-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.Powersave">
                  <na-powersave-preview [keyPair]="config.settingMap" [target]="'value'"></na-powersave-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.Brightness">
                  <na-brightness-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_BRIGHTNESS].value">
                  </na-brightness-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.Volume">
                  <na-volume-preview [volume]="config.settingMap[constantSvc.DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL].value">
                  </na-volume-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.MaintenancePlayback">
                  <na-mtPlayback-preview [data]="config.settingMap[constantSvc.DEVKEY_FAKE_MAINTENANCE].value">
                  </na-mtPlayback-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.Timeserver">
                  <na-timeserver-preview [enabled]="config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value"
                    [source]="config.settingMap[constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value">
                  </na-timeserver-preview>
                </ng-template>
                <ng-template [ngSwitchCase]="_enumConfigKey.DailyReboot">
                  <na-dailyReboot-preview [config]="config.settingMap" [target]="'value'"></na-dailyReboot-preview>
                </ng-template>
                <div *ngSwitchDefault>
                  <span>{{ getConfigValue(config) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>