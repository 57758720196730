import { Injectable } from '@angular/core';

import { DevFuncConfirmItem } from './dev-func-confirm-item';
import {
  DLG_NAME_ISSUE_REPORT,
  DLG_NAME_REBOOT,
  DLG_NAME_FIRMWARE_UPDATE,
  DLG_NAME_APK_UPDATE,
  DLG_NAME_BASIC_CONFIG,
  DLG_NAME_NET_CONFIG,
  DLG_NAME_ALERT,
  DLG_NAME_MULTI_BASIC_CONFIG,
  DLG_NAME_SECURITY,
  DLG_NAME_CLEARCACHE,
  DLG_NAME_CLEARAPPDATA,
  DLG_NAME_TAG_UPDATE
} from '../base-config-data';

import { IssueConfirmSubFormComponent } from '../../troubleshoot/confirm/issue-confirm-subform.component';
import { RebootConfirmSubFormComponent } from '../../reboot/confirm/reboot-confirm-subform.component';
import { FirmwareConfirmSubFormComponent } from '../../firmware/confirm/firmware-confirm-subform.component';
import { BasicConfigConfirmSubFormComponent } from '../../config/confirm/basic-config-confirm-subform.component';
import { NetConfigConfirmSubFormComponent } from '../../config/confirm/net-config-confirm-subform.component';
import { AlertConfirmSubFormComponent } from '../../alert/confirm/alert-confirm-subform.component';
import { APKConfirmSubFormComponent } from '../../apk/confirm/apk-confirm-subform.component';
import { MultiBasicConfigConfirmSubFormComponent } from '../../config-m/confirm/m-basic-config-confirm-subform.component';
import { SecurityConfirmSubFormComponent } from '../../security/confirm/security-confirm-subform.component';
import { ClearCacheConfirmSubFormComponent } from '../../clear/confirm/clearCache-confirm-subform.component';
import { ClearAppDataConfirmSubFormComponent } from '../../clear/confirm/clearAppData-confirm-subform.component';
import { TagUpdateConfirmSubFormComponent } from '../../tag/confirm/tag-update-confirm-subform.component';

@Injectable()
export class DevFuncConfirmService {
  private _items: DevFuncConfirmItem[] = [];

  constructor() {
    this._items = [
      new DevFuncConfirmItem(IssueConfirmSubFormComponent, DLG_NAME_ISSUE_REPORT),
      new DevFuncConfirmItem(RebootConfirmSubFormComponent, DLG_NAME_REBOOT),
      new DevFuncConfirmItem(FirmwareConfirmSubFormComponent, DLG_NAME_FIRMWARE_UPDATE),
      new DevFuncConfirmItem(APKConfirmSubFormComponent, DLG_NAME_APK_UPDATE),
      new DevFuncConfirmItem(BasicConfigConfirmSubFormComponent, DLG_NAME_BASIC_CONFIG),
      new DevFuncConfirmItem(MultiBasicConfigConfirmSubFormComponent, DLG_NAME_MULTI_BASIC_CONFIG),
      new DevFuncConfirmItem(NetConfigConfirmSubFormComponent, DLG_NAME_NET_CONFIG),
      new DevFuncConfirmItem(AlertConfirmSubFormComponent, DLG_NAME_ALERT),
      new DevFuncConfirmItem(SecurityConfirmSubFormComponent, DLG_NAME_SECURITY),
      new DevFuncConfirmItem(ClearCacheConfirmSubFormComponent, DLG_NAME_CLEARCACHE),
      new DevFuncConfirmItem(ClearAppDataConfirmSubFormComponent, DLG_NAME_CLEARAPPDATA),
      new DevFuncConfirmItem(TagUpdateConfirmSubFormComponent, DLG_NAME_TAG_UPDATE)
    ];
  }

  getAllItems(): DevFuncConfirmItem[] {
    return this._items;
  }

  getItemByName(name: string): DevFuncConfirmItem {
    return this._items.find(i => i.name === name);
  }
}
