<div class="filter-picker">
    <div class="input-group input-group-sm mb-1">
        <input #search type='text' class="form-control form-control-sm search-box" [class.active]="_searchTxt"
            [disabled]="_loading" name='search' placeholder="Search saved filter ..." aria-label="Search saved filter">
        <div class="input-group-append">
            <span class="input-group-text">
                <i class="fas fa-search"></i>
            </span>
        </div>
    </div>
    <div class="filters" [class.active]="_displayedSavedFilters.length !== _savedFilters.length">
        <div class="title" [class.active]="_selectedSavedFilter === DEFAULT_SAVED_FILTER"
            (click)="selectDefaultSavedFilter()">
            <i class="fas fa-caret-down mr-1"></i>
            Saved filter
        </div>
        <div class="ml-3">
            <div *ngFor="let fInfo of _displayedSavedFilters" class="filter-item"
                [class.active]="_selectedSavedFilter?.id === fInfo.filter.id" (click)="selectSavedFilter(fInfo)">
                <i class="fas fa-caret-right mr-1"></i>
                <span class="name mr-1">{{ fInfo.filter.name }}</span>

                (<span *ngIf="!fInfo.loading; else templateLoadingFilterStatistic">
                    <span class="online-dev-link" (click)="showOnlineOnly(fInfo);$event.stopPropagation()">
                        {{ fInfo.filter.online === undefined ? '-' : fInfo.filter.online }}
                    </span>
                    / {{ fInfo.filter.total === undefined ? '-' : fInfo.filter.total }}
                </span>
                <ng-template #templateLoadingFilterStatistic>
                    <span [class.text-loading]="true" [attr.data-text]="'....'">....</span>
                </ng-template>)
            </div>
        </div>
    </div>
</div>