import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, mergeMap, takeUntil } from 'rxjs/operators';

import { DeviceGroupInfo, DEVICE_GROUP_FUNC_EXCLUDE, DEVICE_GROUP_FUNC_MOVE } from './group/dev-group.data';
import { DeviceGroupService } from './group/dev-group.service';
import { HelperLib } from '../../../app/lib/common/helper.lib';
import { DeviceGroupFuncItem, DeviceGroupFuncInterface } from './group/dlg/group-func.def';
import { DeviceGroupFuncService } from './group/dlg/group-func.service';
import { DeviceGroupFuncDirective } from './group/dlg/group-func.directive';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DeviceService } from './device.service';
import { PolicyService } from '../setting/policy/policy.service';
import { PolicyInfo } from '../setting/policy/policy.data';
import { AppliedFilterEvent, DeviceAdvFilterOptionInfo } from '../../../app/content/device/filter/data/dev-adv-filter.data';
import { Logger } from '../../../app/lib/common/logger';
import { AutoUnsubscribeComponent } from '../virtual/auto-unsubscribe.component';
import { TranslateService } from '../../../app/translate/translate.service';
import { DeviceSavedFilterInfo } from './filter/data/dev-saved-filter.data';
import { OnOffSwitch } from '../../../app/lib/common/common.data';
import { DeviceFilterHelper } from './filter/lib/dev-filter.helper';

enum DeviceFilterType {
  ByGroup = 'ByGroup',
  ByFilter = 'ByFilter'
}

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  _loading: boolean = false;

  _devFilterType: DeviceFilterType = DeviceFilterType.ByGroup;
  _enumDevFilterType: typeof DeviceFilterType = DeviceFilterType;
  _isDeviceLoading: boolean = false;
  // group
  _devGroupRouteList: DeviceGroupInfo[] = [];
  _showGroupTree: boolean = true;
  _groupCtrlAllowHide: boolean = false;
  _devGroupSwitch: OnOffSwitch = OnOffSwitch.on;
  _enumOnOffSwitch: typeof OnOffSwitch = OnOffSwitch;
  _selectedDeviceGroup: DeviceGroupInfo;
  _filterRecord: { [type: string]: string[] };
  _selectedSavedFilter: DeviceSavedFilterInfo;

  _selectedDeviceCount: number = 0;

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (HelperLib.isMobileLayout(event.target.innerWidth)) {
      this._showGroupTree = false;
      this._groupCtrlAllowHide = true;
    }
    else {
      this._showGroupTree = true;
      this._groupCtrlAllowHide = false;
    }
  }

  @ViewChild(DeviceGroupFuncDirective, { static: true }) devGroupFuncHost: DeviceGroupFuncDirective;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private devGroupSvc: DeviceGroupService,
    private devGroupFuncSvc: DeviceGroupFuncService,
    private devSvc: DeviceService,
    private policySvc: PolicyService,
    private translateSvc: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this._devGroupSwitch = this.devGroupSvc.groupSwitch;

    this.devGroupSvc.onRouteChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((routeList: DeviceGroupInfo[]) => {
      this._devGroupRouteList = routeList;
    });

    this.devGroupSvc.onDevGroupSwitchChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((onOffSwitch: OnOffSwitch) => {
      this._devGroupSwitch = onOffSwitch;
    });

    // record filter info
    this.devSvc.onDeviceFilterApplied.pipe(
      concatMap((res: AppliedFilterEvent) => {
        if (res.isApplied && res.sourceFilters) {
          return DeviceFilterHelper.exportFilterDesc(this.translateSvc, res.sourceFilters, { devGroupSvc: this.devGroupSvc, policySvc: this.policySvc });
        }

        return of([]);
      }),
      takeUntil(this._unsubscribe$)
    ).subscribe((res: { name: string, descs: string[] }[]) => {
      this._filterRecord = null;

      if (res.length > 0) {
        this._filterRecord = {};
        res.forEach(filterDesc => {
          this._filterRecord[filterDesc.name] = [...filterDesc.descs.filter(d => d)];
        });

        if (Object.keys(this._filterRecord).length === 0) {
          this._filterRecord = null;
        }
      }
    });

    this._loading = true;
    HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
      this.route.queryParamMap.pipe().pipe(
        takeUntil(this._unsubscribe$)
      ).subscribe((params: ParamMap) => {
        const targetDevGroupID: string = params.get('group');
        const targetDevPolicyID: string = params.get('policy');
        const targetDevPolicyState: string = params.get('policyState');
        const devActivityID: string = params.get('devActivity');
        const devActivityState: string = params.get('devActivityState');
        Logger.logInfo('dashboard', 'OnInit', `query params: group id: ${targetDevGroupID}, policy id: (${targetDevPolicyID}, ${targetDevPolicyState}), dev activity: (${devActivityID}, ${devActivityState})`);

        if (targetDevGroupID) {
          const targetDevGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(targetDevGroupID);
          if (targetDevGroup) {
            this.updateSelectedDeviceGroup(targetDevGroup);
          }
        }
        else {
          this.updateSelectedDeviceGroup(null);
        }

        if (targetDevGroupID || (targetDevPolicyID && targetDevPolicyState) || devActivityID) {
          this.doAdvanceFilterSearch({
            deviceGroup: { groupID: targetDevGroupID },
            devicePolicy: { policyID: targetDevPolicyID, policyState: targetDevPolicyState },
            deviceActivity: { activityID: devActivityID, activityState: devActivityState }
          });
        }

        // remove the route parameter
        this.router.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: this.route });
      });

      this._devGroupRouteList = this.devGroupSvc.getHomeGroupRoute();
      this._loading = false;
    });
  }

  ngAfterViewInit(): void {
    if (window.innerWidth < 992) {
      this._groupCtrlAllowHide = true;
      this._showGroupTree = false;
    }
  }

  selectDeviceFilterType(filterType: DeviceFilterType): void {
    // change between dev group <--> saved filter
    if (this._devFilterType !== filterType) {
      this._devFilterType = filterType;

      this._selectedSavedFilter = null;
      switch (this._devFilterType) {
        case DeviceFilterType.ByFilter:
          {
            this.inspectGroup(this.devGroupSvc.getHomeGroup(), true);
          }
          break;
      }

      this.devSvc.resetDeviceFilter();
    }
  }

  inspectGroup(g: DeviceGroupInfo, expand: boolean = false): void {
    this.devGroupSvc.inspectGroup(null, g, expand);
    this.updateSelectedDeviceGroup(g);
  }

  turnOnOffSidebar(target?: OnOffSwitch): void {
    this._devGroupSwitch = target || (this._devGroupSwitch === OnOffSwitch.on ? OnOffSwitch.off : OnOffSwitch.on);

    switch (this._devFilterType) {
      case DeviceFilterType.ByGroup:
        {
          this.devGroupSvc.turnOnOffGroup(this._devGroupSwitch);
        }
        break;
    }
  }

  changeDeviceGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_MOVE);
  }

  removeDeviceFromGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_EXCLUDE);
  }

  clearFilters(): void {
    this.devSvc.resetDeviceFilter();
    this._filterRecord = null;
  }

  onDeviceGroupInspect(ev: { devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
    Logger.logInfo('dashboard', 'onDeviceGroupInspect', 'ev: ', ev);

    this.updateSelectedDeviceGroup(ev.devGroup);

    if (ev.filter?.onlineStatus) {
      this.devSvc.getDevicesByFilter('onDeviceGroupInspect', { onlineStatus: ev.filter?.onlineStatus, devGroup: { id: ev.devGroup.id, switch: this._devGroupSwitch } }).subscribe();
    }
  }

  onDeviceSavedFilterSelected(ev: { filter: DeviceSavedFilterInfo }): void {
    Logger.logInfo('dashboard', 'onDeviceSavedFilterSelected', 'ev: ', ev);

    this._selectedSavedFilter = ev.filter;
  }

  onDeviceSelectionChange(selectedDeviceAmount: number): void {
    this._selectedDeviceCount = selectedDeviceAmount;
  }

  onDeviceLoadingStatusChange(isLoading: boolean): void {
    this._isDeviceLoading = isLoading;
  }

  playGroupFunc(funcName: string, g?: DeviceGroupInfo): void {
    const item: DeviceGroupFuncItem = this.devGroupFuncSvc.getFunctionByName(funcName);
    if (!item) {
      return;
    }

    const viewContainerRef = this.devGroupFuncHost.viewContainerRef;
    if (!viewContainerRef) {
      return;
    }

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(item.component);
    (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
    (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.devGroupSvc.getActiveGroup();
  }

  onDeviceDetailGroupSearchFilterRequest(ev: { deviceGroup?: { groupID: string }, devicePolicy?: { policyID: string, policyState: string } }): void {
    this.doAdvanceFilterSearch(ev);
  }

  private doAdvanceFilterSearch(searchOptions: {
    deviceGroup?: { groupID: string },
    devicePolicy?: { policyID: string, policyState: string },
    deviceActivity?: { activityID: string, activityState: string }
  }): void {
    of(searchOptions.devicePolicy).pipe(
      concatMap((devicePolicy: { policyID: string, policyState: string }) => devicePolicy?.policyID ? this.policySvc.getPolicyList() : of([])),
      mergeMap((policies: PolicyInfo[]) => {
        let searchByPolicy$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);
        let searchByDevGroup$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);
        let searchByDevActivity$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);

        const policy: PolicyInfo = policies.find(p => p.id === searchOptions.devicePolicy?.policyID);
        if (policy) {
          searchByPolicy$ = this.devSvc.getAdvanceFilterOptionsByPolicy(policy, 'Not Synced');
        }

        if (searchOptions.deviceGroup?.groupID) {
          searchByDevGroup$ = this.devSvc.getAdvanceFilterOptionByDeviceGroupID(searchOptions.deviceGroup?.groupID);
        }

        if (searchOptions.deviceActivity?.activityID) {
          searchByDevActivity$ = of({
            value: `[Ticket:\"${searchOptions.deviceActivity.activityID}${searchOptions.deviceActivity.activityState ? ':' + searchOptions.deviceActivity.activityState : ''}\"]`
          });
        }

        return forkJoin([searchByPolicy$, searchByDevGroup$, searchByDevActivity$])
      }),
      concatMap((res: Partial<DeviceAdvFilterOptionInfo>[]) => this.devSvc.getDevicesByFilter('by pga', { rules: res.filter(cmd => cmd).map(cmd => ({ type: cmd.type, value: cmd.value })) }))
    ).subscribe();
  }

  private updateSelectedDeviceGroup(g: DeviceGroupInfo): void {
    this._selectedDeviceGroup = g?.id === this.devGroupSvc.getHomeGroup().id ? null : g;
  }
}