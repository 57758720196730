import { HttpClient } from '@angular/common/http';
import { APIBaseManager } from '../../../api.base';

export interface IUpdateVirtualDeviceLabelTxData {
    appendOperation: {
        labelName: string | string[];
        virtualDeviceIDList: string[];
    },
    removeOperation: {
        labelName: string | string[];
        virtualDeviceIDList: string[];
    }
}

export class APIUpdateVirtualDeviceLabelManager extends APIBaseManager<void, void, IUpdateVirtualDeviceLabelTxData, string[]> {
    constructor(protected http: HttpClient) {
        super(http);
        this._apiName = 'API_UpdateVirtualDeviceLabel';
        this._apiMethod = 'PATCH';
    }

    protected getRequestURL(pathParameters: void, queryParameters: void): string {
        return super.getRequestURL() + 'virtualDevices/labels';
    }
}