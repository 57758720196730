import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { IUIElement } from "../uiElement.interface";
import { of, Subject } from "rxjs";
import { delay, map, switchMap, takeUntil } from "rxjs/operators";
import { AutoUnsubscribeComponent } from "app/content/virtual/auto-unsubscribe.component";

@Component({
    selector: 'na-brightness',
    templateUrl: './brightness.component.html',
    styleUrls: ['../uiElement.style.css', './brightness.component.css']
})
export class BrightnessComponent extends AutoUnsubscribeComponent implements IUIElement, OnInit {
    _unsupportReason: string;
    _disabled: boolean;

    _title: string = 'Brightness';
    _brightness: number;
    _lockInfo: { isSync: boolean, policyID: string, policyName: string };

    _isMouseDown: boolean = false;
    _slots: { time: string, brightness: number, idcBrightness: number, showIndicator: boolean }[] = [];
    _anchors: { value: number, marginLeft: string }[] = [];
    _indicators: { value: number, left: string }[] = [];

    _fakeLight$: Subject<{ ev: MouseEvent, barIndex: number, reset: boolean }> = new Subject();
    _light$: Subject<{ ev: MouseEvent, barIndex: number, isMouseDown: boolean }> = new Subject();

    @Input('showBorder') _showBorder: boolean = false;
    @Input('data') _data: { data: { time: number, brightness: number }[], cmd: string } = { data: [], cmd: '' };

    @Output() onBrightnessChanged = new EventEmitter<{ data: { time: number, brightness: number }[], cmd: string }>();

    @HostListener('mouseup')
    onMouseUp() {
        this._isMouseDown = false;
        const datas = this._slots.map((slot) => ({ time: parseInt(slot.time.split(':')[0]), brightness: slot.brightness / 10 }));
        this.onBrightnessChanged.emit({ data: datas, cmd: this.generateCmd(datas) });
    }

    ngOnInit(): void {
        this._slots = this._data?.data.length > 0 ? this._data?.data.map(d => ({
            time: `${d.time < 10 ? '0' + d.time : d.time}:00`,
            brightness: d.brightness * 10,
            idcBrightness: 0,
            showIndicator: false
        })) : Array.from({ length: 24 }, (_, i) => ({ time: `${i < 10 ? '0' + i : i}:00`, brightness: 100, idcBrightness: 0, showIndicator: false }));
        this._anchors = Array.from({ length: 11 }, (_, i) => ({ value: i, marginLeft: `${i * 10}%` }));
        this._indicators = Array.from({ length: 11 }, (_, i) => ({ value: i, left: `${i * 10}%` }));

        this._light$.pipe(
            switchMap((msg: { ev: MouseEvent, barIndex: number, isMouseDown: boolean  }) => {
                return of(msg).pipe(
                    map((msg: { ev: MouseEvent, barIndex: number, isMouseDown: boolean  }) => {
                        if (msg.isMouseDown) {
                            const target = msg.ev.target as HTMLElement;
                            const rect = target.getBoundingClientRect();
                            const percentage = (msg.ev.clientX - rect.left) / rect.width * 100;
                            return { barIndex: msg.barIndex, brightness: Math.round(percentage / 10) * 10 };
                        }
                    })
                )
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((msg: { barIndex: number, brightness: number, reset: boolean }) => {
            this._slots[msg.barIndex].brightness = msg.brightness < 10 ? 10 : msg.brightness;
        });

        this._fakeLight$.pipe(
            switchMap((msg: { ev: MouseEvent, barIndex: number, reset: boolean }) => {
                return msg.reset ?
                    of({ barIndex: msg.barIndex, brightness: 0, reset: msg.reset }) :
                    of(msg).pipe(
                        delay(300),
                        map((msg: { ev: MouseEvent, barIndex: number, reset: boolean }) => {
                            const target = msg.ev.target as HTMLElement;
                            const rect = target.getBoundingClientRect();
                            const percentage = (msg.ev.clientX - rect.left) / rect.width * 100;
                            const brightness: number = Math.round(percentage / 10) * 10;

                            return { barIndex: msg.barIndex, brightness: brightness < 10 ? 10 : brightness, reset: msg.reset};
                        }),
                        takeUntil(this._unsubscribe$)
                    );
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((msg: { barIndex: number, brightness: number, reset: boolean }) => { 
            this._slots[msg.barIndex].idcBrightness = msg.brightness;
            this._slots[msg.barIndex].showIndicator = !msg.reset;
        });
    }

    handleMouseDownOnBar(ev: MouseEvent, barIndex: number): void {
        this._isMouseDown = true;
        this._fakeLight$.next({ ev: ev, barIndex: barIndex, reset: true });
        this._light$.next({ ev: ev, barIndex: barIndex, isMouseDown: this._isMouseDown });
    }

    handleMouseMoveOnBar(ev: MouseEvent, barIndex: number): void {
        if (this._isMouseDown) {
            this._light$.next({ ev: ev, barIndex: barIndex, isMouseDown: this._isMouseDown });
        }
        else {
            this._fakeLight$.next({ ev: ev, barIndex: barIndex, reset: false });
        }
    }

    handleMouseEnterOnBar(ev: MouseEvent, barIndex: number): void {
        if (!this._isMouseDown) {
            this._fakeLight$.next({ ev: ev, barIndex: barIndex, reset: false });
        }
    }

    handleMouseLeaveOnBar(ev: MouseEvent, barIndex: number): void {
        if (!this._isMouseDown) {
            this._fakeLight$.next({ ev: ev, barIndex: barIndex, reset: true });
        }
    }

    private generateCmd(datas: { time: number, brightness: number }[]): string {
        return `echo "# Scheduled Brightness" > /etc/cron.d/scheduledBrightness
${datas.map(data => `echo "* ${data.time} * * * root /usr/bin/lvds_brightness.sh ${data.brightness}" >> /etc/cron.d/scheduledBrightness`).join('\n')}
chmod 644 /etc/cron.d/scheduledBrightness`
    }
}