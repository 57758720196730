import { Component, ViewChild} from '@angular/core';
import { of } from 'rxjs';
import { concatMap, map, catchError } from 'rxjs/operators';

import { IPolicyFunc } from '../policy-func.service';
import { PolicyInfo, PolicyType, PolicyDataSecurity, PolicyDataGroupConfig, PolicyDataAppManagement, PolicyDataFirmwareUpdate, PolicyDataCertificate } from '../policy.data';
import { DeviceGroupInfo, DeviceGroupMode, DeviceGroupType } from '../../../device/group/dev-group.data';
import { DeviceGroupService } from '../../../device/group/dev-group.service';
import { PolicyTypeService, PolicyTypeItem, IPolicyTypeFunc } from '../comp/policy-type.service';
import { PolicyTypeDirective } from '../comp/policy-type.directive';
import { PolicyService } from '../policy.service';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { DialogPage } from '../../../../lib/common/common.data';
import { AccountService } from '../../../../entry/account.service';
import { DeviceService } from '../../../device/device.service';
import { DeviceCacheType, DeviceInfo } from '../../../device/data/device-info';
import { PolicyRawInfo } from '../../../../../app/API/v1/device/policy/api.policy.common';
import { VirtualDlgComponent } from '../../../../../app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './policy-dlg-edit.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyDlgEditComponent extends VirtualDlgComponent implements IPolicyFunc {
    dialogCompleteHandler?: (result: { func: string, errorMessage?: string, data?: any }) => void;
    func: string;

    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    _policyTypeList: { type: PolicyType, displayName: string }[] = [];
    _devGroupRoot: DeviceGroupInfo;
    _groupMode: DeviceGroupMode = DeviceGroupMode.pickmulti;
    _enumPolicyType: typeof PolicyType = PolicyType;
    _originalPickMap: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name?: string } } = {};
    _groupPickMap: { [groupID: string]: { checked: boolean, groupType: DeviceGroupType, name?: string } } = {};
    _errorMessage: string;
    _bNewPolicy: boolean;
    _isPolicyDataValid: boolean = true;
    _loading: boolean = false;

    _title: string;
    set title(t: string) {
        this._title = t;
    }

    _policy: PolicyInfo;
    set policy(p: PolicyInfo) {
        this._policy = p;
        if (!this._policy || !this._policy.type) {
            this._bNewPolicy = true;
            this._policy = new PolicyInfo('policy-' + PolicyService.POLICY_INDEX++, '');
        }
        else {
            this._policy.groupList.forEach((g: { id: string, name?: string, type: DeviceGroupType }) => {
                this._originalPickMap[g.id] = { checked: true, groupType: g.type };
                this._groupPickMap[g.id] = { checked: true, groupType: g.type };
            });
        }
    }

    private _policyTypeHost: PolicyTypeDirective;
    @ViewChild(PolicyTypeDirective)
    set policyTypeHost(host: any) {
        if (host) {
            this._policyTypeHost = host;
        }
    }

    readonly UNSUPPORT_FEATURES: {
        androidVersion: string;
        policyList: {
            type: PolicyType;
            features?: string[];
        }[]
    }[] = [{
        androidVersion: '4.4',
        policyList: [{ type: PolicyType.Application }, { type: PolicyType.Security }, { type: PolicyType.FirmwareUpdate },
        {
            type: PolicyType.Configuration,
            features: [
                'Appstart - QR code overlay',
                'key-screenSaver',
                'key-screenoff',
                'key-powersave',
                'key-maintenancePlayback',
                'key-timeServer'
            ]
        }]
    }, {
        androidVersion: '7.1',
        policyList: [{ type: PolicyType.FirmwareUpdate, features: ['Annual freeze period'] }]
    }];

    constructor(
        protected accountSvc: AccountService,
        private devSvc: DeviceService,
        private groupSvc: DeviceGroupService,
        private policySvc: PolicyService,
        private policyTypeSvc: PolicyTypeService) {
        super(accountSvc);
        this._policyTypeList = this.policySvc.getSupportPolicyTypesByLevel(this.accountSvc.isEnterprise()).map(type => ({ type: type, displayName: HelperLib.splitUpperWord(type) }));
    }

    ngOnInit(): void {
        super.ngOnInit();

        this._loading = true;
        HelperLib.checkState(1, () => { return this.groupSvc.isReady }, () => {
            this._devGroupRoot = this.groupSvc.getRootGroup();
            //add group name since api only return groupID
            Object.keys(this._groupPickMap).forEach((groupID: string) => {
                const g: DeviceGroupInfo = this.groupSvc.getGroupByID(groupID);
                if (g) {
                    this._groupPickMap[groupID].name = g.name;
                    this._originalPickMap[groupID].name = g.name;
                }
            });

            setTimeout(() => {
                this._loading = false;
                if (!this._bNewPolicy && this._policy.type) {
                    this.showPolicy(this._policy.type, this._policy);
                }
            }, 0);
        });
    }

    selectPolicyType(type: PolicyType): void {
        this._policy.type = type;
        this.showPolicy(type, this._policy);
    }

    private onPolicyDataValidateCallback(valid: boolean): void {
        this._isPolicyDataValid = valid;
    }

    private onPolicyFormCancelledCallback(): void {
        this._dlgCloseElementRef.nativeElement.click();
    }

    private showPolicy(type: PolicyType, p: PolicyInfo): void {
        const item: PolicyTypeItem = this.policyTypeSvc.getItemByName(type);
        if (item) {
            this.onPolicyDataValidateCallback(true);
            const viewContainerRef = this._policyTypeHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IPolicyTypeFunc>componentRef.instance).type = type;
            if (this._bNewPolicy) {
                switch (p.type) {
                    case PolicyType.Security:
                        {
                            p.data = new PolicyDataSecurity();
                        }
                        break;
                    case PolicyType.Configuration:
                        {
                            p.data = new PolicyDataGroupConfig();
                        }
                        break;
                    case PolicyType.Application:
                        {
                            p.data = new PolicyDataAppManagement();
                        }
                        break;
                    case PolicyType.FirmwareUpdate:
                        {
                            p.data = new PolicyDataFirmwareUpdate();
                        }
                        break;
                    case PolicyType.Certificate:
                        {
                            p.data = new PolicyDataCertificate();
                        }
                }
            }

            (<IPolicyTypeFunc>componentRef.instance).onPolicyDataValidate = this.onPolicyDataValidateCallback.bind(this);
            (<IPolicyTypeFunc>componentRef.instance).onPolicyFormCancelled = this.onPolicyFormCancelledCallback.bind(this);
            (<IPolicyTypeFunc>componentRef.instance).data = p.data;
            (<IPolicyTypeFunc>componentRef.instance).isInEdit = true;
        }
    }

    submit(): void {
        //check if policy name is duplicate
        if (this.policySvc.isPolicyNameExist(this._policy)) {
            this._errorMessage = 'Policy name is duplicate';
            return;
        }

        this._errorMessage = null;
        this._page = DialogPage.submit;

        let assignList: { id: string, type: DeviceGroupType, name: string }[] = Object.keys(this._groupPickMap).filter(groupID => this._groupPickMap[groupID].checked).map(groupID => { return { id: groupID, type: this._groupPickMap[groupID].groupType, name: this._groupPickMap[groupID].name } }) || [];
        let removeList: { id: string, type: DeviceGroupType, name: string }[] = [];
        if (!this._bNewPolicy) {
            Object.keys(this._originalPickMap).forEach((groupID: string) => {
                if (!this._groupPickMap[groupID] || !this._groupPickMap[groupID].checked) {
                    removeList.push({ id: groupID, type: this._groupPickMap[groupID].groupType, name: this._groupPickMap[groupID].name });
                }
            });
        }

        of(this._bNewPolicy).pipe(
            concatMap((bNew: boolean) => bNew ? this.policySvc.createPolicy(this._policy, assignList) : this.policySvc.updatePolicy(this._policy, assignList, removeList)),
            concatMap((res: { data?: PolicyRawInfo, error: string | number, errorMessage?: string }) => {
                if (res.error !== 0) {
                    throw res.errorMessage;
                }

                if (assignList.length + removeList.length > 0) {
                    // refresh groups to get the latest policies apply on group
                    return this.groupSvc.refreshOwnerGroupList(false).pipe(
                        map(() => {
                            //update device cache
                            assignList.concat(removeList).forEach(t => {
                                if (t.type === DeviceGroupType.group) {
                                    const g: DeviceGroupInfo = this.groupSvc.getGroupByID(t.id);
                                    if (g && g.childs) {
                                        g.childs.filter(c => c.type === DeviceGroupType.device && c.data).forEach(c => {
                                            const dev: DeviceInfo = c.data as DeviceInfo;
                                            if (dev && dev.virtualId) {
                                                this.devSvc.resetCache(dev, DeviceCacheType.shadow, DeviceCacheType.warranty);
                                            }
                                        })
                                    }
                                }
                            });

                            return res.errorMessage;
                        })
                    );
                }

                return of(res.errorMessage);
            }),
            catchError((err: any) => of(err))
        ).subscribe((errorMessage: string) => {
            this._errorMessage = errorMessage;
            this._page = DialogPage.result;

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler({ func: this.func, errorMessage: errorMessage });
            }
        });
    }
}