<div>
    <label class="title">
        Brightness level
        <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
            [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
    </label>
    <div [class.showBorder]="_showBorder">
        <div class="pr-4 pt-1">
            <div class="d-flex align-items-center">
                <small class="axis-y">Time </small>
                <div class="w-100 ml-2">
                    <div *ngFor="let slot of _slots; let i = index" class="slot">
                        <div>{{ slot.time }}</div>
                        <div class="bars">
                            <div class="bar bar-bg"></div>
                            <div class="anchors">
                                <div *ngFor="let anchor of _anchors" class="anchor" [style.width]="anchor.marginLeft">
                                </div>
                            </div>
                            <div class="bar bar-fg" [style.width.%]="slot.brightness">
                                <div *ngIf="slot.brightness !== slot.idcBrightness" class="bar-text">
                                    {{ slot.brightness / 10 }}
                                </div>
                            </div>
                            <div [id]="'bar-' + i" class="bar bar-fake" [style.width.%]="slot.idcBrightness">
                                <div *ngIf="slot.showIndicator" class="bar-text">{{ slot.idcBrightness / 10 }}</div>
                            </div>
                            <div class="bar bar-ctrl" (mousedown)="handleMouseDownOnBar($event, i)"
                                (mousemove)="handleMouseMoveOnBar($event, i)"
                                (mouseenter)="handleMouseEnterOnBar($event, i)"
                                (mouseleave)="handleMouseLeaveOnBar($event, i)"></div>
                        </div>
                    </div>
                    <div class="slot axis-x">
                        <div style="visibility: hidden;">24:00</div>
                        <div class="bars">
                            <div class="indicators">
                                <div *ngFor="let indicator of _indicators" class="indicator" [style.left]="indicator.left">{{
                                    indicator.value }}</div>
                            </div>
                        </div>
                    </div>
                    <small class="d-block text-center">Brightness (0-10)</small>
                </div>
            </div>

        </div>
    </div>
</div>