import { SearchExpression, SearchHelper } from "../../../../../app/content/device/filter/lib/search.helper";
import { AppliedFilterInfo, DeviceAdvFilterInfo, DeviceAdvFilterType } from "../../../../../app/content/device/filter/data/dev-adv-filter.data";
import { HelperLib } from "../../../../../app/lib/common/helper.lib";
import { SavedAdvanceFilterRawData } from "../../../../../app/API/v1/Other/filter/api.advFilter.data";
import { ConstantService } from "../../../../../app/lib/common/constant.service";

export type DeviceSavedFilterType = {
    onlineStatus: { [state: string]: boolean };
    searchQuery: SearchExpression;
    advanceFilters: DeviceAdvFilterInfo[]
}

export class DeviceSavedFilterInfo {
    id: string;
    name: string;
    filter: Partial<DeviceSavedFilterType>;
    total: number | undefined;
    online: number | undefined;
    lastUpdateTime: number;
    raw: SavedAdvanceFilterRawData;

    constructor(id: string, name: string, filter?: AppliedFilterInfo, raw?: SavedAdvanceFilterRawData) {
        this.id = id || `saved-filter-${Date.now()}`;
        this.lastUpdateTime = 0;
        this.name = name || '';
        this.filter = {
            onlineStatus: filter?.onlineStatus || HelperLib.getOnlineStatusState().onlineStatus,
            searchQuery: filter?.searchQuery || null,
            advanceFilters: filter?.advanceFilters || []
        };
        this.raw = raw || null;
    }

    addOrReplaceFilter(options: { onlineStatus?: { [state: string]: boolean }, searchQuery?: SearchExpression, advanceFilters?: DeviceAdvFilterInfo[] }): void {
        this.filter.onlineStatus = options?.onlineStatus || this.filter.onlineStatus;
        this.filter.searchQuery = options?.searchQuery || this.filter.searchQuery;
        this.filter.advanceFilters = options?.advanceFilters || this.filter.advanceFilters;
    }

    transformFilter(constantSvc: ConstantService, advancedFilterList: DeviceAdvFilterInfo[] = []): void {
        this.addOrReplaceFilter({
            onlineStatus: JSON.parse(this.raw?.ui?.onlineStatus || '{}'),
            searchQuery: SearchHelper.importSearchQueryFromPlaintext(constantSvc, this.raw?.ui?.searchQuery),
            advanceFilters: DeviceSavedFilterInfo.initSelectedAdvFilterOptionInfo(advancedFilterList.map(d => d.copy()) || [], new Set(this.raw?.advancedFilterList))
        });
    }

    static initSelectedAdvFilterOptionInfo(sources: DeviceAdvFilterInfo[], selectedRuleMap: Set<string>): DeviceAdvFilterInfo[] {
        for (let advFilter of sources) {
            if (selectedRuleMap.size === 0) {
                break;
            }

            let found: boolean = false;
            let resetOtherOption: boolean = false;
            let deleteRule: string;
            for (let option of advFilter.optionList) {
                if (selectedRuleMap.has(option.value)) {
                    if (option.type === DeviceAdvFilterType.SimpleSelect || option.type === DeviceAdvFilterType.Select || option.type === DeviceAdvFilterType.TextWithOptions) {
                        found = true;
                        resetOtherOption = true;
                        deleteRule = option.value;
                    }
                    else if (option.type === DeviceAdvFilterType.MultiSelect) {
                        found = true;
                        deleteRule = option.value;
                    }
                }
                else {
                    // rule which contains custom data
                    if (option.type === DeviceAdvFilterType.TextWithOptions && option.value) {
                        const curlIndex: number = option.value.indexOf('{{');
                        if (curlIndex > 0) {
                            const prefixStr: string = option.value.substring(0, curlIndex);
                            if (prefixStr) {
                                const foundRule = Array.from(selectedRuleMap.keys()).find(rule => rule.indexOf(prefixStr) === 0);
                                if (foundRule) {
                                    option.data = foundRule.substring(prefixStr.length, foundRule.length - 2);
                                    found = true;
                                    resetOtherOption = true;
                                    deleteRule = foundRule;
                                }
                            }
                        }
                    }
                }

                if (found) {
                    if (resetOtherOption) {
                        advFilter.optionList.forEach(op => op.isDefault = false);
                        advFilter.option = option;
                    }

                    selectedRuleMap.delete(deleteRule);
                    option.isDefault = true;

                    if (option.type !== DeviceAdvFilterType.MultiSelect) {
                        break;
                    }
                }

                if (selectedRuleMap.size === 0) {
                    break;
                }
            }

            if (!found && advFilter.subGroupList?.length > 0 && (advFilter.layer > 1 || (advFilter.layer == 1 && advFilter.groupName !== 'Device Policy' && advFilter.groupName !== 'Device Group'))) {
                this.initSelectedAdvFilterOptionInfo(advFilter.subGroupList, selectedRuleMap);
            }
        }

        return sources;
    }
}
