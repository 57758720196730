
import { Injectable, Directive } from '@angular/core';
import { AndroidGroupType } from '../../../app/content/devfunc/firmware/firmware-data';

@Directive()
@Injectable()
export class ConstantService {
    _appstartExtraList: { property: string, displayName: string, type: 'checkbox' | 'number', condition?: (v: any) => any }[];

    readonly DEVKEY_FAKE_HEARTBEAT: string = 'heartbeatTime';
    readonly DEVKEY_FAKE_DISPLAYNAME: string = 'fake.displayName';
    readonly DEVKEY_FAKE_GROUPNAME: string = 'fake.groupName';
    readonly DEVKEY_FAKE_LOCKSCREEN: string = 'fake.schedule.lockScreen';
    readonly DEVKEY_FAKE_LOCKSCREEN_SCREENOFF: string = 'fake.schedule.screenOff';
    readonly DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER: string = 'fake.schedule.screenSaver';
    readonly DEVKEY_FAKE_MAINTENANCE: string = 'fake.schedule.maintenance';
    readonly DEVKEY_FAKE_APPINSTALL: string = 'fake.app.install';
    readonly DEVKEY_FAKE_SCEP_STATUS: string = 'fake.scep.status';
    readonly DEVKEY_FAKE_REMOTECTRL_CONNECTED: string = 'fake.remotectrl.connected';
    readonly DEVKEY_FAKE_DEVICE_PASSWORD: string = 'encrypted_password';
    readonly DEVKEY_FAKE_BRIGHTNESS: string = 'fake.brightness';
    // adb
    readonly DEVKEY_DEBUG_ADB_ENABLED: string = 'debug.adb.enabled';
    readonly DEVKEY_DEBUG_ADB_TCP_ENABLED: string = 'debug.adb.tcp.enabled';
    readonly DEVKEY_DEBUG_ADB_TCP_PORT: string = 'debug.adb.tcp.port';
    // hardware
    readonly DEVKEY_HD_AUDIO_MASTER_SOUND_LEVEL: string = 'hardware.audioOut.0.masterSoundLevel';
    readonly DEVKEY_HD_AUDIO_MASTER_SOUND_INDEX: string = 'hardware.audioOut.0.masterSoundIndex';
    readonly DEVKEY_HD_AUDIO_MASTER_SOUND_MAXINDEX: string = 'hardware.audioOut.0.masterSoundMaxIndex';
    readonly DEVKEY_HD_AUDIO_SAMPLERATE: string = 'hardware.hdmi.audio.samplingrate';
    readonly DEVKEY_HD_VIDEO_FORMAT: string = 'hardware.videoOut.0.format';
    readonly DEVKEY_HD_VIDEO_HDCP_ENABLED: string = 'hardware.videoOut.0.hdcp.enabled';
    readonly DEVKEY_HD_VIDEO_ROTATION: string = 'hardware.videoOut.0.rotation';
    // info
    readonly DEVKEY_INFO_PCBNAME: string = 'info.PCBName';
    readonly DEVKEY_INFO_PCBREVISION: string = 'info.PCBRevision';
    readonly DEVKEY_INFO_FW_VERSION: string = 'info.firmware.version';
    readonly DEVKEY_INFO_FW_FAMILY: string = 'info.firmware.family';
    readonly DEVKEY_INFO_FOREACTIVITY: string = 'info.foregroundActivity';
    readonly DEVKEY_INFO_APKVERSION: string = 'info.iCareVersion';
    readonly DEVKEY_INFO_MODEL: string = 'info.model';
    readonly DEVKEY_INFO_MODELDESC: string = 'info.modelDescription';
    readonly DEVKEY_INFO_PID: string = 'info.playerId';
    readonly DEVKEY_INFO_PNAME: string = 'info.playerName';
    readonly DEVKEY_INFO_WARRANTY_NAME: string = 'info.warranty.name';
    readonly DEVKEY_INFO_WARRANTY_SN: string = 'info.warranty.sn';
    readonly DEVKEY_INFO_WARRANTY_STARTDATE: string = 'info.warranty.overrideStartDate';
    readonly DEVKEY_INFO_WARRANTY_ENDDATE: string = 'info.warranty.overrideEndDate';
    readonly DEVKEY_INFO_WARRANTY_ETHERNETMACS: string = 'info.warranty.ethernetMacList';
    readonly DEVKEY_INFO_WARRANTY_WIFIMACS: string = 'info.warranty.wifiMacList';
    readonly DEVKEY_INFO_SYSTEM_UPTIME: string = 'info.system.upTime';
    readonly DEVKEY_INFO_SYSTEM_ISLOCK: string = 'info.system.locked';
    readonly DEVKEY_INFO_APP: string = 'info.app';
    readonly DEVKEY_INFO_FW_WEBVIEWPROVIDER: string = 'info.firmware.webviewProvider';
    // net
    readonly DEVKEY_NET_LAN_CONNECTED: string = 'net.ethernet.connected';
    readonly DEVKEY_NET_LAN_DHCP_ENABLED: string = 'net.ethernet.dhcp.enabled';
    readonly DEVKEY_NET_LAN_DNS1: string = 'net.ethernet.dns1';
    readonly DEVKEY_NET_LAN_DNS2: string = 'net.ethernet.dns2';
    readonly DEVKEY_NET_LAN_ENABLED: string = 'net.ethernet.enabled';
    readonly DEVKEY_NET_LAN_GATEWAY: string = 'net.ethernet.gateway';
    readonly DEVKEY_NET_LAN_IP: string = 'net.ethernet.ip';
    readonly DEVKEY_NET_LAN_MAC: string = 'net.ethernet.mac';
    readonly DEVKEY_NET_LAN_NETMASK: string = 'net.ethernet.netmask';
    readonly DEVKEY_NET_WIFI_SSID: string = 'net.wifi.SSID';
    readonly DEVKEY_NET_WIFI_AUTH: string = 'net.wifi.authentication';
    readonly DEVKEY_NET_WIFI_CONNECTED: string = 'net.wifi.connected';
    readonly DEVKEY_NET_WIFI_DHCP_ENABLED: string = 'net.wifi.dhcp.enabled';
    readonly DEVKEY_NET_WIFI_DNS1: string = 'net.wifi.dns1';
    readonly DEVKEY_NET_WIFI_DNS2: string = 'net.wifi.dns2';
    readonly DEVKEY_NET_WIFI_ENABLED: string = 'net.wifi.enabled';
    readonly DEVKEY_NET_WIFI_GATEWAY: string = 'net.wifi.gateway';
    readonly DEVKEY_NET_WIFI_PASSWORD: string = 'net.wifi.password';
    readonly DEVKEY_NET_WIFI_IP: string = 'net.wifi.ip';
    readonly DEVKEY_NET_WIFI_MAC: string = 'net.wifi.mac';
    readonly DEVKEY_NET_WIFI_NETMASK: string = 'net.wifi.netmask';
    // schedule
    readonly DEVKEY_SCHEDULE_REBOOT_ENABLED: string = 'schedule.reboot.enabled';
    readonly DEVKEY_SCHEDULE_REBOOT_TIME: string = 'schedule.reboot.time';
    // schedule : new property to support daily-reboot days
    readonly DEVKEY_SCHEDULE_REBOOT: string = 'schedule.reboot';
    // time
    readonly DEVKEY_TIME_LOCAL: string = 'time.localTime';
    readonly DEVKEY_TIME_TIMEZONE: string = 'time.timeZone';
    readonly DEVKEY_TIME_TIMESERVER_ENABLED: string = 'time.autoTime.enabled';
    readonly DEVKEY_TIME_TIMESERVER_SOURCE: string = 'time.autoTime.server';
    // appStart
    readonly DEVKEY_APPSTART: string = 'app.start';
    readonly DEVKEY_APPSTART_PACKAGENAME: string = 'app.start.packageName';
    readonly DEVKEY_APPSTART_CLASSNAME: string = 'app.start.className';
    readonly DEVKEY_APPSTART_ACTION: string = 'app.start.action';
    readonly DEVKEY_APPSTART_CONTENTURL: string = 'content.serverUrl';
    readonly DEVKEY_APPSTART_OVERLAY: string = 'app.start.overlay'; //fake
    readonly DEVKEY_APPSTART_SCREENSAVER: string = 'app.start.screensaver'; //fake
    readonly DEVKEY_APPSTART_EXTRA_TRUST: string = 'adapi:trust';
    readonly DEVKEY_APPSTART_EXTRA_DESKTOPMODE: string = 'adapi:desktopMode';
    readonly DEVKEY_APPSTART_EXTRA_WEBAPPMODE: string = 'adapi:forceWebAppMode';
    readonly DEVKEY_APPSTART_EXTRA_IGNORECERTERROR: string = 'adapi:ignoreCertificateErrors';
    readonly DEVKEY_APPSTART_EXTRA_NETCONNECTRELOAD: string = 'adapi:reloadOnNetworkConnect';
    readonly DEVKEY_APPSTART_EXTRA_NETDISCONNECTFAIL: string = 'adapi:failOnNetworkDisconnect';
    readonly DEVKEY_APPSTART_EXTRA_DISABLEWEBENHANCE: string = 'adapi:disableAltWebView'; //for 4.4 only
    readonly DEVKEY_APPSTART_EXTRA_AUTORUNTIMEPERMISSION: string = 'adapi:autoGrantRuntimePermission';
    readonly DEVKEY_APPSTART_EXTRA_AUTOUSBPERMISSION: string = 'adapi:autoGrantUsbPermission';
    readonly DEVKEY_APPSTART_EXTRA_CLEARWEBSTORAGE: string = 'adapi:clearWebStorageOnSessionStart';
    readonly DEVKEY_APPSTART_EXTRA_CLEARWEBCACHE: string = 'adapi:clearWebCacheOnSessionStart';
    readonly DEVKEY_APPSTART_EXTRA_WEBAPPRESPONSETIMEOUT: string = 'adapi:webAppResponseTimeout';
    readonly DEVKEY_APPSTART_EXTRA_MINREPEATINTERVAL: string = 'adapi:minRepeatInterval';
    readonly DEVKEY_APPSTART_EXTRA_IGNOREWEBAPPERRORS: string = 'adapi:ignoreWebAppErrors';
    readonly DEVKEY_APPSTART_FAILSAFEURL: string = 'content.failSafeUrl';
    // app setting console
    readonly DEVKEY_APPSETTING_CONSOLE_DISABLE_USBSMILUPDATE: string = 'app.settings.console.disableUsbSmilUpdate';
    readonly DEVKEY_APPSETTING_CONSOLE_DISABLE_NETACCESS: string = 'app.settings.console.disableNetworkAccess';
    readonly DEVKEY_APPSETTING_CONSOLE_DISABLE_USBCONFIG: string = 'app.settings.console.disableUsbConfig';
    readonly DEVKEY_APPSETTING_CONSOLE_DISABLE_USBDEBUG: string = 'app.settings.console.disableUsbDebug';
    readonly DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE: string = 'app.settings.console.disablePowerSaveTimer';
    readonly DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT: string = 'app.settings.console.powerSaveTimerTimeoutMinutes';
    readonly DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION: string = 'app.settings.console.powerSaveTimerTimeoutAction';
    // app metadata
    readonly DEVKEY_APPMETADATA: string = 'app.metadata';
    readonly DEVKEY_APPMETADATA_KEY_APPFEEDBACK: string = 'iadeacare:application-feedback';
    // security
    readonly DEVKEY_SECURITY_USER_ADMIN: string = 'security.users.admin';
    // update
    readonly DEVKEY_UPDATE_POLICY: string = 'system.update.policy';
    readonly DEVKEY_SYSTEM_UPDATE_FW_INFO: string = 'system.update.info.firmware';
    readonly DEVKEY_SYSTEM_UPDATE_FW_LASTCHECKTIME: string = 'system.update.info.lastCheckTime';
    readonly DEVKEY_SYSTEM_UPDATE_FW_RECVTIME: string = 'system.update.info.receivedTime';
    readonly DEVKEY_SYSTEM_UPDATE_FW_STATUS: string = 'system.update.info.updateStatus';

    // cert
    readonly DEVKEY_CERTIFICATE_INSERT: string = 'system.cert.insert';
    readonly DEVKEY_CERTIFICATE_ENROLL: string = 'system.cert.enroll';
    readonly DEVKEY_CERTIFICATE_DELETE: string = 'system.cert.delete';
    
    // ticket types
    readonly TICKETTYPE_TASK: string = 'Task.Batch';
    readonly TICKETTYPE_TROUBLESHOOT: string = 'System.Debug.CollectLog';

    // task name
    readonly TASKTYPE_REBOOT: string = 'System.Reboot';
    readonly TASKTYPE_CLEARCACHE: string = 'System.ClearCache';
    readonly TASKTYPE_CLEARAPPDATA: string = 'System.ClearAppData';
    readonly TASKTYPE_ACTIVEMODE: string = 'System.ActiveMode';
    readonly TASKTYPE_SCREENSHOT: string = 'System.TakeScreenshot';
    readonly TASKTYPE_INSTALL_FIRMWARE: string = 'System.Firmware.Install';
    readonly TASKTYPE_CHECK_FIRMWARE: string = 'System.Firmware.Check';
    readonly TASKTYPE_INSTALL_APK: string = 'System.Software.Install';
    readonly TASKTYPE_CONFIG_BASIC: string = 'System.Config.BasicSetting.Set';
    readonly TASKTYPE_CONFIG_NET: string = 'System.Config.NetworkSetting.Set';
    //readonly TASKTYPE_CONFIG_CURRSETTING_REFRESH = 'System.Config.CurrentSettings.Refresh';
    //readonly TASKTYPE_CONFIG_AVAOPTIONS_REFRESH = 'System.Config.AvailableOptions.Refresh';
    //readonly TASKTYPE_APP_SWITCH = 'System.App.Switch';
    readonly TASKTYPE_APP_LICENSE_RELOAD: string = 'System.App.ReloadLicense';
    readonly TASKTYPE_DEBUG_LOG_COLLECT: string = 'System.Debug.CollectLog';
    readonly TASKTYPE_SECURITY_LOCALPWD: string = 'System.Local.Password.Set';
    readonly TASKTYPE_DATA_SYNC: string = 'IAdeaCare.Device.Sync';
    readonly TASKTYPE_REMOTE_CONTROL: string = 'System.RemoteCtrl';
    readonly TASKTYPE_SCRIPT: string = 'System.RunScript';

    //OTHER
    readonly DISPLAY_ORIENTATION_LIST: (string | number)[] = ['0', '90', '180', '270', 'auto'];

    //Screen saver
    readonly SCREEN_SAVER_TIMEOUT_LIST: number[] = [300, 600, 1200, 1800, 2700, 3600, 7200, 10800, 14400, 18000]; //s
    readonly SCREEN_SAVER_MEDIATYPE_LIST: { name: string, type: string }[] = [{ name: 'html', type: 'text/html' }, { name: 'jpg', type: 'image/jpg' }, { name: 'png', type: 'image/png' }]; // { name: 'mhtml', type: 'application/x-mimearchive' }

    //words
    readonly FEATURE_UNSUPPORT: string = 'This feature is not supported on the device. Please confirm if your firmware version is up-to-date.';
    readonly FEATURE_UNSUPPORT_V44: string = 'This feature is not supported on device with Android 4.4';

    readonly SCEP_SERVER_ALIAS_PREFIX: string = 'iadeacare-scep-eap-cert';
    readonly KEYCODE_RETURN: number = 13;

    getAppstartExtraList(): { property: string, displayName: string, type: 'checkbox' | 'number', condition?: (androidVersion: AndroidGroupType) => boolean }[] {
        this._appstartExtraList = this._appstartExtraList || [
            {
                property: this.DEVKEY_APPSTART_EXTRA_TRUST,
                type: 'checkbox',
                displayName: 'Trust'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_DESKTOPMODE,
                type: 'checkbox',
                displayName: 'Desktop mode'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_WEBAPPMODE,
                type: 'checkbox',
                displayName: 'Force web application mode'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_IGNORECERTERROR,
                type: 'checkbox',
                displayName: 'Ignore certificate errors'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_NETDISCONNECTFAIL,
                type: 'checkbox',
                displayName: 'Fail when network is disconnected'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_NETCONNECTRELOAD,
                type: 'checkbox',
                displayName: 'Reload when network is reconnected'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_DISABLEWEBENHANCE,
                type: 'checkbox',
                displayName: 'Disable web performance enhancement',
                condition: (androidVersion: AndroidGroupType) => { return androidVersion === AndroidGroupType.And_44 }
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_AUTORUNTIMEPERMISSION,
                type: 'checkbox',
                displayName: 'Grant requests for runtime permissions'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_AUTOUSBPERMISSION,
                type: 'checkbox',
                displayName: 'Grant requests for USB permissions'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_CLEARWEBSTORAGE,
                type: 'checkbox',
                displayName: 'Clear web storage on session start'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_CLEARWEBCACHE,
                type: 'checkbox',
                displayName: 'Clear web cache on session start'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_IGNOREWEBAPPERRORS,
                type: 'checkbox',
                displayName: 'Ignore web application error'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_WEBAPPRESPONSETIMEOUT,
                type: 'number',
                displayName: 'Web application response timeout (seconds)'
            },
            {
                property: this.DEVKEY_APPSTART_EXTRA_MINREPEATINTERVAL,
                type: 'number',
                displayName: 'Minimum repeat interval (seconds)'
            }
        ];

        return this._appstartExtraList;
    }
}