import { IAlertSetting } from "../../../../API/v1/Alert/api.alert.common";
import { DeviceInfo } from "../../../device/data/device-info";

export enum AlertTabType {
    open = 'open',
    close = 'close',
    config = 'config',
    none = 'none'
}

export enum OfflineAlertEventMonitorType {
    always,
    timeToTime
}

export enum AlertType {
    Unknown = 'Unknown',
    Offline = 'Offline',
    AccountAccess = 'AccountAccess',
    DeviceCmd = 'DeviceCmd',
    DeviceStatus = 'DeviceStatus'
}

export const OFFLINE_ALERT_AVAILABLE_CHECKPOINT_LIST: string[] = ['30 M', '1 H', '2 H', '1 D'];
export const OFFLINE_ALERT_FIRE_THRESHOLD_LIST: string[] = ['10 M', '30 M', '1 H', '2 H', '1 D', '7 D'];
export const ALERT_EVENT_NAME_UNKNOWN: string = 'IAdeaCare.v1.unknown';
export const ALERT_EVENT_NAME_OFFLINE: string = 'IAdeaCare.v1.alert.device.offline';
export const ALERT_EVENT_NAME_ACCOUNTACCESS: string = 'IAdeaCare.v1.alert.account.access';
export const ALERT_EVENT_NAME_DEVICE_COMMAND: string = 'IAdeaCare.v1.alert.device.command';
export const ALERT_EVENT_NAME_DEVICE_STATUS: string = 'IAdeaCare.v1.alert.device.status';

export const AlertEventTypeMap: { [eventName: string]: { type: AlertType, displayName: string } } = {
    'IAdeaCare.v1.unknown': {
        type: AlertType.Unknown,
        displayName: ''
    },
    'IAdeaCare.v1.alert.device.offline': {
        type: AlertType.Offline,
        displayName: 'Offline'
    },
    'IAdeaCare.v1.alert.account.access': {
        type: AlertType.AccountAccess,
        displayName: 'Account access'
    },
    'IAdeaCare.v1.alert.device.task.fail': {
        type: AlertType.DeviceCmd,
        displayName: 'Device command'
    },
    'IAdeaCare.v1.alert.device.status': {
        type: AlertType.DeviceStatus,
        displayName: 'Device status'
    }
}

export const DeviceMonitorStatusMap: { [monitorID: string]: { displayName: string, data?: { [key: string]: { value: any, type: string, min?: number, max?: number, step?: number } }, belongEvent: { type: AlertType, displayName: string } } } = {
    'Device.status.offline': {
        displayName: 'Device offline',
        belongEvent: AlertEventTypeMap[ALERT_EVENT_NAME_OFFLINE]
    },
    'Device.status.time.outOfSync': {
        displayName: 'Device time out of sync',
        belongEvent: AlertEventTypeMap[ALERT_EVENT_NAME_DEVICE_STATUS]
    },
    'Device.status.certificates.expired': {
        displayName: 'Network certificate expiring within {{networkKeystoreExpiryDaysThreshold}} days',
        belongEvent: AlertEventTypeMap[ALERT_EVENT_NAME_DEVICE_STATUS],
        data: { networkKeystoreExpiryDaysThreshold: { value: 30, type: 'input:number', min: 1, max: 60, step: 1 } }
    },
    'Device.status.SCEP.enroll.fail': {
        displayName: 'SCEP enroll failed',
        belongEvent: AlertEventTypeMap[ALERT_EVENT_NAME_DEVICE_STATUS]
    },
    'Device.status.SCEP.enrolling.wait': {
        displayName: 'SCEP enrolling - wait for approval',
        belongEvent: AlertEventTypeMap[ALERT_EVENT_NAME_DEVICE_STATUS]
    }
}

export class AlertInfo {
    id: string;
    shortID: string;
    name: string;
    eventType: { type: AlertType, displayName: string };
    alertEvent: string;
    alertData: BaseAlertConfigData;

    set ID(id: string) {
        this.id = id;
        this.shortID = this.id.replace(/[:{2}\.]/g, '-');
    }

    constructor(o?: IAlertSetting) {
        if (o) {
            this.id = o.settingID;
            this.name = o.settingName;
            this.alertEvent = o.alertEvent;
            this.eventType = AlertEventTypeMap[o.alertEvent.trim()];
        }
        else {
            this.id = '';
            this.name = '';
            this.alertEvent = ALERT_EVENT_NAME_UNKNOWN;
            this.eventType = AlertEventTypeMap[this.alertEvent];
        }

        switch (this.eventType.type) {
            case AlertType.Offline:
                {
                    this.alertData = new OfflineAlertConfigData(o ? o.settingData : null);
                }
                break;
            case AlertType.AccountAccess:
                {
                    this.alertData = new AccountAccessAlertConfigData(o ? o.settingData : null);
                }
                break;
            case AlertType.DeviceCmd:
                {
                    this.alertData = new DeviceCommandAlertConfigData(o ? o.settingData : null);
                }
                break;
            case AlertType.DeviceStatus:
                {
                    this.alertData = new DeviceStatusAlertConfigData(o ? o.settingData : null);
                }
                break;
        }
    }

    copy(): AlertInfo {
        const r = new AlertInfo();
        r.ID = this.id;
        r.name = this.name;
        r.alertEvent = this.alertEvent;
        r.eventType = this.eventType;
        r.alertData = this.alertData.copy();

        return r;
    }

    resetByAlert(otherAlert: AlertInfo): void {
        this.ID = otherAlert.id;
        this.name = otherAlert.name;
        this.alertEvent = otherAlert.alertEvent;
        this.eventType = otherAlert.eventType;
        this.alertData = otherAlert.alertData;
    }
}

export class BaseAlertConfigData {
    alertReceiverList: string[];

    constructor(o?: any) {
        if (o) {
            this.alertReceiverList = o.alertReceiverList ? o.alertReceiverList.filter(r => r).map(r => r) : [];
        }
        else {
            this.alertReceiverList = [];
        }
    }

    copy(): BaseAlertConfigData {
        return new BaseAlertConfigData();
    }

    export(): any {
        return {};
    }

    changeReceiver(receiver: string): void {
        this.alertReceiverList = receiver ? receiver.split(/[\s,\n]+/) : [];
    }
}

export class OfflineAlertConfigData extends BaseAlertConfigData {
    alertStartTime: string;
    alertEndTime: string;
    isAlertAlwaysRun: boolean;
    alertApplyDeviceList: { virtualDeviceID: string }[];
    isAlertAutoApply: boolean;
    alertCheckPoint: string;
    alertThreshold: string;
    alertMonitorType: OfflineAlertEventMonitorType;
    isWeekendExcluded: boolean;

    constructor(o?: any) {
        super(o);

        if (o) {
            this.alertApplyDeviceList = o.alertApplyDeviceList ? o.alertApplyDeviceList.map((d: { virtualDeviceID: string }) => { return { virtualDeviceID: d.virtualDeviceID } }) : [];
            this.alertCheckPoint = o.alertCheckPoint;
            this.alertEndTime = o.alertEndTime;
            this.alertStartTime = o.alertStartTime;
            this.alertThreshold = o.alertThreshold;
            this.isAlertAlwaysRun = o.isAlertAlwaysRun;
            this.isAlertAutoApply = o.isAlertAutoApply;
            this.alertMonitorType = o.isAlertAlwaysRun ? OfflineAlertEventMonitorType.always : OfflineAlertEventMonitorType.timeToTime;
            this.isWeekendExcluded = o.isWeekendExcluded;
        }
        else {
            this.alertApplyDeviceList = [];
            this.alertCheckPoint = OFFLINE_ALERT_AVAILABLE_CHECKPOINT_LIST[0];
            this.alertEndTime = '17:00';
            this.alertStartTime = '8:00';
            this.alertThreshold = OFFLINE_ALERT_FIRE_THRESHOLD_LIST[0];
            this.isAlertAlwaysRun = false;
            this.isAlertAutoApply = false;
            this.alertMonitorType = OfflineAlertEventMonitorType.timeToTime;
            this.isWeekendExcluded = false;
        }
    }

    copy(): OfflineAlertConfigData {
        const c: OfflineAlertConfigData = new OfflineAlertConfigData();
        c.alertReceiverList = this.alertReceiverList.map(r => r);
        c.alertApplyDeviceList = this.alertApplyDeviceList.map(d => { return { virtualDeviceID: d.virtualDeviceID } });
        c.alertCheckPoint = this.alertCheckPoint;
        c.alertEndTime = this.alertEndTime;
        c.alertStartTime = this.alertStartTime;
        c.alertThreshold = this.alertThreshold;
        c.isAlertAlwaysRun = this.isAlertAlwaysRun;
        c.isAlertAutoApply = this.isAlertAutoApply;
        c.alertMonitorType = this.alertMonitorType;
        c.isWeekendExcluded = this.isWeekendExcluded;

        return c;
    }

    export(): any {
        return {
            isAlertAlwaysRun: this.isAlertAlwaysRun,
            isAlertAutoApply: this.isAlertAutoApply,
            alertStartTime: this.alertStartTime,
            alertEndTime: this.alertEndTime,
            alertCheckPoint: this.alertCheckPoint,
            alertThreshold: this.alertThreshold,
            alertReceiverList: this.alertReceiverList,
            alertApplyDeviceList: this.alertApplyDeviceList,
            isWeekendExcluded: this.isWeekendExcluded
        }
    }

    changeAutoApply(checked: boolean): void {
        this.isAlertAutoApply = checked;
    }

    changeThreshold(threshold: string): void {
        this.alertThreshold = threshold;
    }

    changeCheckPoint(checkPoint: string): void {
        this.alertCheckPoint = checkPoint;
    }

    changeMonitorType(monitorType: OfflineAlertEventMonitorType): void {
        this.alertMonitorType = monitorType;
        this.isAlertAlwaysRun = monitorType === OfflineAlertEventMonitorType.always;
    }

    changeMonitorStartTime(time: string): void {
        this.alertStartTime = time;
    }

    changeMonitorEndTime(time: string): void {
        this.alertEndTime = time;
    }

    changeExcludeWeekend(checked: boolean): void {
        this.isWeekendExcluded = checked;
    }

    changeApplyDeviceList(appliedDeviceList: DeviceInfo[]): void {
        this.alertApplyDeviceList = appliedDeviceList.map(d => { return { virtualDeviceID: d.virtualId } });
    }
}

export class AccountAccessAlertConfigData extends BaseAlertConfigData {
    constructor(o?: any) {
        super(o);
    }

    copy(): AccountAccessAlertConfigData {
        const c: AccountAccessAlertConfigData = new AccountAccessAlertConfigData();
        c.alertReceiverList = this.alertReceiverList.map(r => r);

        return c;
    }

    export(): any {
        return {
            alertReceiverList: this.alertReceiverList
        }
    }
}

export class DeviceCommandAlertConfigData extends BaseAlertConfigData {
    alertApplyDeviceList: { virtualDeviceID: string }[];
    isAlertAutoApply: boolean;
    taskActionIDList: string[];

    constructor(o?: any) {
        super(o);
        if (o) {
            this.alertApplyDeviceList = o.alertApplyDeviceList ? o.alertApplyDeviceList.map((d: { virtualDeviceID: string }) => { return { virtualDeviceID: d.virtualDeviceID } }) : [];
            this.isAlertAutoApply = o.isAlertAutoApply;
            this.taskActionIDList = o.taskActionIDList ? o.taskActionIDList.map(actionID => actionID) : [];
        }
        else {
            this.alertApplyDeviceList = [];
            this.isAlertAutoApply = false;
            this.taskActionIDList = [];
        }
    }

    copy(): DeviceCommandAlertConfigData {
        const c: DeviceCommandAlertConfigData = new DeviceCommandAlertConfigData();
        c.alertReceiverList = this.alertReceiverList.map(r => r);
        c.alertApplyDeviceList = this.alertApplyDeviceList.map(d => { return { virtualDeviceID: d.virtualDeviceID } });
        c.isAlertAutoApply = this.isAlertAutoApply;
        c.taskActionIDList = this.taskActionIDList.map(actionID => actionID);

        return c;
    }

    export(): any {
        return {
            taskActionIDList: this.taskActionIDList,
            isAlertAutoApply: this.isAlertAutoApply,
            alertReceiverList: this.alertReceiverList,
            alertApplyDeviceList: this.alertApplyDeviceList,
        }
    }

    changeAutoApply(checked: boolean): void {
        this.isAlertAutoApply = checked;
    }

    changeMonitorOptionList(oList: { id: string, displayName: string, checked: boolean }[]): void {
        this.taskActionIDList = oList.filter(o => o.checked).map(o => o.id);
    }

    changeApplyDeviceList(appliedDeviceList: DeviceInfo[]): void {
        this.alertApplyDeviceList = appliedDeviceList.map(d => { return { virtualDeviceID: d.virtualId } });
    }
}

export class DeviceStatusAlertConfigData extends BaseAlertConfigData {
    alertApplyDeviceList: { virtualDeviceID: string }[];
    isAlertAutoApply: boolean;
    deviceStatusMonitorIDList: string[]; //{ id: string, data?: { [key: string]: any } }[];
    deviceStatusMonitorDatas: { [monitorID: string]: { [key: string]: any } };

    constructor(o?: any) {
        super(o);
        if (o) {
            this.alertApplyDeviceList = o.alertApplyDeviceList ? o.alertApplyDeviceList.map((d: { virtualDeviceID: string }) => { return { virtualDeviceID: d.virtualDeviceID } }) : [];
            this.isAlertAutoApply = o.isAlertAutoApply;
            this.deviceStatusMonitorIDList = o.deviceStatusMonitorIDList ? o.deviceStatusMonitorIDList.map(statusID => statusID) : [];
            this.deviceStatusMonitorIDList.forEach(monitorID => {
                let monitorIDWithUnderline: string = monitorID.split('.').join('_');
                if (o[monitorIDWithUnderline]) {
                    this.deviceStatusMonitorDatas = this.deviceStatusMonitorDatas || {};
                    this.deviceStatusMonitorDatas[monitorID] = o[monitorIDWithUnderline];
                }
            });
        }
        else {
            this.alertApplyDeviceList = [];
            this.deviceStatusMonitorIDList = [];
            this.isAlertAutoApply = false;
            this.deviceStatusMonitorDatas = {};
        }
    }

    copy(): DeviceStatusAlertConfigData {
        const c: DeviceStatusAlertConfigData = new DeviceStatusAlertConfigData();
        c.alertReceiverList = this.alertReceiverList.map(r => r);
        c.alertApplyDeviceList = this.alertApplyDeviceList.map(d => { return { virtualDeviceID: d.virtualDeviceID } });
        c.isAlertAutoApply = this.isAlertAutoApply;
        c.deviceStatusMonitorIDList = this.deviceStatusMonitorIDList.map(statusID => statusID);
        try {
            c.deviceStatusMonitorDatas = this.deviceStatusMonitorDatas ? JSON.parse(JSON.stringify(this.deviceStatusMonitorDatas)) : {};
        }
        catch {

        }

        return c;
    }

    export(): any {
        let data = {
            deviceStatusMonitorIDList: this.deviceStatusMonitorIDList,
            isAlertAutoApply: this.isAlertAutoApply,
            alertReceiverList: this.alertReceiverList,
            alertApplyDeviceList: this.alertApplyDeviceList,
        }

        if (Object.keys(this.deviceStatusMonitorDatas).length > 0) {
            Object.keys(this.deviceStatusMonitorDatas).forEach((monitorID: string) => {
                if (this.deviceStatusMonitorIDList.find(id => id === monitorID)) {
                    let monitorIDWidthUnderline: string = monitorID.split('.').join('_');
                    data[monitorIDWidthUnderline] = this.deviceStatusMonitorDatas[monitorID];
                }
                else {
                    // reset back to monitor default value
                    delete this.deviceStatusMonitorDatas[monitorID];
                }
            });
        }

        return data;
    }

    changeAutoApply(checked: boolean): void {
        this.isAlertAutoApply = checked;
    }

    changeMonitorOptionList(oList: { id: string, displayName: string, checked: boolean }[]): void {
        this.deviceStatusMonitorIDList = oList.filter(o => o.checked).map(o => o.id);
    }

    changeMonitorOptionData(monitorID: string, dataKey: string, data: any): void {
        this.deviceStatusMonitorDatas = this.deviceStatusMonitorDatas || {};
        this.deviceStatusMonitorDatas[monitorID] = this.deviceStatusMonitorDatas[monitorID] || {};
        this.deviceStatusMonitorDatas[monitorID][dataKey] = data;
    }

    changeApplyDeviceList(appliedDeviceList: DeviceInfo[]): void {
        this.alertApplyDeviceList = appliedDeviceList.map(d => { return { virtualDeviceID: d.virtualId } });
    }
}

export class AlertBaseEventArgs {
    currentAlertList: AlertInfo[];
    updateAlertList: AlertInfo[];

    static create(current: AlertInfo[], changed?: AlertInfo[]) {
        return new AlertBaseEventArgs(current, changed);
    }

    constructor(current: AlertInfo[], update?: AlertInfo[]) {
        this.currentAlertList = current;
        this.updateAlertList = update;
    }
}

export class AlertChangedEventArgs extends AlertBaseEventArgs {
    constructor(current: AlertInfo[], changed?: AlertInfo[]) {
        super(current, changed);
    }

    static create(current: AlertInfo[], changed?: AlertInfo[]) {
        return new AlertChangedEventArgs(current, changed);
    }
}

export class AlertAddedEventArgs extends AlertBaseEventArgs {
    constructor(current: AlertInfo[], changed?: AlertInfo[]) {
        super(current, changed);
    }

    static create(current: AlertInfo[], changed?: AlertInfo[]) {
        return new AlertAddedEventArgs(current, changed);
    }
}

export class AlertRemovedEventArgs extends AlertBaseEventArgs {
    constructor(current: AlertInfo[], changed?: AlertInfo[]) {
        super(current, changed);
    }

    static create(current: AlertInfo[], changed?: AlertInfo[]) {
        return new AlertRemovedEventArgs(current, changed);
    }
}