<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div class="form-group row">
                <label for="col-filter-name" class="col-6 col-sm-5 col-md-4 col-form-label col-form-label-sm">
                    Filter name
                    <span class="red-1 ml-1" [class.d-none]="funcName === FUNC_APPLY">*</span>
                </label>
                <div class="col-6 col-sm-7 col-md-8">
                    <input id="col-filter-name" type="text" class="form-control form-control-sm"
                        placeholder="Name of saved filter ..."
                        [attr.required]="funcName === FUNC_EDIT || funcName === FUNC_CREATE" [(ngModel)]="_filterName">
                    <div class="text-right">
                        <small>** Filter name is required to save the filter.</small>
                    </div>   
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div *ngFor="let filter of _allFilters" class="filter-type" [class.changed]="filter?.isChanged()"
                    [class.active]="_currentFilterType === filter.type" (click)="_currentFilterType = filter.type">
                    {{ filter.type | capitalFirstWord }}
                </div>
            </div>
            <div class="mt-2">
                <!-- online status filter -->
                <div [class.d-none]="_currentFilterType !== 'online Status'">
                    <div class="filter-title">
                        Online Status
                    </div>
                    <div class="block block-online" [class.changed]="_isOnlineStatusFilterChanged"
                        (click)="$event.stopPropagation()">
                        <div *ngFor="let status of _onlineStatusFilter | keyvalue" class="filter-item">
                            <div>
                                <input type="checkbox" [id]="'id_onlineStatusFilter_' + status.key" class="cmn-checkbox"
                                    [checked]="status.value" [disabled]="_loading"
                                    (change)="changeOnlineStatusFilter(status.key, $event.target.checked)">
                                <label [for]="'id_onlineStatusFilter_' + status.key">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Filter {{ status.key }}</span>
                                </label>
                            </div>
                            <i class="ball ml-2" [ngClass]="'status-' + status.key"></i>
                            <span class="ml-2">{{ status.key | capitalFirstWord }}</span>
                        </div>
                    </div>
                </div>
                <!-- search filter -->
                <div [class.d-none]="_currentFilterType !== 'search'">
                    <div class="filter-title">
                        Search query
                    </div>
                    <div class="block block-search">
                        <div *ngFor="let sExpr of _searchQuery.expressions; let index = index">
                            <div *ngFor="let sExprSub of sExpr.expressions; let first = first; let indexSub = index"
                                class="d-flex align-items-center mb-1">
                                <div *ngIf="sExpr.operator === 'OR' && !first" class="mr-1">or</div>
                                <div class="dropdown">
                                    <button [id]="'field-' + sExpr.operator + '-' + sExprSub.id" type="button"
                                        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown"
                                        [class.warning]="sExprSub.searchValue && !sExprSub.field" [disabled]="_loading">
                                        <span>
                                            {{ (sExprSub.langKey || '-- Select a field --') | translate |
                                            capitalFirstWord }}
                                        </span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-left option-list"
                                        [attr.aria-labelledby]="'field-' + sExpr.operator + '-' + sExprSub.id">
                                        <li *ngFor="let searchFieldOption of _searchOptions.sources"
                                            class="dropdown-item"
                                            [class.active]="sExprSub.field == searchFieldOption.field"
                                            (click)="selectSearchSourceColumn(sExprSub, searchFieldOption)">
                                            {{ searchFieldOption.langKey | translate | capitalFirstWord }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="dropdown ml-2">
                                    <button [id]="'rule-' + sExpr.operator + '-' + sExprSub.id" type="button"
                                        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown"
                                        [disabled]="_loading">
                                        <span>{{ sExprSub.rule }}</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-left option-list"
                                        [attr.aria-labelledby]="'rule-' + sExpr.operator + '-' + sExprSub.id">
                                        <li *ngFor="let ruleFieldOption of _searchOptions.rules" class="dropdown-item"
                                            [class.active]="sExprSub.rule === ruleFieldOption"
                                            (click)="selectSearchRuleColumn(sExprSub, ruleFieldOption)">
                                            {{ ruleFieldOption | capitalFirstWord }}
                                        </li>
                                    </ul>
                                </div>
                                <input type="text" class="form-control form-control-sm ml-2"
                                    [(ngModel)]="sExprSub.searchValue">
                                <div class="ml-4 d-flex btn-group">
                                    <button type="button" class="btn btn-sm btn-action btn-light"
                                        (click)="addNewSearchExpression('AND', sExpr)">AND</button>
                                    <button type="button" class="btn btn-sm btn-action btn-light"
                                        (click)="addNewSearchExpression('OR', sExpr)">OR</button>
                                    <button type="button" class="btn btn-sm btn-action btn-light btn-action-red"
                                        (click)="removeSearchExpression(sExpr, indexSub)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- advance filter -->
                <div [class.d-none]="_currentFilterType !== 'advanced'">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="filter-title">
                            Advanced query
                        </div>
                        <div class="d-flex">
                            <div class="mx-3">
                                Categories
                            </div>
                            <div *ngFor="let filter of _advFilters" class="cmn-radio">
                                <ng-container *ngIf="!filter.hide">
                                    <input [id]="filter.id" type="radio" name="devAdvFilterOption"
                                        [checked]="_advFilter?.groupName === filter.groupName" [disabled]="_loading"
                                        (click)="selectFilterCategory(filter)">
                                    <label [for]="filter.id" class="radio"></label>
                                    <span class="mx-2">
                                        {{ filter.groupName }}
                                        <span *ngIf="filter.changedOptionsCount > 0" class="red-1 ml-1">
                                            ({{ filter.changedOptionsCount }})
                                        </span>
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="block">
                        <na-dev-adv-filter [source]="_advFilter" [loading]="_loading"></na-dev-adv-filter>
                    </div>
                </div>
            </div>
            <div *ngIf="_loading" class="loading-box mx-auto mt-2"></div>
            <div *ngIf="_errorMessage" class="mx-auto mt-2 text-center warning-block">{{ _errorMessage }}</div>
            <div class='mt-4 mb-2 d-flex justify-content-between'>
                <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading" (click)="restore()">
                    <i class="fas fa-sync-alt mr-1"></i>
                    Restore
                </button>
                <div>
                    <button type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading || !_filterName"
                        (click)="saveAndApply(true)">
                        <i class="fas fa-bookmark mr-1"></i>
                        Save filter & Apply
                    </button>
                    <button *ngIf="funcName === FUNC_APPLY" type="button" class="btn btn-sm btn-action btn-light ml-2"
                        [disabled]="_loading" (click)="saveAndApply(false)">
                        <i class="fas fa-check mr-1"></i>
                        Apply only
                    </button>
                </div>
                <button type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_loading"
                    data-dismiss="modal">
                    <i class="fas fa-times mr-1"></i>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>