import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { fromEvent, of } from "rxjs";
import { concatMap, debounceTime, delay, takeUntil } from "rxjs/operators";

import { AutoUnsubscribeComponent } from "../../../../app/content/virtual/auto-unsubscribe.component";
import { DeviceService } from "../device.service";
import { DeviceSavedFilterInfo } from "./data/dev-saved-filter.data";
import { DeviceInfo, OnlineStatus } from "../data/device-info";
import { AppliedFilterEvent } from "./data/dev-adv-filter.data";
import { DeviceFilterHelper } from "./lib/dev-filter.helper";

@Component({
    selector: 'na-dev-saved-filter-picker',
    templateUrl: './dev-saved-filter-picker.component.html',
    styleUrls: ['./dev-saved-filter-picker.component.css']
})
export class DeviceSavedFilterPickerComponent extends AutoUnsubscribeComponent implements OnInit {
    readonly DEFAULT_SAVED_FILTER: DeviceSavedFilterInfo = new DeviceSavedFilterInfo('default', 'Default');
    _loading: boolean;
    _savedFilters: { filter: DeviceSavedFilterInfo, loading: boolean }[] = [];
    _displayedSavedFilters: { filter: DeviceSavedFilterInfo, loading: boolean }[] = [];

    public _selectedSavedFilter: DeviceSavedFilterInfo = null;
    @Input('filter')
    set filter(v: DeviceSavedFilterInfo) {
        this.selectSavedFilter({ filter: v || this.DEFAULT_SAVED_FILTER, loading: false }, false);
    }

    _searchTxt: string;
    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (!this._searchRef && v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._searchTxt = e.target.value.toLocaleLowerCase();
                this.refactor();
            });
        }
    }

    @Output() onSavedFilterSelected = new EventEmitter<{ filter: DeviceSavedFilterInfo }>();

    constructor(private devSvc: DeviceService) {
        super()
    }

    ngOnInit(): void {
        this.devSvc.onDeviceSavedFilterUpdated.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((ev: { filters: DeviceSavedFilterInfo[], updatedFilter: DeviceSavedFilterInfo }) => {
            this._savedFilters = ev.filters.map(f => ({ filter: f, loading: false }));
            this.refactor();
            this.selectSavedFilter({ filter: ev.updatedFilter, loading: false }, false);
        });

        this.devSvc.onDeviceSavedFilterRemoved.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((ev: { filters: DeviceSavedFilterInfo[], removedFilter: DeviceSavedFilterInfo }) => {
            this._savedFilters = ev.filters.map(f => ({ filter: f, loading: false }));
            this.refactor();
            this.selectDefaultSavedFilter();
        });

        this.devSvc.onDeviceFilterApplied.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: AppliedFilterEvent) => {
            if (res.reset) {
                this.selectDefaultSavedFilter();
            }
        });

        this._loading = true;
        this.devSvc.getDeviceSavedFilters().subscribe((res: { isFault: boolean, filters: DeviceSavedFilterInfo[], errorMessage?: string }) => {
            this._loading = false;
            this._savedFilters = (!res.isFault ? res.filters : this._savedFilters).map(f => ({ filter: f, loading: false }));
            this.refactor();
            this.selectDefaultSavedFilter();
        });
    }

    selectDefaultSavedFilter(): void {
        this.selectSavedFilter({ filter: this.DEFAULT_SAVED_FILTER, loading: false });
    }

    selectSavedFilter(item: { filter: DeviceSavedFilterInfo, loading: boolean }, applySearch: boolean = true): void {
        if (this._selectedSavedFilter !== item.filter) {
            this._selectedSavedFilter = item.filter;

            if (applySearch) {
                of(true).pipe(
                    concatMap(() => {
                        if (Object.values(DeviceFilterHelper.isSavedFilterApplied(this._selectedSavedFilter.filter)).some(Boolean)) {
                            return of(this._selectedSavedFilter);
                        }

                        item.loading = true;
                        return this.devSvc.transformDeviceSavedFilter(this._selectedSavedFilter).pipe(delay(1000));
                    }),
                    concatMap(() => this.devSvc.getDevicesByFilter('saved filter', {
                        onlineStatus: this._selectedSavedFilter?.filter?.onlineStatus,
                        searchQuery: this._selectedSavedFilter?.filter?.searchQuery,
                        advanceFilters: this._selectedSavedFilter?.filter?.advanceFilters
                    }))
                ).subscribe((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => {
                    this._selectedSavedFilter.total = res.devices.length;
                    this._selectedSavedFilter.online = res.devices.filter(d => d.onlineStatus === OnlineStatus.Online).length;

                    item.loading = false;
                    this.onSavedFilterSelected.emit({ filter: this._selectedSavedFilter });
                });
            }
            else {
                this.onSavedFilterSelected.emit({ filter: this._selectedSavedFilter });
            }
        }
    }

    showOnlineOnly(item: { filter: DeviceSavedFilterInfo, loading: boolean }): void {
        if (item.filter.online === undefined) {
            this.selectSavedFilter(item);
            return;
        }

        this._selectedSavedFilter = item.filter;

        this.onSavedFilterSelected.emit({ filter: this._selectedSavedFilter });

        this.devSvc.getDevicesByFilter('saved filter', {
            onlineStatus: { [OnlineStatus.Online]: true, [OnlineStatus.Disconnect]: false, [OnlineStatus.Offline]: false },
            searchQuery: this._selectedSavedFilter?.filter?.searchQuery,
            advanceFilters: this._selectedSavedFilter?.filter?.advanceFilters
        }).subscribe((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => { });
    }

    private refactor(): void {
        this._displayedSavedFilters = this._searchTxt ? this._savedFilters.filter(s => s.filter.name.toLocaleLowerCase().includes(this._searchTxt)) : this._savedFilters.map(s => s);
        this._displayedSavedFilters.sort((a, b) => a.filter.name >= b.filter.name ? 1 : -1);
    }

}