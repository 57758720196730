<div class="mb-4">
    <div>
        <div class="block-titlebar justify-content-between">
            <strong class="txt-bold">Filter settings <ng-container *ngIf="_filter?.name">: {{ _filter?.name }}</ng-container></strong>
            <div>
                <button *ngIf="_filter" type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit"
                    data-toggle="modal" data-target="#devSavedFilterFuncModalHost" (click)="createSavedFilter()">
                    <i class="fas fa-plus mr-1"></i>
                    Create
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2" [disabled]="!_filter"
                    data-toggle="modal" data-target="#devSavedFilterFuncModalHost"
                    (click)="updateSavedFilter()">
                    <i class="fas fa-pen mr-1"></i>
                    Edit
                </button>
                <button type="button" class="btn btn-sm btn-action btn-light btn-nb btn-edit ml-2" [disabled]="!_filter"
                    data-toggle="modal" data-target="#devSavedFilterFuncModalHost" (click)="removeSavedFilter()">
                    <i class="fas fa-trash-alt mr-1"></i>
                    Delete
                </button>
            </div>
        </div>
        <div class="py-1">
            <div *ngIf="_isDefaultFilter; else templateSavedFilterContent" class="text-center p-2">
                <div>Create a new filter</div>
                <button type="button" class="btn btn-sm btn-action btn-light mt-2" data-toggle="modal"
                    data-target="#devSavedFilterFuncModalHost" (click)="createSavedFilter()">
                    <i class="fas fa-plus mr-1"></i>
                    Create
                </button>
            </div>
            <ng-template #templateSavedFilterContent>
                <ng-container *ngIf="_filterDescs.length > 0; else templateEmptyFilterContent">
                    <div *ngFor="let filter of _filterDescs">
                        <div class="form-group row align-items-center">
                            <label [for]="'filter-desc-' + filter.name"
                                class="col-12 col-md-4 col-form-label">
                                {{ filter.name }}
                            </label>
                            <div class="col-12 col-md-8">
                                <div *ngFor="let desc of filter.descs" [innerHtml]="desc">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #templateEmptyFilterContent>
                    <div class="text-center p-2">
                        No filters are applied.
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </div>
    <div *ngIf="_page === _enumPage.result" class="mt-2">
        <div *ngIf="_errorMessage" class="block block-error">
            <i class="fas fa-exclamation-circle"></i>
            <div>{{ _errorMessage }}</div>
        </div>
    </div>
</div>
<div id="devSavedFilterFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template dev-saved-filter-func></ng-template>
</div>