<div class="space-pair-block">
    <div *ngIf="!_loadingSpace && !_isAppRegistered; else templateRegsitered" class="text-center">
        <p>App is not registered.</p>
        <p>Please register it from the 'Registration' page first.</p>
    </div>
    <ng-template #templateRegsitered>
        <div *ngIf="_page == _enumPage.action">
            <div *ngIf="_message" class="error-block error-block-pos-abs">{{ _message }}</div>
            <div class="row">
                <div class="col-12 col-md-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="item-title">
                                <label>Available rooms</label>
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <input #searchRoom class="form-control form-control-sm" type='text'
                                        [disabled]="_loadingSpace" name='search' placeholder="Search by room name ..."
                                        aria-label="Search rooms">
                                </div>
                            </div>
                            <div class="item-content">
                                <div class="list-group">
                                    <div class="list-group-item list-group-header black">
                                        <div class="row align-items-center">
                                            <div class="col title">
                                                <span>Room id</span>
                                            </div>
                                            <div class="col title">
                                                <span>Room name</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-content list-group-hoverless">
                                        <ng-container *ngIf="!_loadingSpace; else loadingSpace">
                                            <div *ngFor="let space of _displaySpaceList" class="list-group-item"
                                                [class.active]="_space?.spaceID == space.spaceID"
                                                (click)="selectSpace(space)">
                                                <div class="row align-items-center">
                                                    <div class="col text-break">
                                                        {{ space.spaceShortID }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ space.spaceName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #loadingSpace>
                                            <div class="loading">
                                                <p>Please wait...</p>
                                                <div class="loading-box"></div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div>
                                <label>Reservable module <ng-container *ngIf="_space">
                                        for <span class="red-1">{{ _space.spaceName }}</span></ng-container>
                                </label>
                                <div class="d-flex">
                                    <div *ngIf="_space" class="dropdown-wrapper btn-group flex-fill">
                                        <button id="dropdownReservableModuleLink" type="button"
                                            class="btn btn-sm btn-action btn-light dropdown-toggle text-wrap"
                                            data-toggle="dropdown">
                                            {{ _reservableModuleMap[_changedReservableModules[_space.spaceID]?.to]?.name
                                            }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-left w-100"
                                            aria-labelledby="dropdownReservableModuleLink">
                                            <div class="input-group input-group-sm px-2 mb-2">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-search"></i>
                                                    </span>
                                                </div>
                                                <input #searchReservableModule class="form-control form-control-sm"
                                                    type='text' name='search' placeholder="Search reservable module ..."
                                                    aria-label="Search reservable modules">
                                            </div>
                                            <ul class="option-list">
                                                <li *ngFor="let rm of _displayedReservableModules"
                                                    [class.selected]="_changedReservableModules[_space.spaceID]?.to === rm.id"
                                                    (click)="changeReservableModule(_space, rm)">
                                                    <div>
                                                        {{ rm.name }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="item-title">
                                <label>Paired devices <ng-container *ngIf="_space">
                                        under <span class="red-1">{{ _space.spaceName }}</span></ng-container>
                                </label>
                            </div>
                            <div class="item-content">
                                <div class="list-group">
                                    <div class="list-group-item list-group-header black">
                                        <div class="row align-items-center">
                                            <div class="col title">
                                                <span>Device name</span>
                                            </div>
                                            <div class="col title">
                                                <span>Device MAC</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-content list-group-hoverless">
                                        <ng-container *ngIf="_space; else templateRoomNull">
                                            <ng-container *ngIf="_spaceDevices.length > 0; else templateNoPairedDevice">
                                                <div *ngFor="let pairedDevice of _spaceDevices" class="list-group-item"
                                                    [class.active]="pairedDevice.selected"
                                                    (click)="selectPairedDevice(pairedDevice)">
                                                    <div class="row align-items-center">
                                                        <div class="col text-break">
                                                            {{ pairedDevice.dev.virtualName
                                                            }}
                                                        </div>
                                                        <div class="col text-break">
                                                            {{
                                                            pairedDevice.dev.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC]
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #templateNoPairedDevice>
                                                <div class="text-center my-2">
                                                    No paired device
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #templateRoomNull>
                                            <div class="text-center my-2">
                                                Select a room first
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-1">
                    <div class="block-exchange">
                        <div class="btn-exchange" [class.inactive]="!hasDeviceUnderRoomSelected()">
                            <i class="fas fa-arrow-circle-right" (click)="releaseDevices()"></i>
                        </div>
                        <div class="btn-exchange" [class.inactive]="!hasAvailableDeviceSelected()">
                            <i class="fas fa-arrow-circle-left" (click)="addDevices()"></i>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="item-title">
                        Available devices
                        <div class="input-group input-group-sm w-50">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                            <input #searchDevices class="form-control form-control-sm" type='text'
                                [disabled]="_loadingDevice" name='search' placeholder="Search by device name ..."
                                aria-label="Search devices">
                        </div>
                    </div>
                    <div class="item-content">
                        <div class="list-group">
                            <div class="list-group-item list-group-header black">
                                <div class="row align-items-center">
                                    <div class="col title">
                                        <span>Device name</span>
                                    </div>
                                    <div class="col title">
                                        <span>MAC</span>
                                    </div>
                                    <div class="col title">
                                        <span>Room</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-content list-group-hoverless">
                                <ng-container *ngIf="!_loadingDevice; else loadingDevice">
                                    <div *ngFor="let d of _displayAvailableDeviceList" class="list-group-item"
                                        [class.active]="d.selected" (click)="selectAvailableDevice(d)">
                                        <div class="row align-items-center">
                                            <div class="d-flex align-items-center col text-break">
                                                <div [ngSwitch]="d.dev.onlineStatus" class="mr-2">
                                                    <ng-template [ngSwitchCase]="_enumOnlineType.Online">
                                                        <div class="status-item">
                                                            <div class="ball status-Online" data-toggle="tooltip"
                                                                [title]="_enumOnlineType[d.dev.onlineStatus]">
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template [ngSwitchCase]="_enumOnlineType.Disconnect">
                                                        <div class="status-item">
                                                            <div class="ball status-Disconnect" data-toggle="tooltip"
                                                                [title]="_enumOnlineType[d.dev.onlineStatus]">
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template [ngSwitchCase]="_enumOnlineType.Offline">
                                                        <div class="status-item">
                                                            <div class="ball status-Offline" data-toggle="tooltip"
                                                                [title]="_enumOnlineType[d.dev.onlineStatus]">
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template [ngSwitchCase]="_enumOnlineType.Syncing">
                                                        <div class="status-item">
                                                            <div class="ball status-Syncing" data-toggle="tooltip"
                                                                [title]="_enumOnlineType[d.dev.onlineStatus]">
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template [ngSwitchCase]="_enumOnlineType.Error">
                                                        <div class="status-item">
                                                            <div class="ball status-Error" data-toggle="tooltip"
                                                                [title]="_enumOnlineType[d.dev.onlineStatus]">
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                {{ d.dev.virtualName }}
                                            </div>
                                            <div class="col text-break">
                                                {{ d.dev.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] }}
                                            </div>
                                            <div class="col text-break">
                                                {{ getPairedSpaceNameByDeviceID(d.dev) }}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #loadingDevice>
                                    <div class="loading">
                                        <p>Please wait...</p>
                                        <div class="loading-box"></div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <na-pager [currentPage]="_currentPage" [numberInPage]="NUM_IN_PAGE"
                                [total]="_availableDeviceCount" (onPageChanged)="onPageChanged($event)">
                            </na-pager>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 text-center">
                    <button type="button" class="btn btn-sm btn-action btn-light"
                        [disabled]="_loadingDevice || _loadingSpace" (click)="refresh(true, true)">
                        <i class="fas fa-sync-alt mr-1"></i>
                        Refresh
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2"
                        [disabled]="_loadingDevice || _loadingSpace || (!hasDeviceUpdate() && !hasReservableModuleChanged())"
                        (click)="reset()">
                        <i class="fas fa-times mr-1"></i>
                        Reset
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2"
                        [disabled]="_loadingDevice || _loadingSpace || (!hasDeviceUpdate() && !hasReservableModuleChanged())"
                        (click)="gotoConfirmPage()">
                        Next
                        <i class="fas fa-arrow-right ml-1"></i>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="_page == _enumPage.confirm">
            <ng-container *ngIf="!_loadingPolicy; else templateLoadingPolicy">
                <div>
                    <strong>Updated device pairings </strong>
                    <div *ngIf="_deviceToBePairedList.length > 0 || _deviceToBeUnPairedList.length > 0" class="ml-2">
                        <div class="note">
                            <div>
                                <div class="cmn-radio">
                                    <input id="assign-by-policy" type="radio" name="content-assign-options"
                                        [checked]="_pairingContentAssignMode === _enumPairingContentAssignMode.Policy"
                                        (click)="selectContentAssignMode(_enumPairingContentAssignMode.Policy)">
                                    <label for="assign-by-policy" class="radio"></label>
                                    <div class="w-100 ml-2 d-flex align-items-center">
                                        <strong>Select ServiceNow policy</strong>
                                        <div class="btn-group w-50 mx-2">
                                            <button id="dropdownPolicyLink" type="button"
                                                class="btn btn-sm btn-action btn-light dropdown-toggle"
                                                data-toggle="dropdown">
                                                {{ _policy?.name || 'New Policy' }}
                                                <span class="caret"></span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-left option-list w-100"
                                                aria-labelledby="dropdownPolicyLink">
                                                <li *ngFor="let policy of _policyList"
                                                    [class.selected]="_policy?.id == policy.id"
                                                    (click)="selectPolicy(policy)">
                                                    {{ policy.name }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="dropdown">
                                            <i class="fas fa-question-circle ml-2 question-mark" data-toggle="dropdown"
                                                aria-expanded="false"></i>
                                            <div class="dropdown-menu question-content">
                                                <ul class="mb-0 mr-3">
                                                    <li>
                                                        <small>
                                                            Select 'New policy' for first time setup. All devices under
                                                            your
                                                            account will be assigned to the new ServiceNow policy
                                                        </small>
                                                    </li>
                                                    <li>
                                                        <small>
                                                            Select the previously created ServiceNow policy, the default
                                                            ServiceNow
                                                            policy name is 'Connect to ServiceNow'
                                                        </small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="cmn-radio">
                                    <input id="assign-by-manual" type="radio" name="content-assign-options"
                                        [checked]="_pairingContentAssignMode === _enumPairingContentAssignMode.Manual"
                                        (click)="selectContentAssignMode(_enumPairingContentAssignMode.Manual)">
                                    <label for="assign-by-manual" class="radio"></label>
                                    <strong class="ml-2">
                                        Advance mode
                                    </strong>
                                </div>
                                <div class="note-content">
                                    To display the room's calendar on the device being paired, assign the device's
                                    content URL
                                    to
                                    IAdea's Booking-for-Workplace site : <span class="red-1">{{ PLAYER_CONTENT_URL
                                        }}</span><br />
                                    There are 2 options : <br />
                                    <ul class="mb-0">
                                        <li>
                                            <strong>Recommanded</strong> : Create a Configuration Policy (from Devices
                                            -> Policy
                                            ->
                                            Add and select Configuration policy
                                            type), update the content URL and group all the paired devices under this
                                            policy.
                                        </li>
                                        <li>
                                            <strong>One time configuration for online devices</strong> : Use 'Batch
                                            configuration'
                                            (from Devices -> All devices -> Batch actions -> Basic
                                            configuration) to apply content URL to the paired devices all at once.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>Device to be paired (total: {{_deviceToBePairedList.length}})</div>
                                <div class="list-group">
                                    <div class="list-group-item list-group-header black">
                                        <div class="row align-items-center">
                                            <div class="col title">
                                                <span>Device name</span>
                                            </div>
                                            <div class="col title">
                                                <span>Device MAC</span>
                                            </div>
                                            <div class="col title">
                                                <span>From the room</span>
                                            </div>
                                            <div class="col title">
                                                <span>To the room</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-content list-group-hoverless">
                                        <ng-container *ngIf="_deviceToBePairedList.length > 0">
                                            <div *ngFor="let item of _deviceToBePairedList" class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="d-flex align-items-center col text-break">
                                                        <div [ngSwitch]="item.dev.onlineStatus" class="mr-2">
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Online">
                                                                <div class="status-item">
                                                                    <div class="ball status-Online"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Disconnect">
                                                                <div class="status-item">
                                                                    <div class="ball status-Disconnect"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Offline">
                                                                <div class="status-item">
                                                                    <div class="ball status-Offline"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Syncing">
                                                                <div class="status-item">
                                                                    <div class="ball status-Syncing"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Error">
                                                                <div class="status-item">
                                                                    <div class="ball status-Error" data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        {{ item.dev.virtualName }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{
                                                        item.dev.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC]
                                                        }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ item.fromSpaceName }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ item.toSpaceName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div>Device to be unpaired (total: {{_deviceToBeUnPairedList.length}})</div>
                                <div class="list-group">
                                    <div class="list-group-item list-group-header black">
                                        <div class="row align-items-center">
                                            <div class="col title">
                                                <span>Device name</span>
                                            </div>
                                            <div class="col title">
                                                <span>Device MAC</span>
                                            </div>
                                            <div class="col title">
                                                <span>From the room</span>
                                            </div>
                                            <div class="col title">
                                                <span>To the room</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-content list-group-hoverless">
                                        <ng-container *ngIf="_deviceToBeUnPairedList.length > 0">
                                            <div *ngFor="let item of _deviceToBeUnPairedList" class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="d-flex align-items-center col text-break">
                                                        <div [ngSwitch]="item.dev.onlineStatus" class="mr-2">
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Online">
                                                                <div class="status-item">
                                                                    <div class="ball status-Online"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Disconnect">
                                                                <div class="status-item">
                                                                    <div class="ball status-Disconnect"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Offline">
                                                                <div class="status-item">
                                                                    <div class="ball status-Offline"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Syncing">
                                                                <div class="status-item">
                                                                    <div class="ball status-Syncing"
                                                                        data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template [ngSwitchCase]="_enumOnlineType.Error">
                                                                <div class="status-item">
                                                                    <div class="ball status-Error" data-toggle="tooltip"
                                                                        [title]="_enumOnlineType[item.dev.onlineStatus]">
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        {{ item.dev.virtualName }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{
                                                        item.dev.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC]
                                                        }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ item.fromSpaceName }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ item.toSpaceName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mt-2">
                    <strong>Updated reservable module for rooms </strong>
                    <div *ngIf="hasReservableModuleChanged()" class="ml-2">
                        <div class="list-group">
                            <div class="list-group-item list-group-header black">
                                <div class="row align-items-center">
                                    <div class="col title">
                                        <span>Space</span>
                                    </div>
                                    <div class="col title">
                                        <span>Old reservable module</span>
                                    </div>
                                    <div class="col title">
                                        <span>New reservable module</span>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-content list-group-hoverless">
                                <div *ngFor="let item of _changedReservableModules | keyvalue" class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="d-flex align-items-center col text-break">
                                            {{ _spaceMap[item.key]?.spaceName }}
                                        </div>
                                        <div class="col text-break">
                                            {{
                                                _reservableModuleMap[item.value.from]?.name
                                            }}
                                        </div>
                                        <div class="col text-break">
                                            {{ _reservableModuleMap[item.value.to]?.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #templateLoadingPolicy>
                <div class="loading">
                    <p>Please wait...</p>
                    <div class="loading-box"></div>
                </div>
            </ng-template>
            <div class="mt-4 text-center">
                <div *ngIf="_message" class="my-2">{{ _message }}</div>
                <div class="d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-sm btn-action btn-ligth ml-2"
                        [disabled]="_updating || _loadingPolicy" (click)="backToPairingSelection()">
                        <i class="fas fa-arrow-left mr-1"></i>
                        Back
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-ligth ml-2"
                        [disabled]="_updating || _loadingPolicy" (click)="confirmApplyingPolicyToDeviceGroups()">
                        <i class="fas fa-check mr-1"></i>
                        Apply
                    </button>
                    <div *ngIf="_updating" class="loading loading-p0 ml-4">
                        <div class="loading-box"></div>
                    </div>
                </div>
                <button #btnPolicyAssignWarning type="button" class="d-none" data-toggle="modal"
                    data-target="#policyAssignWarningModalHost">
                </button>
            </div>
        </div>
    </ng-template>
</div>
<div id="policyAssignWarningModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <div class='modal-dialog config-dlg'>
        <div class='modal-content'>
            <div class='modal-header'>
                <span class="modal-title">Notice</span>
                <button #dlgClose type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Configuration policy <strong class="red-1">{{ _policy?.name }}</strong> will apply to the device
                    groups to which the paired devices
                    belong. This may
                    <ol>
                        <li>
                            Replace the current configuration policy of the device group
                        </li>
                        <li>
                            Affect other non-paired devices within the same device groups.
                        </li>
                    </ol>
                    <div class="mt-1">
                        Please check the following table for all the affected device groups.
                    </div>
                </div>
                <div class="list-group mt-2">
                    <div class="list-group-item list-group-header black">
                        <div class="row align-items-center">
                            <div class="col title">
                                <span>Device group</span>
                            </div>
                            <div class="col title">
                                <span>Current policy</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-group-content list-group-hoverless">
                        <div *ngFor="let pairedItem of _allPairedDeviceGroupMap | keyvalue" class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col text-break">
                                    {{ pairedItem.value.groupName }}
                                </div>
                                <div class="col text-break">
                                    {{ pairedItem.value.configurationPolicy?.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 text-center">
                    <button type="button" class="btn btn-sm btn-action btn-ligth ml-2" data-dismiss="modal">
                        <i class="fas fa-check mr-1"></i>
                        Cancel
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-ligth ml-2" data-dismiss="modal"
                        (click)="applyPolicyToDeviceGroups()">
                        <i class="fas fa-check mr-1"></i>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>