import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CustomPipeModule } from '../../../lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../translate/translate.module';
import { DeviceSavedFilterPickerComponent } from './dev-saved-filter-picker.component';
import { DeviceSavedFilterDetailComponent } from './comp/dev-saved-filter-detail.component';
import { DeviceAdvanceFilterComponent } from './comp/dev-adv-filter.component';
import { DeviceSavedFilterFuncService } from './dlg/dev-saved-filter-func.service';
import { DeviceSavedFilterFuncDirective } from './dlg/dev-saved-filter-func.directive';
import { DeviceSavedFilterEntryComponent } from './comp/dev-saved-filter-entry.component';
import { DeviceSavedFilterEditDlgComponent } from './dlg/dev-saved-filter-edit-dlg.component';
import { DeviceSavedFilterRemoveDlgComponent } from './dlg/dev-saved-filter-remove-dlg.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule
    ],
    providers: [
        DeviceSavedFilterFuncService
    ],
    declarations: [
        DeviceSavedFilterFuncDirective,
        DeviceSavedFilterPickerComponent,
        DeviceSavedFilterDetailComponent,
        DeviceAdvanceFilterComponent,
        DeviceSavedFilterEntryComponent,
        DeviceSavedFilterEditDlgComponent,
        DeviceSavedFilterRemoveDlgComponent
    ],
    exports: [
        DeviceSavedFilterPickerComponent,
        DeviceSavedFilterDetailComponent,
        DeviceAdvanceFilterComponent,
        DeviceSavedFilterEntryComponent,
        DeviceSavedFilterEditDlgComponent,
        DeviceSavedFilterRemoveDlgComponent
    ]
})
export class DeviceSavedFilterModule {}