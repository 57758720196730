import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AccountService } from "app/entry/account.service";
import { takeUntil } from "rxjs/operators";
import { AutoUnsubscribeComponent } from "../auto-unsubscribe.component";

@Component({
    template: ``
})
export class VirtualDlgComponent extends AutoUnsubscribeComponent implements OnInit {
    
    protected _dlgCloseElementRef: ElementRef;
    @ViewChild('dlgClose')
    set dlgClose(holder: ElementRef) {
        if (!this._dlgCloseElementRef && holder) {
            this._dlgCloseElementRef = holder;
        }
    }

    constructor(protected accountSvc: AccountService) { 
        super();
    }

    ngOnInit(): void {
        this.accountSvc.loginChanged.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((isLogin: boolean) => {
            if (!isLogin) {
                this.closeDlg();
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    protected closeDlg(): void {
        setTimeout(() => {
            this._dlgCloseElementRef.nativeElement.click();
        });
    }
}