import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { DialogPage } from "../../../../lib/common/common.data";
import { DeviceSavedFilterInfo } from "../data/dev-saved-filter.data";
import { DeviceFilterHelper } from "../lib/dev-filter.helper";
import { DeviceSavedFilterFuncDirective } from "../dlg/dev-saved-filter-func.directive";
import { DEV_SAVED_FILTER_FUNC_CREATE, DEV_SAVED_FILTER_FUNC_EDIT, DEV_SAVED_FILTER_FUNC_REMOVE, DeviceSavedFilterFuncItem, IDeviceSavedFilterFuncCtrl } from "../dlg/dev-saved-filter-func.def";
import { DeviceSavedFilterFuncService } from "../dlg/dev-saved-filter-func.service";
import { DeviceService } from "../../device.service";
import { AutoUnsubscribeComponent } from "../../../../../app/content/virtual/auto-unsubscribe.component";
import { TranslateService } from "../../../../../app/translate/translate.service";

@Component({
    selector: 'na-dev-saved-filter-detail',
    templateUrl: './dev-saved-filter-detail.component.html',
    styleUrls: ['./dev-saved-filter-detail.component.css']
})
export class DeviceSavedFilterDetailComponent extends AutoUnsubscribeComponent implements OnInit {
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;
    _updating: boolean = false;
    _errorMessage: string;
    _filterDescs: { name: string, descs: string[] }[] = [];
    _isDefaultFilter: boolean = true;

    _filter: DeviceSavedFilterInfo;
    @Input('filter')
    set filter(v: DeviceSavedFilterInfo) {
        if (v) {
            this._filter = v;
            this._isDefaultFilter = this._filter.id === 'default';
            DeviceFilterHelper.exportFilterDesc(this.translateSvc, this._filter.filter).subscribe((res: { name: string, descs: string[] }[]) => {
                this._filterDescs = res;
            });
        }
    }

    @ViewChild(DeviceSavedFilterFuncDirective) private _funcHost: DeviceSavedFilterFuncDirective;

    constructor(private devSavedFilterFunc: DeviceSavedFilterFuncService, private devSvc: DeviceService, private translateSvc: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.devSvc.onDeviceSavedFilterUpdated.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { filters: DeviceSavedFilterInfo[], updatedFilter: DeviceSavedFilterInfo }) => {
            this._filter = res?.updatedFilter || this._filter;
            DeviceFilterHelper.exportFilterDesc(this.translateSvc, this._filter.filter).subscribe((res: { name: string, descs: string[] }[]) => {
                this._filterDescs = res;
            });
        });
    }

    createSavedFilter(): void {
        this.createFuncDlg(DEV_SAVED_FILTER_FUNC_CREATE, new DeviceSavedFilterInfo(null, null));
    }

    updateSavedFilter(): void {
        this.createFuncDlg(DEV_SAVED_FILTER_FUNC_EDIT, this._filter);
    }

    removeSavedFilter(): void {
        this.createFuncDlg(DEV_SAVED_FILTER_FUNC_REMOVE, this._filter);
    }

    private onActionComplete(res: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }): void { }

    private createFuncDlg(name: string, data?: DeviceSavedFilterInfo, other?: any): void {
        const item: DeviceSavedFilterFuncItem = this.devSavedFilterFunc.getItemByName(name);
        if (item) {
            const viewContainerRef = this._funcHost.viewContainerRef;
            viewContainerRef.clear();

            const componentRef = viewContainerRef.createComponent(item.component);

            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).title = item.title;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).funcName = name;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).data = data;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).other = other;
            (<IDeviceSavedFilterFuncCtrl>componentRef.instance).onActionCompleted = this.onActionComplete.bind(this);
        }
    }
}