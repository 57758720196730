import { Component } from '@angular/core';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo, DeviceGroupType } from '../dev-group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';
import { UserService } from '../../../admin/user/user.service';
import { VirtualDlgComponent } from '../../../../../app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './group-dlg-create.component.html',
    styleUrls: ['./group-dlg.style.css', './group-dlg-create.component.css']
})
export class DeviceGroupCreateDlgComponent extends VirtualDlgComponent implements DeviceGroupFuncInterface {
    private _index: number = 1;
    title: string;
    group: DeviceGroupInfo;
    _updating: boolean;

    _gList: { index: number, name: string }[] = [];
    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private devGroupSvc: DeviceGroupService, private userSvc: UserService) {
        super(accountSvc);
        this.addSlot();
    }

    addSlot(): void {
        this._errorMessage = null;
        this._gList.push({
            index: this._index++,
            name: ''
        });
    }

    removeSlot(g: { index: number, name: string }): void {
        this._errorMessage = null;
        this._gList.splice(this._gList.indexOf(g), 1);
    }

    createGroup(): void {
        this._errorMessage = '';
        this._updating = true;
        
        this.devGroupSvc.addNewGroup(null, this.group.id, this._gList.map(g => ({ name: g.name, type: DeviceGroupType.group }))).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;

            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            this.userSvc.updateUserGroupCache();

            setTimeout(() => this._dlgCloseElementRef.nativeElement.click(), 0);   
        });
    }
}