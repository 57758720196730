import { Type } from "@angular/core";
import { DeviceSavedFilterInfo } from "../data/dev-saved-filter.data";

export const DEV_SAVED_FILTER_FUNC_APPLY: string = 'dev-saved-filter-apply';
export const DEV_SAVED_FILTER_FUNC_CREATE: string = 'dev-saved-filter-create';
export const DEV_SAVED_FILTER_FUNC_REMOVE: string = 'dev-saved-filter-remove';
export const DEV_SAVED_FILTER_FUNC_EDIT: string = 'dev-saved-filter-edit';

export class DeviceSavedFilterFuncItem {
  constructor(public component: Type<any>, public name: string, public title: string) {}
}

export interface IDeviceSavedFilterFuncCtrl {
  title: string;
  funcName: string;
  data?: DeviceSavedFilterInfo;
  other?: any;
  onActionCompleted: (ret: { funcName: string, isFault: boolean, data?: any, errorMessage?: string }) => void;
}