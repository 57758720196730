import { Component } from '@angular/core';
import { of } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo } from '../dev-group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';
import { PolicyService } from '../../../setting/policy/policy.service';
import { VirtualDlgComponent } from '../../../../../app/content/virtual/dlg/virtual-dlg.component';


@Component({
    templateUrl: './group-dlg-exclude.component.html'
})
export class DeviceGroupExcludeDlgComponent extends VirtualDlgComponent implements DeviceGroupFuncInterface {
    title: string;
    group: DeviceGroupInfo;
    _updating?: boolean;
    other?: any;

    _deleteGroupList: DeviceGroupInfo[] = [];
    _moveDeviceList: DeviceGroupInfo[] = [];

    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private groupSvc: DeviceGroupService, private policySvc: PolicyService) { 
        super(accountSvc);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.group) {
            for (let c of this.group.childs) {
                if (c.data) {
                    //device
                    if (c.data.isSelect) {
                        this._moveDeviceList.push(c);
                    }
                }
                else {
                    //group
                    if (c.selected) {
                        this._deleteGroupList.push(c);
                    }
                }
            }
        }
    }

    removeOut(): void {
        this._errorMessage = '';
        this._updating = true;

        of(true).pipe(
            concatMap(() => {
                if (this._moveDeviceList.length > 0) {
                    const targetGroup: DeviceGroupInfo = this.groupSvc.getDefaultGroup();
                    return this.groupSvc.moveGroup(null, this._moveDeviceList, targetGroup.id, true).pipe(
                        map((res: { isFault: boolean, errorMessage?: string }) => {
                            if (res.isFault) {
                                throw res.errorMessage;
                            }

                            return { isFault: false };
                        })
                    )
                }

                return of({ isFault: false });
            }),
            concatMap(() => {
                if (this._deleteGroupList.length > 0) {
                    return this.groupSvc.deleteGroup(null, this.group, this._deleteGroupList).pipe(
                        map((res: { isFault: boolean, errorMessage?: string }) => {
                            if (res.isFault) {
                                throw res.errorMessage;
                            }

                            return { isFault: false };
                        })
                    )
                }

                return of({ isFault: false });
            }),
            catchError((err: any) => of({ isFault: true, errorMessage: err }))
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;
            this._errorMessage = res.errorMessage;
            
            if (!res.isFault) {
                this.groupSvc.inspectGroup(null, this.group);
                setTimeout(() => this._dlgCloseElementRef.nativeElement.click(), 0);
            }
        });
    }
}