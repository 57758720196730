import { Component } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, concatMap, map, takeUntil } from "rxjs/operators";

import { VirtualDlgComponent } from "app/content/virtual/dlg/virtual-dlg.component";
import { DEV_SAVED_FILTER_FUNC_APPLY, DEV_SAVED_FILTER_FUNC_CREATE, DEV_SAVED_FILTER_FUNC_EDIT, IDeviceSavedFilterFuncCtrl } from "./dev-saved-filter-func.def";
import { DeviceSavedFilterInfo } from "../data/dev-saved-filter.data";
import { DeviceAdvFilterInfo, DeviceAdvFilterType } from "../data/dev-adv-filter.data";
import { SearchExpression, SearchFilterRule, SearchHelper, SearchRules } from "../lib/search.helper";
import { DeviceInfo, OnlineStatus } from "../../data/device-info";
import { AccountService } from "../../../../../app/entry/account.service";
import { DeviceService } from "../../device.service";
import { ConstantService } from "../../../../../app/lib/common/constant.service";
import { Logger } from "../../../../../app/lib/common/logger";
import { HelperLib } from "../../../../../app/lib/common/helper.lib";
import { DeviceFilterHelper } from "../lib/dev-filter.helper";

type FILTER_TYPE = 'online Status' | 'search' | 'advanced';

@Component({
    templateUrl: './dev-saved-filter-edit-dlg.component.html',
    styleUrls: ['./dev-saved-filter-edit-dlg.component.css']
})
export class DeviceSavedFilterEditDlgComponent extends VirtualDlgComponent implements IDeviceSavedFilterFuncCtrl {
    readonly FUNC_CREATE: string = DEV_SAVED_FILTER_FUNC_CREATE;
    readonly FUNC_APPLY: string = DEV_SAVED_FILTER_FUNC_APPLY;
    readonly FUNC_EDIT: string = DEV_SAVED_FILTER_FUNC_EDIT;

    title: string;
    funcName: string;
    data?: DeviceSavedFilterInfo;
    other?: any;
    onActionCompleted: (ret: { funcName: string; isFault: boolean; data?: any; errorMessage?: string; }) => void;

    _loading: boolean = false;
    _filterName: string;

    _allFilters: { type: FILTER_TYPE, isChanged: () => boolean }[] = [];
    _currentFilterType: FILTER_TYPE = 'online Status';

    _onlineStatusFilter: { [state: string]: boolean } = {};
    _isOnlineStatusFilterChanged: boolean = false;

    _searchQuery: SearchExpression;
    _searchOptions: { sources: { field: string, langKey: string, valueGenerator: (d: DeviceInfo) => string | string[] }[], rules: string[] };

    _advFilterBackup: DeviceAdvFilterInfo[] = [];
    _advFilters: DeviceAdvFilterInfo[] = [];
    _advFilter: DeviceAdvFilterInfo;

    _errorMessage: string;

    constructor(protected accountSvc: AccountService, private devSvc: DeviceService, private constantSvc: ConstantService) {
        super(accountSvc);
    }

    ngOnInit(): void {
        this._allFilters = [
            {
                type: 'online Status', isChanged: () => {
                    for (let status of Object.keys(this._onlineStatusFilter)) {
                        if (!this._onlineStatusFilter[status]) {
                            return true;
                        }
                    }

                    return false;
                }
            },
            {
                type: 'search', isChanged: () => {
                    return SearchHelper.isExpressionHasValue(this._searchQuery);
                }
            },
            {
                type: 'advanced', isChanged: () => {
                    for (let advFilter of this._advFilters) {
                        if (advFilter.export().length > 0) {
                            return true;
                        }
                    }

                    return false;
                }
            }
        ];

        this.restore();
    }

    private resetOnlineStatus(currentOnlineStatus?: { [key: string]: boolean }): void {
        const onlineState = HelperLib.getOnlineStatusState(currentOnlineStatus);
        this._onlineStatusFilter = onlineState.onlineStatus;
        this._isOnlineStatusFilterChanged = onlineState.isChanged;
    }

    private resetSearchQuery(currentSearchQuery?: SearchExpression): void {
        this._searchOptions = {
            sources: SearchHelper.getSupportedSearchCriteria(this.constantSvc),
            rules: SearchRules
        };

        this._searchQuery = SearchHelper.copySearchExpression(currentSearchQuery) || {
            operator: 'AND',
            expressions: [{
                operator: 'AND',
                expressions: [{ id: `expr-${Date.now()}`, field: "", rule: "contains", searchValue: "" }]
            }]
        };
    }

    private resetAdvQuery(currentAdvQuery?: DeviceAdvFilterInfo[]): Observable<{ isFault: boolean, errorMessage?: string }> {
        if (currentAdvQuery?.length > 0) {
            this._advFilters = currentAdvQuery.map(af => af.copy());
            this._advFilter = this._advFilters[0];

            return of({ isFault: false });
        }

        return this.devSvc.getAdvanceFilterList().pipe(
            map((res: { isFault: boolean, data: DeviceAdvFilterInfo[], errorMessage?: string }) => {
                Logger.logInfo('DeviceSavedFilterEditDlgComponent', 'OnInit', 'filter list = ', this._advFilters);
                if (res.isFault) {
                    return { isFault: true, errorMessage: res.errorMessage };
                }

                this._advFilters = res.data.map(af => af.copy());
                this._advFilter = this._advFilters[0];

                return { isFault: false };
            })
        );
    }

    selectSearchSourceColumn(expr: SearchFilterRule, searchField: { field: string, langKey: string, valueGenerator: (d: DeviceInfo) => string }): void {
        expr.field = searchField.field;
        expr.langKey = searchField.langKey;
        expr.valueGenerator = searchField.valueGenerator;
    }

    selectSearchRuleColumn(expr: SearchFilterRule, rule: 'startsWith' | 'endsWith' | 'contains' | 'equal'): void {
        expr.rule = rule;
    }

    addNewSearchExpression(operator: 'AND' | 'OR', expr: SearchExpression): void {
        if (operator === 'AND') {
            this._searchQuery.expressions.push({
                operator: 'AND',
                expressions: [{ id: `expr-${Date.now()}`, field: "", rule: "contains", searchValue: "" }]
            });
        }
        else if (operator === 'OR') {
            expr.operator = 'OR';
            expr.expressions.push({ id: `expr-${Date.now()}`, field: "", rule: "contains", searchValue: "" })
        }
    }

    removeSearchExpression(expr: SearchExpression, subIndex: number): void {
        if (expr.expressions.length == 1 && this._searchQuery.expressions.length == 1) {
            const subExpr = expr.expressions[0] as SearchFilterRule;
            if (subExpr) {
                subExpr.searchValue = '';
                subExpr.field = '';
                subExpr.langKey = '';
            }
            return;
        }

        if (subIndex > 0) {
            expr.expressions.splice(subIndex, 1);
        }
        else {
            this._searchQuery.expressions.splice(this._searchQuery.expressions.indexOf(expr), 1);
        }
    }

    changeOnlineStatusFilter(key: string, checked: boolean): void {
        this._onlineStatusFilter[key] = checked;

        // check if online-status-filter is changed.
        if (!this._onlineStatusFilter[key]) {
            this._isOnlineStatusFilterChanged = true;
        }
        else {
            this._isOnlineStatusFilterChanged = false;
            for (let status of Object.keys(this._onlineStatusFilter)) {
                if (!this._onlineStatusFilter[status]) {
                    this._isOnlineStatusFilterChanged = true;
                    break;
                }
            }
        }
    }

    selectFilterCategory(filter: DeviceAdvFilterInfo): void {
        this._advFilter = filter;
    }

    saveAndApply(toSave: boolean = false): void {
        this._loading = true;
        this.preCheckQueries().pipe(
            concatMap(() => this.applySearch()),
            concatMap((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => {
                if (res.isFault) {
                    throw res.errorMessage;
                }

                this.onActionCompleted({ funcName: this.funcName, isFault: false, data: { isFilterApplied: true } });

                if (toSave) {
                    const savedFilter: DeviceSavedFilterInfo = new DeviceSavedFilterInfo(this.data?.id, this._filterName, {
                        onlineStatus: { ...this._onlineStatusFilter },
                        searchQuery: SearchHelper.copySearchExpression(this._searchQuery),
                        advanceFilters: this._advFilters.map(f => f.copy())
                    });
                    
                    if (!res.isFault && res.devices) {
                        savedFilter.total = res.devices.length;
                        savedFilter.online = res.devices.filter(d => d.onlineStatus === OnlineStatus.Online).length;
                    }

                    return this.devSvc.updateDeviceSavedFilter(savedFilter, this.funcName === this.FUNC_APPLY || this.funcName === this.FUNC_CREATE);
                }

                return of({ isFault: false });
            }),
            catchError((err) => of({ isFault: true, errorMessage: err })),
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }) => {
            this._loading = false;
            this._errorMessage = res.errorMessage;
            if (!res.isFault) {
                this.closeDlg();
            }
        });
    }

    private applySearch(): Observable<{ isFault: boolean, devices?: DeviceInfo[], errorMessage?: string }> {
        return this.devSvc.getDevicesByFilter('adv-filter-dlg', { onlineStatus: this._onlineStatusFilter, searchQuery: this._searchQuery, advanceFilters: this._advFilters });
    }

    private preCheckQueries(): Observable<boolean> {
        return of(true).pipe(
            map(() => {
                this._errorMessage = '';

                const appliedRets = DeviceFilterHelper.isSavedFilterApplied({ onlineStatus: this._onlineStatusFilter, searchQuery: this._searchQuery, advanceFilters: this._advFilters });
                if (appliedRets.searchQuery) {
                    if (!SearchHelper.isExpressionValid(this._searchQuery)) {
                        throw 'Search queries are invalid';
                    }

                    return true;
                }

                if (appliedRets.onlineStatus || appliedRets.advanceFilters) {
                    return true;
                }

                throw 'No queries are applied ';
            })
        );
    }

    restore(): void {
        this._loading = true;
        of(true).pipe(
            concatMap(() => {
                this._filterName = this.data?.name;
                this.resetOnlineStatus(this.data?.filter?.onlineStatus);
                this.resetSearchQuery(this.data?.filter?.searchQuery);

                return this.resetAdvQuery(this.data?.filter?.advanceFilters);
            })
        ).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._loading = false;
            this._errorMessage = res.errorMessage;
        });
    }
}