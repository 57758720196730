import { Component, OnInit } from '@angular/core';

import { DeviceInfo } from '../../../../../device/data/device-info';
import { DeviceService } from '../../../../../device/device.service';
import { AlertType, DeviceMonitorStatusMap, DeviceStatusAlertConfigData } from '../../alert.data';
import { AlertHelper } from '../../alert.helper';
import { IAlertDataCtrl } from '../alert-data-ctrl.interface';

interface DeviceStatusCmdData {
    key?: string;
    type?: string;
    min?: number;
    max?: number;
    step?: number;
    value?: number;
}

@Component({
    templateUrl: './alert-data-device-status.component.html',
    styleUrls: ['../../alert.style.css']
})
export class AlertDeviceStatusDataComponent implements OnInit, IAlertDataCtrl {
    _receivers: string;
    _monitorList: { id: string, displayName: string, checked: boolean, contents?: (string | DeviceStatusCmdData)[] }[] = [];
    _defaultSelectDeviceIDList: string[] = [];
    _updateDeviceList: DeviceInfo[] = [];

    private _data: DeviceStatusAlertConfigData = new DeviceStatusAlertConfigData();
    set alertData(data: DeviceStatusAlertConfigData) {
        this._data = data;
        this._receivers = this._data && this._data.alertReceiverList ? this._data.alertReceiverList.join('\n') : '';
        this._defaultSelectDeviceIDList = this._data.alertApplyDeviceList.map(d => d.virtualDeviceID);
    }

    _isViewMode: boolean = true;
    set isViewMode(isView: boolean) {
        this._isViewMode = isView;
    }

    constructor(private devSvc: DeviceService) { }

    ngOnInit(): void {
        const currMonitorMap: { [monitorID: string]: boolean } = this._data.deviceStatusMonitorIDList.reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
        }, {});

        this._monitorList = Object.keys(DeviceMonitorStatusMap).filter((monitorID: string) => DeviceMonitorStatusMap[monitorID].belongEvent.type === AlertType.DeviceStatus).map((monitorID: string) => {
            return {
                id: monitorID,
                displayName: DeviceMonitorStatusMap[monitorID].displayName,
                checked: currMonitorMap[monitorID] || false,
                contents: this.generateMonitorEventWithDataContent(monitorID, DeviceMonitorStatusMap[monitorID], this._data.deviceStatusMonitorDatas)
            }
        });
    }

    isString(value: any): boolean {
        return typeof value === 'string';
    }

    private generateMonitorEventWithDataContent(
        monitorID: string,
        monitorTemplate: { displayName: string, data?: { [key: string]: any }, belongEvent: { type: AlertType, displayName: string } },
        currentMonitorData?: { [monitorID: string]: { [key: string]: any } }): (string | DeviceStatusCmdData)[] {
        const d = {
            displayName: monitorTemplate.displayName,
            data: monitorTemplate.data ? JSON.parse(JSON.stringify(monitorTemplate.data)) : monitorTemplate.data,
            belongEvent: { type: monitorTemplate.belongEvent.type, displayName: monitorTemplate.belongEvent.displayName }
        };

        const regex = /{{(.*?)}}/g;
        let match: RegExpExecArray;
        let lastIndex = 0;
        const parts: (string | DeviceStatusCmdData)[] = [];

        while ((match = regex.exec(d.displayName)) != null) {
            if (match.index > lastIndex) {
                parts.push(d.displayName.substring(lastIndex, match.index));
            }

            const key: string = match[1].trim();
            if (d.data?.[key]) {
                let templateData = d.data[key];
                if (currentMonitorData?.[monitorID]?.[key]) {
                    templateData.value = currentMonitorData[monitorID][key];
                }
                parts.push(Object.assign({ key: key }, templateData));
            }

            lastIndex = match.index;
            d.displayName = d.displayName.replace(`{{${match[1]}}}`, '');
        }

        if (lastIndex < d.displayName.length) {
            parts.push(d.displayName.substring(lastIndex));
        }

        return parts;
    }

    changeMonitorItem(option: { id: string, displayName: string, checked: boolean, contents?: (string | DeviceStatusCmdData)[] }, checked: boolean): void {
        option.checked = checked;
        this._data.changeMonitorOptionList(this._monitorList);
    }

    changeMonitorItemValue(option: { id: string, displayName: string, checked: boolean, contents?: (string | DeviceStatusCmdData)[] }, part: DeviceStatusCmdData, dataValue: any): void {
        this._data.changeMonitorOptionData(option.id, part.key, part.type === 'input:number' ? +dataValue : dataValue);
    }

    changeReceivers(receiver: string): void {
        this._data.changeReceiver(receiver);
    }

    transformReceiverList(receiverList: string[]): string {
        return AlertHelper.transformReceiverList(receiverList);
    }

    transformApplyDeviceList(deviceIDList: { virtualDeviceID: string }[]): string {
        return AlertHelper.transformApplyDeviceList(this.devSvc, deviceIDList);
    }

    onDeviceStatusAlertDeviceSelectChange(deviceList: DeviceInfo[]): void {
        this._data.changeApplyDeviceList(deviceList);
    }
}