import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Subject, timer } from "rxjs";
import { concatMap, takeUntil } from "rxjs/operators";

import { DeviceInfo } from "app/content/device/data/device-info";
import { DeviceService } from "app/content/device/device.service";

@Directive({
    selector: '[lazyImageLoad]'
})
export class LazyLoadImageDirective implements OnInit, OnDestroy {
    @Input() lazyImageLoad!: string;
    @Input() dev!: DeviceInfo;
    @Input() force: boolean = false;
    @Input() errorText: string = 'Unavailable';

    private static injected = false;
    private spinner!: HTMLElement;
    private errorTextElement!: HTMLElement;
    private _observer!: IntersectionObserver;
    protected _unsubscribe$: Subject<void> = new Subject();

    constructor(private el: ElementRef, private renderer: Renderer2, private devSvc: DeviceService) {
    }

    ngOnInit(): void {
        this.setupContainer();
        if (!LazyLoadImageDirective.injected) {
            this.injectKeyframes();
            LazyLoadImageDirective.injected = true; // Mark as injected
        }

        this._observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.loadImage();
                this._observer.unobserve(entry.target);
                this._observer.disconnect();
            }
        }, {
            root: null,
            threshold: 0.5
        });

        this._observer.observe(this.el.nativeElement);
    }

    ngOnDestroy(): void {
        if (this._observer) {
            this._observer.disconnect();
        }

        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    private setupContainer() {
        // Ensure the parent container is positioned
        const parent = this.el.nativeElement.parentElement;
        if (parent) {
            this.renderer.setStyle(parent, 'position', 'relative');
        }
    }

    private injectKeyframes() {
        const style = this.renderer.createElement('style');
        style.innerHTML = `
          @keyframes img-spin {
            0% { transform: translate(-50%, -50%) rotate(0deg); }
            100% { transform: translate(-50%, -50%) rotate(360deg); }
          }
        `;
        this.renderer.appendChild(document.head, style);
    }

    private loadImage(): void {
        if (this.dev) {
            this.showSpinner();

            timer(100 + Math.random() * 2000).pipe(
                concatMap(() => this.devSvc.updateScreenshot(this.dev, this.force)),
                takeUntil(this._unsubscribe$)
            ).subscribe((res: { url: string, useCache?: boolean, lastUpdateTime: Date }) => {
                const img: HTMLImageElement = this.el.nativeElement;

                this.renderer.setAttribute(img, 'src', res.url);

                img.onload = () => {
                    this.renderer.addClass(this.el.nativeElement, 'active');
                    this.removeErrorText();
                    this.removeSpinner();
                };

                img.onerror = () => {
                    this.showErrorText();
                    this.removeSpinner();
                }
            });
        }
    }

    private showSpinner() {
        this.spinner = this.renderer.createElement('div');
        this.renderer.setStyle(this.spinner, 'position', 'absolute');
        this.renderer.setStyle(this.spinner, 'top', '50%');
        this.renderer.setStyle(this.spinner, 'left', '50%');
        this.renderer.setStyle(this.spinner, 'width', '40px');
        this.renderer.setStyle(this.spinner, 'height', '40px');
        this.renderer.setStyle(this.spinner, 'border', '4px solid #f3f3f3');
        this.renderer.setStyle(this.spinner, 'border-top', '4px solid #3498db');
        this.renderer.setStyle(this.spinner, 'border-radius', '50%');
        this.renderer.setStyle(this.spinner, 'animation', 'img-spin 3s linear infinite');


        // Add the spinner to the image container
        const parent = this.el.nativeElement.parentElement;
        if (parent) {
            this.renderer.appendChild(parent, this.spinner);
        }
    }

    private showErrorText() {
        const parent = this.el.nativeElement.parentElement;
        if (!parent) return;

        this.errorTextElement = this.renderer.createElement('div');
        this.renderer.setStyle(this.errorTextElement, 'position', 'absolute');
        this.renderer.setStyle(this.errorTextElement, 'top', '50%');
        this.renderer.setStyle(this.errorTextElement, 'left', '50%');
        this.renderer.setStyle(this.errorTextElement, 'transform', 'translate(-50%, -50%)');
        this.renderer.setStyle(this.errorTextElement, 'background', 'rgba(0, 0, 0, 0.6)');
        this.renderer.setStyle(this.errorTextElement, 'color', 'white');
        this.renderer.setStyle(this.errorTextElement, 'padding', '8px 12px');
        this.renderer.setStyle(this.errorTextElement, 'border-radius', '5px');
        this.renderer.setStyle(this.errorTextElement, 'font-size', '14px');
        this.renderer.setStyle(this.errorTextElement, 'font-weight', 'bold');
        this.renderer.setStyle(this.errorTextElement, 'letter-spacing', '1px');
        this.renderer.setStyle(this.errorTextElement, 'pointer-events', 'none'); // Prevent blocking clicks
        this.renderer.setStyle(this.errorTextElement, 'text-align', 'center');

        const text = this.renderer.createText(this.errorText);
        this.renderer.appendChild(this.errorTextElement, text);
        this.renderer.appendChild(parent, this.errorTextElement);
    }

    private removeSpinner() {
        if (this.spinner && this.spinner.parentElement) {
            this.renderer.removeChild(this.spinner.parentElement, this.spinner);
        }
    }

    private removeErrorText() {
        if (this.errorTextElement && this.errorTextElement.parentElement) {
            this.renderer.removeChild(this.errorTextElement.parentElement, this.errorTextElement);
        }
    }
}