import { Component } from '@angular/core';

import { DeviceGroupFuncInterface } from './group-func.def';
import { DeviceGroupInfo, DeviceGroupMode, DeviceGroupType, DEVICE_GROUP_ID_HOME } from '../dev-group.data';
import { DeviceGroupService } from '../dev-group.service';
import { AccountService } from '../../../../entry/account.service';
import { VirtualDlgComponent } from '../../../../../app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './group-dlg-move.component.html'
})
export class DeviceGroupMoveDlgComponent extends VirtualDlgComponent implements DeviceGroupFuncInterface {
    title: string;
    group: DeviceGroupInfo;

    _updating: boolean;
    other?: { fromGroup?: DeviceGroupInfo, targetGroup?: DeviceGroupInfo };

    _mode: DeviceGroupMode = DeviceGroupMode.pickone;
    _devGroupRoot: DeviceGroupInfo;
    _moveList: DeviceGroupInfo[] = [];

    _fromGroup: DeviceGroupInfo;
    _targetGroup: DeviceGroupInfo;
    _allowChooseTargetGroup: boolean = true;

    _errorMessage: string;
    _enumGroupType: typeof DeviceGroupType = DeviceGroupType;

    constructor(protected accountSvc: AccountService, private groupSvc: DeviceGroupService) {
        super(accountSvc);
        this._devGroupRoot = this.groupSvc.getRootGroup();
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.other) {
            this._fromGroup = this.other.fromGroup;
            if (this.other.targetGroup) {
                this._allowChooseTargetGroup = false;
                this._targetGroup = this.other.targetGroup;
            }
        }

        this._moveList = this._fromGroup ? [this._fromGroup] : (this.group ? this.group.childs.filter(c => c.selected || (c.data ? c.data.isSelect : false)) : []);
        if (this.group.id === DEVICE_GROUP_ID_HOME && this._moveList.length === 0) {
            this._moveList = this.groupSvc.getAllGroupDeviceList().filter(d => d.data && d.data.isSelect);
        }
    }

    onTargetGroupChange(g: DeviceGroupInfo): void {
        this._targetGroup = g;
    }

    moveGroup(): void {
        // move sub-groups inside A group to A group
        if (this.group.id === this._targetGroup.id) {
            this._errorMessage = 'Do not need to move';
            return;
        }
        // one of the moved sub-groups = target group
        if (this._moveList.filter(g => g.type === DeviceGroupType.group).find(g => g.id === this._targetGroup.id)) {
            this._errorMessage = 'Could not move group to itself';
            return;
        }
        // move one of the moved devices to HOME
        if (this._targetGroup.id === DEVICE_GROUP_ID_HOME && this._moveList.filter(g => g.type === DeviceGroupType.device).length > 0) {
            this._errorMessage = 'Could not move device to "HOME"';
            return;
        }
        // check if move parent group to its child group
        if (this._fromGroup) {
            let parentID: string = this._targetGroup.parentID;
            while (parentID && parentID !== DEVICE_GROUP_ID_HOME) {
                if (parentID === this._fromGroup.id) {
                    this._errorMessage = 'Could not move parent group to its child group';
                    return;
                }

                const parentGroup = this.groupSvc.getGroupByID(parentID);
                parentID = parentGroup?.parentID;
            }
        }

        this._errorMessage = '';
        this._updating = true;

        this.groupSvc.moveGroup(null, this._moveList, this._targetGroup.id, true).subscribe((res: { isFault: boolean, errorMessage?: string }) => {
            this._updating = false;
            if (res.isFault) {
                this._errorMessage = res.errorMessage;
                return;
            }

            setTimeout(() => this._dlgCloseElementRef.nativeElement.click(), 0);
        });
    }
}