import { CertInfo, CertInstallStatus } from "app/content/admin/cert/cert.data";
import { AppManagementInfo } from "../../../../app/uiElement/appMgr/appmgr.data";
import { DeviceGroupType } from "../../device/group/dev-group.data";
import { PolicyInfoUsage, PolicyRawInfo } from "../../../../app/API/v1/device/policy/api.policy.common";

export enum PolicyType {
    Security = 'Security',
    Configuration = 'Configuration',
    Application = 'Application',
    FirmwareUpdate = 'FirmwareUpdate',
    Certificate = 'Certificate'
}

export class PolicyInfo {
    id: string;
    name: string;
    type: PolicyType;
    isDefault: boolean;
    isManaged: boolean;
    isUsed: boolean;
    groupList: { id: string, type: DeviceGroupType, name: string }[] = [];
    usage: { [devGroupID: string]: PolicyInfoUsage };
    source: any;
    sourceType?: string;
    data: any;
    raw?: PolicyRawInfo;

    constructor(id: string, name: string, raw?: PolicyRawInfo) {
        this.id = id;
        this.name = name;
        this.groupList = [];
        this.isDefault = raw?.isDefault;
        this.isManaged = raw?.isManaged;
        this.isUsed = raw?.isUsed;
        this.data = null;
        this.usage = raw?.usage?.reduce((prev, curr) => {
            prev[curr.group.groupID] = curr;
            return prev;
        }, {});
        this.source = raw?.source;
        this.raw = raw;
    }

    copy(): PolicyInfo {
        const p: PolicyInfo = new PolicyInfo(this.id, this.name, this.raw);
        p.assignFrom(this);
        p.data = this.data ? this.data.copy() : this.data;
        p.usage = this.usage;

        return p;
    }

    assignFrom(p: PolicyInfo): void {
        this.name = p.name;
        this.type = p.type;
        this.isUsed = p.isUsed;
        this.groupList = p.groupList.map(g => g);
        this.data = p.data;
        this.usage = p.usage;
    }
}

export class PolicyInfoSecurity extends PolicyInfo {
    data: PolicyDataSecurity;

    constructor(id: string, name: string, raw?: PolicyRawInfo) {
        super(id, name, raw);
        this.type = PolicyType.Security;
        this.data = new PolicyDataSecurity();
    }

    copy(): PolicyInfoSecurity {
        const p = new PolicyInfoSecurity(this.id, this.name, this.raw);
        p.assignFrom(this);
        p.data = this.data.copy();

        return p;
    }
}

export class PolicyInfoGroupConfig extends PolicyInfo {
    data: PolicyDataGroupConfig;

    constructor(id: string, name: string, raw?: PolicyRawInfo) {
        super(id, name, raw);
        this.type = PolicyType.Configuration;
        this.data = new PolicyDataGroupConfig();
    }

    copy(): PolicyInfoGroupConfig {
        const p = new PolicyInfoGroupConfig(this.id, this.name, this.raw);
        p.assignFrom(this);
        p.data = this.data.copy();

        return p;
    }
}

export class PolicyInfoAppManagement extends PolicyInfo {
    data: PolicyDataAppManagement;

    constructor(id: string, name: string, raw?: PolicyRawInfo) {
        super(id, name, raw);
        this.type = PolicyType.Application;
        this.data = new PolicyDataAppManagement();
    }

    copy(): PolicyInfoAppManagement {
        const p = new PolicyInfoAppManagement(this.id, this.name, this.raw);
        p.assignFrom(this);
        p.data = this.data.copy();

        return p;
    }
}

export class PolicyInfoFirmwareUpdate extends PolicyInfo {
    data: PolicyDataFirmwareUpdate;

    constructor(id: string, name: string, raw?: PolicyRawInfo) {
        super(id, name, raw);
        this.type = PolicyType.FirmwareUpdate;
        this.data = new PolicyDataFirmwareUpdate();
    }

    copy(): PolicyInfoFirmwareUpdate {
        const p = new PolicyInfoFirmwareUpdate(this.id, this.name, this.raw);
        p.assignFrom(this);
        p.data = this.data.copy();

        return p;
    }
}

export class PolicyInfoCertificate extends PolicyInfo {
    data: PolicyDataCertificate;

    constructor(id: string, name: string, raw?: PolicyRawInfo) {
        super(id, name, raw);
        this.type = PolicyType.Certificate;
        this.data = new PolicyDataCertificate();
    }

    copy(): PolicyInfoCertificate {
        const p = new PolicyInfoCertificate(this.id, this.name, this.raw);
        p.assignFrom(this);
        p.data = this.data.copy();

        return p;
    }
}

export type PolicyFirmwareServerSource = 'current' | 'ota';
export type PolicyFirmwareType = 'DEFAULT_INSTALL_POLICY' | 'AUTOMATIC_INSTALL_POLICY' | 'WINDOWED_INSTALL_POLICY';
export class PolicyDataFirmwareUpdate {
    serverSource: PolicyFirmwareServerSource;
    enabled: boolean;
    otaServer?: string;
    checkIntervalHours: number;
    installWindow: {
        beginTime: string;
        endTime: string;
    };
    annualFreezePeriod: {
        beginDate: string;
        endDate: string;
    };

    constructor() {
        this.serverSource = 'current';
        this.otaServer = '';
        this.enabled = false;
        this.checkIntervalHours = 12;
        this.installWindow = {
            beginTime: '00:00',
            endTime: '00:00'
        };
        this.annualFreezePeriod = {
            beginDate: null,
            endDate: null
        };
    }

    transformPolicyFromRaw(raw: {
        policyType: PolicyFirmwareType,
        checkIntervalHours: number,
        otaServer: string,
        installWindow?: string,
        freezePeriods?: string[]
    }): void {
        this.enabled = true;
        if (raw.policyType === 'DEFAULT_INSTALL_POLICY') {
            this.enabled = false;
        }

        this.otaServer = raw.otaServer;
        if (this.otaServer) {
            this.serverSource = 'ota';
        }
        this.checkIntervalHours = raw.checkIntervalHours;
        if (raw.installWindow) {
            const installWindowPeriods: string[] = raw.installWindow.split('/');
            this.installWindow.beginTime = installWindowPeriods[0];
            this.installWindow.endTime = installWindowPeriods[1];
        }
        if (raw.freezePeriods && raw.freezePeriods[0]) {
            const freezePeriods: string[] = raw.freezePeriods[0].split('/');
            const year: number = new Date().getFullYear();
            this.annualFreezePeriod.beginDate = year + '-' + freezePeriods[0].substring(2);
            this.annualFreezePeriod.endDate = year + '-' + freezePeriods[1].substring(2);
        }
    }

    getTransformData(): {
        policyType: PolicyFirmwareType,
        checkIntervalHours: number,
        otaServer: string,
        installWindow?: string,
        freezePeriods?: string[]
    } {
        let policyType: PolicyFirmwareType = 'DEFAULT_INSTALL_POLICY';
        if (!this.enabled) {
            policyType = 'DEFAULT_INSTALL_POLICY';
        }
        else {
            policyType = this.installWindow.beginTime || this.installWindow.endTime ? 'WINDOWED_INSTALL_POLICY' : 'AUTOMATIC_INSTALL_POLICY';
        }

        const ret: {
            policyType: PolicyFirmwareType,
            checkIntervalHours: number,
            otaServer: string,
            installWindow?: string,
            freezePeriods?: string[]
        } = {
            policyType: policyType,
            checkIntervalHours: this.checkIntervalHours,
            otaServer: this.serverSource === 'ota' ? this.otaServer : '',
        };

        if (this.enabled) {
            ret.installWindow = this.installWindow.beginTime + '/' + this.installWindow.endTime;
            if (this.annualFreezePeriod.beginDate && this.annualFreezePeriod.endDate) {
                ret.freezePeriods = [
                    '--' + this.annualFreezePeriod.beginDate.split('-')[1] + '-' + this.annualFreezePeriod.beginDate.split('-')[2] + '/' +
                    '--' + this.annualFreezePeriod.endDate.split('-')[1] + '-' + this.annualFreezePeriod.endDate.split('-')[2]
                ];
            }
        }

        return ret;
    }

    copy(): PolicyDataFirmwareUpdate {
        const d: PolicyDataFirmwareUpdate = new PolicyDataFirmwareUpdate();
        d.serverSource = this.serverSource;
        d.enabled = this.enabled;
        d.otaServer = this.otaServer;
        d.checkIntervalHours = this.checkIntervalHours;
        d.installWindow = Object.assign({}, this.installWindow);
        d.annualFreezePeriod = Object.assign({}, this.annualFreezePeriod);

        return d;
    }
}

export class PolicyDataAppManagement {
    appInfoList: AppManagementInfo[];

    constructor() {
        this.appInfoList = [];
    }

    copy(): PolicyDataAppManagement {
        const d: PolicyDataAppManagement = new PolicyDataAppManagement();
        d.appInfoList = this.appInfoList.map(a => a.copy());

        return d;
    }
}

export class PolicyDataSecurity {
    enableDevicePassword: boolean;
    devicePassword: { enabled: boolean; new?: string, confirm?: string, isValid?: boolean };
    enableOTP: boolean;
    disableUSBUpdate: boolean;
    disableRESTApi: boolean;

    get isValid(): boolean {
        if (!this.enableDevicePassword) {
            return true;
        }
        else {
            if (!this.devicePassword.enabled && !this.enableOTP) {
                return false;
            }
            return !this.devicePassword.enabled || this.isDevicePasswordMatch ? true : false;
        }
    }

    constructor() {
        this.enableDevicePassword = false;
        this.devicePassword = { enabled: false, new: '', confirm: '', isValid: true };
        this.enableOTP = false;
        this.disableUSBUpdate = false;
        this.disableRESTApi = false;
    }

    get isDevicePasswordMatch(): boolean {
        return this.devicePassword.new === this.devicePassword.confirm;
    }

    copy(): PolicyDataSecurity {
        const d: PolicyDataSecurity = new PolicyDataSecurity();
        d.enableDevicePassword = this.enableDevicePassword;
        d.devicePassword = Object.assign({}, this.devicePassword);
        d.enableOTP = this.enableOTP;
        d.disableUSBUpdate = this.disableUSBUpdate;
        d.disableRESTApi = this.disableRESTApi;
        return d;
    }
}

export class PolicyDataCertificate {
    certificateMap: { [certID: string]: { cert: CertInfo, installStatus: CertInstallStatus } } = {};

    setTransformData(transformData: {
        certificateList: { certificateID: string, isRevoked: boolean }[],
        metadata?: {
            rawData: any;
        }
    }): void {
        if (transformData?.metadata?.rawData) {
            this.certificateMap = {};
            try {
                const rawCerts: { id: string, name: string, expiryData: string, fingerprint: string }[] = JSON.parse(transformData.metadata.rawData);
                rawCerts.forEach(rawCert => {
                    this.certificateMap[rawCert.id] = {
                        cert: new CertInfo({ id: rawCert.id, name: rawCert.name, expiryDate: rawCert.expiryData, fingerprint: rawCert.fingerprint }),
                        installStatus: CertInstallStatus.Unmanageable
                    }
                });
            }
            catch (ex) {

            }
        }

        // if cert is in metadata but not in certificateList, it should be marked as unmanageable
        if (transformData?.certificateList && Array.isArray(transformData.certificateList)) {
            transformData.certificateList.forEach((cert => {
                if (cert.isRevoked !== undefined) {
                    if (this.certificateMap[cert.certificateID]) {
                        this.certificateMap[cert.certificateID].installStatus = cert.isRevoked ? CertInstallStatus.Revoke : CertInstallStatus.Install;
                    }
                    else {
                        // some error occurs since 'certificateMap' should contains all the certificates in 'certificateList'
                    }
                }
            }));
        }
    }

    getTransformData(): { certificateList: { certificateID: string, isRevoked: boolean }[], metadata?: { rawData: string } } {
        // When update, remove all unmanageable certificates on metadata. User only need to watch this info once.
        const installedCertIDs: string[] = Object.keys(this.certificateMap).filter(certID => this.certificateMap[certID].installStatus === CertInstallStatus.Install || this.certificateMap[certID].installStatus === CertInstallStatus.Revoke);
        return {
            certificateList: installedCertIDs.map(certID => ({ certificateID: certID, isRevoked: this.certificateMap[certID].installStatus === CertInstallStatus.Revoke })),
            metadata: { rawData: JSON.stringify(installedCertIDs.map(certID => ({ id: this.certificateMap[certID].cert.id, name: this.certificateMap[certID].cert.name, expiryData: this.certificateMap[certID].cert.expiryDate, fingerprint: this.certificateMap[certID].cert.fingerprint }))) }
        };
    }

    copy(): PolicyDataCertificate {
        const d: PolicyDataCertificate = new PolicyDataCertificate();
        d.certificateMap = {};
        Object.keys(this.certificateMap).forEach(certID => {
            d.certificateMap[certID] = { cert: this.certificateMap[certID].cert.copy(), installStatus: this.certificateMap[certID].installStatus }
        });

        return d;
    }
}

export class PolicyDataGroupConfig {
    configMap: { [key: string]: PolicyDataGroupConfigItem };

    constructor() {
        this.configMap = {};
    }

    copy(): PolicyDataGroupConfig {
        const d: PolicyDataGroupConfig = new PolicyDataGroupConfig();

        Object.keys(this.configMap).forEach(key => {
            d.configMap[key] = {
                key: this.configMap[key].key,
                name: this.configMap[key].name,
                checked: this.configMap[key].checked,
                defaultValid: this.configMap[key].defaultValid,
                valid: this.configMap[key].valid,
                availableValueList: this.configMap[key].availableValueList,
                reset: this.configMap[key].reset
            }

            d.configMap[key].settingMap = {};
            Object.keys(this.configMap[key].settingMap).forEach(k => {
                let copied: any = this.configMap[key].settingMap[k].value;
                if (this.configMap[key].settingMap[k].value) {
                    if (this.configMap[key].settingMap[k].value.copy) {
                        copied = this.configMap[key].settingMap[k].value.copy();
                    }
                    else if (Array.isArray(this.configMap[key].settingMap[k].value)) {
                        if (this.configMap[key].settingMap[k].value.length === 0) {
                            copied = [];
                        }
                        else {
                            if (Array.isArray(this.configMap[key].settingMap[k].value[0].copy)) {
                                copied = this.configMap[key].settingMap[k].value.map(v => v.copy());
                            }
                            else if (typeof this.configMap[key].settingMap[k].value[0] === 'object') {
                                copied = this.configMap[key].settingMap[k].value.map(v => Object.assign({}, v));
                            }
                            else {
                                copied = this.configMap[key].settingMap[k].value.map(v => v);
                            }
                        }
                    }
                    else if (typeof this.configMap[key].settingMap[k].value === 'object') {
                        copied = Object.assign({}, this.configMap[key].settingMap[k].value);
                    }
                }

                d.configMap[key].settingMap[k] = {
                    langKey: this.configMap[key].settingMap[k].langKey,
                    value: copied
                };
            });
        });

        return d;
    }
}

export class PolicyDataGroupConfigItem {
    key: string;
    name: string;
    checked: boolean;
    valid: boolean;
    defaultValid: boolean;
    availableValueList?: any[];
    settingMap?: { [key: string]: { langKey: string, value: any } };
    reset?: (s: PolicyDataGroupConfigItem) => void;

    constructor(key: string, name: string, options?: { isDefaultValid?: boolean, valid?: boolean, checked?: boolean, defaultSettingMap?: { [key: string]: { langKey: string, value: any } }, resetFunc?: (s: PolicyDataGroupConfigItem) => void, availableSettings?: any[] }) {
        this.key = key;
        this.name = name;
        this.checked = options?.checked ?? false;
        this.defaultValid = options?.isDefaultValid ?? true;
        this.valid = options?.valid ?? this.defaultValid;
        this.availableValueList = options?.availableSettings || [];
        this.settingMap = options?.defaultSettingMap || {};
        this.reset = () => {
            this.valid = this.defaultValid;
            options?.resetFunc?.(this);
        };
        if (!options?.defaultSettingMap) {
            this.reset(this);
        }
    }
}

export const WEEKDAY_ORDINAL_LIST = ['first', 'second', 'third', 'fourth'];