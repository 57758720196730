import { Component } from '@angular/core';
import { of } from 'rxjs';
import { map, concatMap, catchError } from 'rxjs/operators';

import { IPolicyFunc } from '../policy-func.service';
import { PolicyInfo } from '../policy.data';
import { DialogPage } from '../../../../lib/common/common.data';
import { PolicyService } from '../policy.service';
import { AccountService } from '../../../../entry/account.service';
import { DeviceGroupService } from '../../../device/group/dev-group.service';
import { DeviceGroupInfo, DeviceGroupType } from '../../../device/group/dev-group.data';
import { DeviceCacheType, DeviceInfo } from '../../../device/data/device-info';
import { DeviceService } from '../../../device/device.service';
import { VirtualDlgComponent } from '../../../../../app/content/virtual/dlg/virtual-dlg.component';

@Component({
    templateUrl: './policy-dlg-remove.component.html',
    styleUrls: ['../policy.style.css']
})
export class PolicyDlgRemoveComponent extends VirtualDlgComponent implements IPolicyFunc {
    dialogCompleteHandler?: (result: { func: string, errorMessage?: string, data?: any }) => void;
    func: string;

    _title: string;
    set title(t: string) {
        this._title = t;
    }

    _policy: PolicyInfo;
    set policy(p: PolicyInfo) {
        this._policy = p;
    }

    _errorMessage: string;
    _page: DialogPage = DialogPage.action;
    _enumPage: typeof DialogPage = DialogPage;

    constructor(
        protected accountSvc: AccountService,
        private policySvc: PolicyService,
        private devSvc: DeviceService,
        private groupSvc: DeviceGroupService
    ) {
        super(accountSvc);
    }

    remove(): void {
        this._errorMessage = null;
        this._page = DialogPage.submit;

        this.policySvc.deletePolicy(this._policy).pipe(
            concatMap((res: { error: number | string, errorMessage?: string }) => {
                if (res.error !== 0) {
                    throw res.errorMessage;
                }

                if (this._policy.isUsed) {
                    //refresh groups to get the latest policies apply on group
                    //(this policy may be applied on multiple groups)
                    return this.groupSvc.refreshOwnerGroupList(false).pipe(
                        map(() => {
                            //update device cache
                            this._policy.groupList.forEach(t => {
                                if (t.type === DeviceGroupType.group) {
                                    const g: DeviceGroupInfo = this.groupSvc.getGroupByID(t.id);
                                    if (g && g.childs) {
                                        g.childs.filter(c => c.type === DeviceGroupType.device && c.data).forEach(c => {
                                            const dev: DeviceInfo = c.data as DeviceInfo;
                                            if (dev && dev.virtualId) {
                                                this.devSvc.resetCache(dev, DeviceCacheType.shadow);
                                            }
                                        })
                                    }
                                }
                            });
                            return res.errorMessage;
                        })
                    )
                }

                return of(res.errorMessage);
            }),
            catchError((error: any) => of(error))
        ).subscribe((errorMessage: string) => {
            this._errorMessage = errorMessage;
            this._page = DialogPage.result;

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler({ func: this.func, errorMessage: errorMessage });
            }
        });
    }
}