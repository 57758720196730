import { Component, Input } from '@angular/core';

@Component({
    selector: 'na-brightness-preview',
    templateUrl: './brightness-preview.component.html'
})
export class BrightnessPreviewComponent {
    _statistics: { time: string, brightness: number }[] = [];
    @Input('data')
    set data(din: { data: { time: number, brightness: number }[], cmd: string }) {
        if (din?.data?.length > 0) {
            let start: { time: number, brightness: number } = din.data[0], prev: { time: number, brightness: number } = start;
            for (let i = 1; i < din.data.length; i++) {
                const current = din.data[i];
                if (current.brightness !== prev.brightness) {
                    this._statistics.push({ time: `${this.padTime(start.time)} - ${this.padTime(current.time)}`, brightness: start.brightness * 10 });
                    start = current;
                }

                prev = current;
            }

            this._statistics.push({ time: `${this.padTime(start.time)} - ${this.padTime(24)}`, brightness: start.brightness * 10 });
        }
    }

    private padTime(time: number): string {
        return `${time < 10 ? '0' + time : time}:00`;
    }
}