import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { fromEvent as observableFromEvent } from 'rxjs';
import { map, concatAll, takeUntil } from 'rxjs/operators';
import { IUIElement } from '../uiElement.interface';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-volume',
    templateUrl: './volume.component.html',
    styleUrls: ['../uiElement.style.css', './volume.component.css']
})
export class VolumeComponent extends AutoUnsubscribeComponent implements AfterViewInit, IUIElement {
    private readonly VOLUME_MAX: number = 100;

    private _bOverMax: boolean = false;
    private _bOverMin: boolean = false;

    private _volumeBarWidth: number = 0;
    _volumeWidth: number = 0;
    private _offsetX: number = 0;
    private _ratio: number = 0;
    _valid: boolean = true;

    _displayVolumeLevel: boolean = true;
    @Input()
    set displayVolume(e: boolean) {
        this._displayVolumeLevel = e;
    }

    private _volume: number = 10;
    @Input()
    set volume(v: number) {
        if (this._volume !== v) {
            this._volume = v;
        }
    }

    _showBorder: boolean = false;
    @Input()
    set showBorder(e: boolean) {
        this._showBorder = e;
    }

    _unsupportReason: string;
    @Input('unsupportReason')
    set unsupportReason(v: string) {
        this._unsupportReason = v;
    }

    _disabled: boolean;
    @Input('disabled')
    set disabled(v: boolean) {
        this._disabled = v;
    }

    _lockInfo: { isSync: boolean, policyID: string, policyName: string };
    @Input('lock')
    set lock(d: { isSync: boolean, policyID: string, policyName: string }) {
        this._lockInfo = d;
    }

    private _max: number = this.VOLUME_MAX;
    @Input('volumeMax')
    set max(m: number) {
        if (!m) {
            //m should > 0
            this._valid = false;
            return;
        }

        this._max = m;
        this.updateMoveRatio();
    }

    private _volumeSliderRef: ElementRef;
    @ViewChild('volumeSlider', { static: true })
    set volumeSlider(holder: ElementRef) {
        if (holder) {
            this._volumeSliderRef = holder;
        }
    }

    private _volumeBallRef: ElementRef;
    @ViewChild('volumeBall', { static: true })
    set volumeBall(holder: ElementRef) {
        if (holder) {
            this._volumeBallRef = holder;
        }
    }

    private _volumeSliderDownRef: ElementRef;
    @ViewChild('volumeSliderDown', { static: true })
    set volumeSliderDown(holder: ElementRef) {
        if (holder) {
            this._volumeSliderDownRef = holder;
        }
    }

    private _volumeSliderUpRef: ElementRef;
    @ViewChild('volumeSliderUp', { static: true })
    set volumeSliderUp(holder: ElementRef) {
        if (holder) {
            this._volumeSliderUpRef = holder;
        }
    }

    @Output() onVolumeChanged = new EventEmitter<number>();

    ngAfterViewInit(): void {
        const volumeBarDownMouseDownOb = observableFromEvent<MouseEvent>(this._volumeSliderDownRef.nativeElement, 'mousedown');
        volumeBarDownMouseDownOb.pipe(
            map((m: MouseEvent) => {
                m.preventDefault();
                return m.offsetX
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe(offsetX => {
            this.updateByOffsetX(offsetX);
        });
        const volumeBarUpMouseDownOb = observableFromEvent<MouseEvent>(this._volumeSliderUpRef.nativeElement, 'mousedown');
        volumeBarUpMouseDownOb.pipe(
            map((m: MouseEvent) => {
                m.preventDefault();
                return m.offsetX
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe(offsetX => {
            this.updateByOffsetX(offsetX);
        });

        const volumeBallMouseDownOb = observableFromEvent(this._volumeBallRef.nativeElement, 'mousedown');
        const mouseUpOb = observableFromEvent(document, 'mouseup');
        const mouseMoveOb = observableFromEvent<MouseEvent>(document, 'mousemove');
        volumeBallMouseDownOb.pipe(
            map(e => mouseMoveOb.pipe(
                takeUntil(mouseUpOb)
            )),
            concatAll(),
            map((m: MouseEvent) => {
                m.preventDefault();
                return m.movementX
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe(deltaX => {
            this.updateByMoveDelta(deltaX);
        });

        const sizeChangeOb = observableFromEvent(window, 'resize');
        sizeChangeOb.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe(() => {
            this._volumeBarWidth = this._volumeSliderRef.nativeElement.clientWidth;
            this.updateMoveRatio();
            this._volumeWidth = this._offsetX = this._volume * this._ratio;
        });

        setTimeout(() => {
            this._volumeBarWidth = this._volumeSliderRef.nativeElement.clientWidth;
            this.updateMoveRatio();
            this._volumeWidth = this._offsetX = this._volume * this._ratio;
        }, 500);
    }

    private updateByMoveDelta(delta: number): void {
        if ((this._bOverMax && delta >= 0) || (this._bOverMin && delta <= 0)) {
            return;
        }

        this.updateByOffsetX(this._offsetX + delta);
    }

    private updateByOffsetX(offsetX: number): void {
        if (!this._valid) {
            return;
        }

        const new_volume = Math.round(offsetX / this._ratio);
        if (new_volume > this._max) {
            this._bOverMax = true;
        }
        else if (new_volume < 0) {
            this._bOverMin = true;
        }
        else {
            this._bOverMax = this._bOverMin = false;
        }

        if (!this._bOverMax && !this._bOverMin) {
            this._offsetX = offsetX;
            this.volumeCorrect(new_volume);
        }
    }

    private volumeCorrect(new_volume: number = this._volume): void {
        if (new_volume != this._volume) {
            this._volume = new_volume;
            this._volumeWidth = this._volume * this._ratio;
            this.onVolumeChanged.next(this._volume);
        }
    }

    mute(): void {
        this._volume = 0;
        this._volumeWidth = 0;
        this._offsetX = 0;
        this.onVolumeChanged.next(this._volume);
    }

    volumeUp(): void {
        this.adjustVolume(true);
    }

    volumeDown(): void {
        this.adjustVolume(false);
    }

    private adjustVolume(bUp: boolean): void {
        let new_volume = bUp ? this._volume + 1 : this._volume - 1;
        new_volume = new_volume > this._max ? this._max : new_volume < 0 ? 0 : new_volume;
        if (new_volume != this._volume) {
            this._volume = new_volume;
            this._volumeWidth = this._offsetX = this._volume * this._ratio;
            this.onVolumeChanged.next(this._volume);
        }
    }

    private updateMoveRatio(): void {
        this._ratio = this._volumeBarWidth / this._max;
    }

    private volumePercentTransform(): number {
        let v: number = Math.round(this._volume * 100 / this._max);
        v = v > 100 ? 100 : v < 0 ? 0 : v;

        return v;
    }
}