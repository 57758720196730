import { CustomizationItemInfo } from "../customization/customization.data";

export type PropertiesOnly<T> = { [K in keyof T as (T[K] extends Function ? never : K)]?: T[K] };
export type PluginResponse<T> = {
    error: number;
    errorMessage?: string;
    data: T;
}

export class PluginItemInfo<T> {
    prev: T;
    current: T;

    get isChanged(): boolean {
        return this.prev !== this.current;
    }

    get isValid(): boolean {
        return this.current ? true : false;
    }

    constructor(defaultData: T) {
        this.prev = this.current = defaultData;
    }

    setAll(v: T): void {
        this.prev = this.current = v;
    }

    set(v: T): void {
        this.current = v;
    }

    reset(): void {
        this.current = this.prev;
    }

    export(): T {
        return this.isChanged ? this.current : undefined;
    }
}

export interface IPluginDataProvider {
    name: string;
    config: {
        tenantUrl: string;
        username: string;
        userCredential: string;
    };
    funcs: { enableCalendarSync: boolean; };
    iadeaCareAccessInfo: { accessKeyID: string, accessKeyRemark: string, isAccessKeyEnabled: boolean };
}

export interface IPluginDataCalendar {
    locale: string;
    pin: number;
    background: string;
    logo: string;
    fontsizeRatio?: number;
    theme: {
        foreground?: string;
        availableColor?: string;
        busyColor?: string;
        timeline?: {
            bgColor?: string;
            futureEventTimeBlockColor?: string;
            currentEventTimeBlockColor?: string;
            expiredEventTimeBlockColor?: string;
        }
    };
    calendar: {
        enableOnsiteBook?: boolean;
        enableFutureEventBook?: boolean;
        enableFutureEventCancel?: boolean;
        hideTimeline?: boolean;
    };
    lightbar: {
        activate?: boolean;
        available: {
            color?: string;
            mode?: string;
        },
        busy: {
            color?: string;
            mode?: string;
        }
    }
}

export interface IPluginDataPairing {
    spaceID?: string;
    spaceShortID?: string;
    spaceName?: string;
    type?: 'room' | 'desk';
    emailAddress?: string;
    capacity?: number;
    devices: {
        deviceID: string;
        deviceName?: string;
        isPaired: boolean;
        pairingDateTime: Date;
    }[]
}

export class PluginProviderInfo {
    tenantUrl: PluginItemInfo<string>;
    username: PluginItemInfo<string>;
    userCredential: PluginItemInfo<string>;
    funcs: {
        enableCalendarSync: PluginItemInfo<boolean>
    }

    constructor(raw?: Partial<IPluginDataProvider>) {
        this.tenantUrl = new PluginItemInfo<string>(raw?.config?.tenantUrl || '');
        this.username = new PluginItemInfo<string>(raw?.config?.username || '');
        this.userCredential = new PluginItemInfo<string>(raw?.config?.userCredential || '');
        this.funcs = {
            enableCalendarSync: new PluginItemInfo<boolean>(raw?.funcs?.enableCalendarSync ?? false)
        };
    }

    isEmpty(): boolean {
        return !this.tenantUrl && !this.username && !this.userCredential ? true : false;
    }

    isValid(): boolean {
        return this.tenantUrl.isValid && this.username.isValid && this.userCredential.isValid;
    }

    isChanged(): boolean {
        return this.tenantUrl.isChanged ||
            this.username.isChanged ||
            this.userCredential.isChanged ||
            this.funcs.enableCalendarSync.isChanged;
    }

    reset(): void {
        this.tenantUrl.reset();
        this.username.reset();
        this.userCredential.reset();
        this.funcs.enableCalendarSync.reset();
    }

    export(): Partial<IPluginDataProvider> {
        return {
            config: {
                tenantUrl: this.tenantUrl.export(),
                username: this.username.export(),
                userCredential: this.userCredential.export(),
            },
            funcs: {
                enableCalendarSync: this.funcs.enableCalendarSync.export(),
            }
        }
    }
}

export class PluginCalendarInfo {
    locale: PluginItemInfo<string>;
    pin: PluginItemInfo<number>;
    background: CustomizationItemInfo;
    logo: CustomizationItemInfo;
    fontsizeRatio: PluginItemInfo<number>;
    theme: {
        foreground?: PluginItemInfo<string>;
        availableColor?: PluginItemInfo<string>;
        busyColor?: PluginItemInfo<string>;
        timeline?: {
            bgColor?: PluginItemInfo<string>;
            futureEventTimeBlockColor?: PluginItemInfo<string>;
            currentEventTimeBlockColor?: PluginItemInfo<string>;
            expiredEventTimeBlockColor?: PluginItemInfo<string>;
        }
    };
    calendar: {
        enableOnsiteBook?: PluginItemInfo<boolean>;
        enableFutureEventBook?: PluginItemInfo<boolean>;
        enableFutureEventCancel?: PluginItemInfo<boolean>;
        hideTimeline?: PluginItemInfo<boolean>;
    };
    lightbar: {
        activate?: PluginItemInfo<boolean>;
        available: {
            color?: PluginItemInfo<string>;
            mode?: PluginItemInfo<string>;
        },
        busy: {
            color?: PluginItemInfo<string>;
            mode?: PluginItemInfo<string>;
        }
    };

    constructor(imgBaseUrl?: string, accountID?: string, token?: string, raw?: Partial<IPluginDataCalendar>) {
        this.locale = new PluginItemInfo(raw?.locale || 'en-us');
        this.pin = new PluginItemInfo(raw?.pin || 1688);
        this.fontsizeRatio = new PluginItemInfo(raw?.fontsizeRatio || 1);
        this.background = new CustomizationItemInfo();
        this.logo = new CustomizationItemInfo();
        if (imgBaseUrl) {
            this.background.reset(`${imgBaseUrl}/${accountID}/${raw?.background}?token=${token}&nounce=${Date.now()}`);
            this.logo.reset(`${imgBaseUrl}/${accountID}/${raw?.logo}?token=${token}&nounce=${Date.now()}`);
        }

        this.theme = {
            foreground: new PluginItemInfo(raw?.theme?.foreground || '#000000'),
            availableColor: new PluginItemInfo(raw?.theme?.availableColor || '#1cbf92'),
            busyColor: new PluginItemInfo(raw?.theme?.busyColor || '#bb2323'),
            timeline: {
                bgColor: new PluginItemInfo(raw?.theme?.timeline?.bgColor || '#d3d3d3'),
                futureEventTimeBlockColor: new PluginItemInfo(raw?.theme?.timeline?.futureEventTimeBlockColor || '#c96565'),
                currentEventTimeBlockColor: new PluginItemInfo(raw?.theme?.timeline?.currentEventTimeBlockColor || '#d72c2c'),
                expiredEventTimeBlockColor: new PluginItemInfo(raw?.theme?.timeline?.expiredEventTimeBlockColor || '#6c757d')
            }
        };
        this.calendar = {
            enableFutureEventBook: new PluginItemInfo(raw?.calendar?.enableFutureEventBook ?? true),
            enableFutureEventCancel: new PluginItemInfo(raw?.calendar?.enableFutureEventCancel ?? true),
            enableOnsiteBook: new PluginItemInfo(raw?.calendar?.enableOnsiteBook ?? true),
            hideTimeline: new PluginItemInfo(raw?.calendar?.hideTimeline ?? false),
        };
        this.lightbar = {
            activate: new PluginItemInfo(raw?.lightbar?.activate ?? true),
            available: {
                color: new PluginItemInfo(raw?.lightbar?.available?.color || '#00ff00'),
                mode: new PluginItemInfo(raw?.lightbar?.available?.mode || 'on')
            },
            busy: {
                color: new PluginItemInfo(raw?.lightbar?.busy?.color || '#ff0000'),
                mode: new PluginItemInfo(raw?.lightbar?.busy?.mode || 'on')
            }
        };
    }

    isValid(): boolean {
        return true;
    }

    isChanged(): boolean {
        return this.locale.isChanged ||
            this.pin.isChanged ||
            this.background.isChanged() ||
            this.logo.isChanged() ||
            this.fontsizeRatio.isChanged ||
            this.theme.foreground.isChanged ||
            this.theme.availableColor.isChanged ||
            this.theme.busyColor.isChanged ||
            this.theme.timeline.futureEventTimeBlockColor.isChanged ||
            this.theme.timeline.currentEventTimeBlockColor.isChanged ||
            this.theme.timeline.expiredEventTimeBlockColor.isChanged ||
            this.calendar.enableFutureEventBook.isChanged ||
            this.calendar.enableFutureEventCancel.isChanged ||
            this.calendar.enableOnsiteBook.isChanged ||
            this.calendar.hideTimeline.isChanged ||
            this.lightbar.activate.isChanged ||
            this.lightbar.available.color.isChanged ||
            this.lightbar.available.mode.isChanged ||
            this.lightbar.busy.color.isChanged ||
            this.lightbar.busy.mode.isChanged;
    }

    reset(): void {
        this.locale.reset();
        this.pin.reset();
        this.background.reset();
        this.logo.reset();
        this.fontsizeRatio.reset();
        this.theme.foreground.reset();
        this.theme.availableColor.reset();
        this.theme.busyColor.reset();
        this.theme.timeline.bgColor.reset();
        this.theme.timeline.futureEventTimeBlockColor.reset();
        this.theme.timeline.currentEventTimeBlockColor.reset();
        this.theme.timeline.expiredEventTimeBlockColor.reset();
        this.calendar.enableFutureEventBook.reset();
        this.calendar.enableFutureEventCancel.reset();
        this.calendar.enableOnsiteBook.reset();
        this.calendar.hideTimeline.reset();
        this.lightbar.activate.reset();
        this.lightbar.available.color.reset();
        this.lightbar.available.mode.reset();
        this.lightbar.busy.color.reset();
        this.lightbar.busy.mode.reset();
    }

    export(): Partial<IPluginDataCalendar> {
        return {
            locale: this.locale.export(),
            pin: this.pin.export(),
            fontsizeRatio: this.fontsizeRatio.export(),
            //background: this.background.name,
            //logo: this.logo.name,
            theme: {
                foreground: this.theme.foreground.export(),
                availableColor: this.theme.availableColor.export(),
                busyColor: this.theme.busyColor.export(),
                timeline: {
                    bgColor: this.theme.timeline.bgColor.export(),
                    futureEventTimeBlockColor: this.theme.timeline.futureEventTimeBlockColor.export(),
                    currentEventTimeBlockColor: this.theme.timeline.currentEventTimeBlockColor.export(),
                    expiredEventTimeBlockColor: this.theme.timeline.expiredEventTimeBlockColor.export()
                }
            },
            calendar: {
                enableFutureEventBook: this.calendar.enableFutureEventBook.export(),
                enableFutureEventCancel: this.calendar.enableFutureEventCancel.export(),
                enableOnsiteBook: this.calendar.enableOnsiteBook.export(),
                hideTimeline: this.calendar.hideTimeline.export()
            },
            lightbar: {
                activate: this.lightbar.activate.export(),
                available: {
                    color: this.lightbar.available.color.export(),
                    mode: this.lightbar.available.mode.export()
                },
                busy: {
                    color: this.lightbar.busy.color.export(),
                    mode: this.lightbar.busy.mode.export()
                }
            }
        }
    }
}

export class PluginSpaceInfo {
    spaceID?: string;
    spaceShortID?: string;
    spaceName?: string;
    type?: 'room' | 'desk';
    emailAddress?: string;
    capacity?: number;

    constructor(raw?: Partial<PropertiesOnly<PluginSpaceInfo>>) {
        this.spaceID = raw?.spaceID || '';
        this.spaceShortID = this.spaceID.substring(0, 5);
        this.spaceName = raw.spaceName;
        this.type = raw.type;
        this.emailAddress = raw.emailAddress;
        this.capacity = raw.capacity;
    }
}

export type PluginPairingRequestInfo = Partial<PluginSpaceInfo> & {
    devices: {
        deviceID: string;
        deviceName?: string;
        isPaired: boolean;
        pairingDateTime: Date;
    }[]
};