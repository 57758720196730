import { Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";

import { SearchHelper } from "./search.helper";
import { HelperLib } from "../../../../../app/lib/common/helper.lib";
import { TranslateService } from "../../../../../app/translate/translate.service";
import { AppliedFilterInfo, DeviceAdvFilterInfo } from "../data/dev-adv-filter.data";
import { DeviceGroupService } from "../../group/dev-group.service";
import { DeviceGroupInfo } from "../../group/dev-group.data";
import { PolicyInfo } from "../../../../../app/content/setting/policy/policy.data";
import { PolicyService } from "../../../../../app/content/setting/policy/policy.service";

export class DeviceFilterHelper {
    static exportFilterDesc(translateSvc: TranslateService, filter: Partial<AppliedFilterInfo>, options?: { devGroupSvc?: DeviceGroupService, policySvc?: PolicyService }): Observable<{ name: string, descs: string[] }[]> {
        if (!filter) {
            return of([]);
        }

        return of(true).pipe(
            map(() => {
                const record: Map<string, string[]> = new Map();

                let policyRuleInfo: { id: string, isSynced: boolean };
                let deviceGroupRuleInfo: { id: string };
                let ticketRuleInfo: { id: string, status: string };

                if (filter.rules?.length > 0) {
                    filter.rules.forEach(rule => {
                        if (rule.value.startsWith('[#DevicePolicy')) {
                            // Policy rule, should be at most one policy rule at a time.
                            const matches: RegExpMatchArray = rule.value.match(/\[#DevicePolicy\.([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}):(\w+)\]/);
                            if (matches) {
                                policyRuleInfo = { id: matches[1], isSynced: Boolean(matches[2]) };
                            }
                        }
                        else if (rule.value.startsWith('[#DeviceGroup')) {
                            // Dev Group rule, should be at most one dev-group rule at a time.
                            const matches: RegExpMatchArray = rule.value.match(/\[#DeviceGroup:\"([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})\"\]/);
                            if (matches) {
                                deviceGroupRuleInfo = { id: matches[1] };
                            }
                        }
                        else if (rule.value.startsWith('[Ticket')) {
                            // Ticket rule, should be at most one ticket rule at a time.
                            const matches: RegExpMatchArray = rule.value.match(/\[Ticket:\"([a-f0-9]{13}-[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})(:*)(\w*)\"\]/);
                            if (matches) {
                                ticketRuleInfo = { id: matches[1], status: matches[3] };
                            }
                        }
                    });
                }

                if (ticketRuleInfo) {
                    record.set('Device activity', [`Activity ID = ${ticketRuleInfo.id}`, `${ticketRuleInfo.status ? `Activity status = ${ticketRuleInfo.status}` : ''}`]);
                }
                if (deviceGroupRuleInfo?.id) {
                    const targetDevGroup: DeviceGroupInfo = options?.devGroupSvc?.getGroupByID(deviceGroupRuleInfo.id);
                    record.set('Device group', [`Device group = ${targetDevGroup?.name || deviceGroupRuleInfo.id}`]);
                }

                return { record: record, policyRuleInfo: policyRuleInfo };
            }),
            concatMap((res) => {
                if (res.policyRuleInfo) {
                    return (options?.policySvc?.getPolicyList() || of([])).pipe(
                        map((policies: PolicyInfo[]) => {
                            const policy: PolicyInfo = policies.find(p => p.id === res.policyRuleInfo.id);
                            res.record.set('Device policy', [`Policy = ${policy?.name || res.policyRuleInfo.id}`, `Sync status = ${res.policyRuleInfo.isSynced ? 'Synced' : 'Not synced'}`]);
                            
                            return res.record;
                        })
                    );
                }

                return of(res.record);
            }),
            map((record: Map<string, string[]>) => {
                if (filter.onlineStatus) {
                    const onlineStatusState = HelperLib.getOnlineStatusState(filter.onlineStatus);
                    if (onlineStatusState.isChanged) {
                        record.set('Online status', [Object.keys(onlineStatusState.onlineStatusIndicator).map(key => `${key}: ${onlineStatusState.onlineStatusIndicator[key]}`).join(', ')]);
                    }
                }
                if (filter.searchQuery) {
                    if (SearchHelper.isExpressionHasValue(filter.searchQuery)) {
                        record.set('Advanced search', [SearchHelper.exportSearchQueryToPlaintext(translateSvc, filter.searchQuery)]);
                    }
                }
                if (filter.quickSearch) {
                    if (SearchHelper.isExpressionHasValue(filter.quickSearch)) {
                        record.set('Quick search', [SearchHelper.exportSearchQueryToPlaintext(translateSvc, filter.quickSearch)]);
                    }
                }
                if (filter.devGroup) {
                    if (filter.devGroup?.id) {
                        record.set('Device group', [`Group = ${options?.devGroupSvc?.getGroupByID(filter.devGroup.id)?.name || filter.devGroup.id}`]);
                    }
                }
                if (filter.advanceFilters) {
                    const filterQueries: { type: string, value: string }[] = filter.advanceFilters.flatMap(advf => advf.export());
                    if (filterQueries.length > 0) {
                        record.set('Advanced filters', [`${filterQueries.length} filter(s) applied`]);
                    }
                }

                return Array.from(record.entries()).map((v) => ({ name: v[0], descs: v[1] }));
            })
        );
    }

    static isSavedFilterApplied(filter: Partial<AppliedFilterInfo>): { onlineStatus: boolean, searchQuery: boolean, advanceFilters: boolean } {
        return {
            onlineStatus: filter?.onlineStatus ? HelperLib.getOnlineStatusState(filter.onlineStatus).isChanged : false,
            searchQuery: filter?.searchQuery ? SearchHelper.isExpressionHasValue(filter.searchQuery) : false,
            advanceFilters: this.isAdvanceFilterApplied(filter.advanceFilters)
        };
    }

    static isAdvanceFilterApplied(advFilters: DeviceAdvFilterInfo[]): boolean {
        if (!advFilters) {
            return false;
        }

        for (let filter of advFilters) {
            if (filter.export().length > 0) {
                return true;
            }
        }

        return false;
    }
}