import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceGroupService } from './dev-group.service';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CustomPipeModule } from '../../../../app/lib/pipes/custom-pipe.module';
import { TranslateModule } from '../../../../app/translate/translate.module';
import { DeviceGroupTreeWrapperComponent } from './comp/dev-group-tree-wrapper.component';
import { DeviceGroupTreeViewComponent } from './comp/dev-group-tree-view.component';
import { DeviceGroupDetailComponent } from './comp/dev-group-detail.component';
import { DeviceGroupFuncService } from './dlg/group-func.service';
import { DeviceGroupFuncDirective } from './dlg/group-func.directive';
import { DeviceGroupCreateDlgComponent } from './dlg/group-dlg-create.component';
import { DeviceGroupDeleteDlgComponent } from './dlg/group-dlg-delete.component';
import { DeviceGroupMoveDlgComponent } from './dlg/group-dlg-move.component';
import { DeviceGroupExcludeDlgComponent } from './dlg/group-dlg-exclude.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule
    ],
    providers: [
        DeviceGroupService,
        DeviceGroupFuncService
    ],
    declarations: [
        DeviceGroupFuncDirective,
        DeviceGroupCreateDlgComponent,
        DeviceGroupDeleteDlgComponent,
        DeviceGroupMoveDlgComponent,
        DeviceGroupExcludeDlgComponent,
        DeviceGroupTreeWrapperComponent,
        DeviceGroupTreeViewComponent,
        DeviceGroupDetailComponent
    ],
    exports: [
        DeviceGroupFuncDirective,
        DeviceGroupTreeWrapperComponent,
        DeviceGroupTreeViewComponent,
        DeviceGroupDetailComponent
    ]
})
export class DeviceGroupModule {}