import { Component, Input } from "@angular/core";
import { DeviceAdvFilterInfo, DeviceAdvFilterOptionInfo, DeviceAdvFilterType } from "../data/dev-adv-filter.data";

@Component({
    selector: 'na-dev-adv-filter',
    templateUrl: './dev-adv-filter.component.html',
    styleUrls: ['./dev-adv-filter.component.css']
})
export class DeviceAdvanceFilterComponent {
    readonly FILTER_TYPE_GROUP: DeviceAdvFilterType = DeviceAdvFilterType.Group;
    readonly FILTER_TYPE_SELECT: DeviceAdvFilterType = DeviceAdvFilterType.Select;
    readonly FILTER_TYPE_SIMPLE_SELECT: DeviceAdvFilterType = DeviceAdvFilterType.SimpleSelect;
    readonly FILTER_TYPE_MULTI_SELECT: DeviceAdvFilterType = DeviceAdvFilterType.MultiSelect;
    readonly FILTER_TYPE_TEXT: DeviceAdvFilterType = DeviceAdvFilterType.TextWithOptions;

    @Input('source') _source: DeviceAdvFilterInfo;
    @Input('loading') _loading: boolean = false;

    selectFilter(filter: DeviceAdvFilterInfo): void {
        if (filter.type == this.FILTER_TYPE_SIMPLE_SELECT) {
            if (!filter.dropdownShow) {
                // should set show to false for all the other simple-select filters
            }
        }

        filter.dropdownShow = !filter.dropdownShow;
    }

    chooseSingleSelectFilterOption(filter: DeviceAdvFilterInfo, option: DeviceAdvFilterOptionInfo): void {
        filter.dropdownShow = !filter.dropdownShow
        filter.optionList.forEach(option => option.isDefault = false);
        option.isDefault = true;
        filter.option = option;
    }

    chooseMultiSelectFilter(filter: DeviceAdvFilterInfo, checked: boolean): void {
        filter.isDefault = checked;
        filter.optionList?.forEach(option => option.isDefault = checked);
    }

    chooseMultiSelectFilterOption(filter: DeviceAdvFilterInfo, option: DeviceAdvFilterOptionInfo, checked: boolean): void {
        option.isDefault = checked;
        if (option.isDefault) {
            filter.isDefault = true;
        }
        else {
            let isOptionSelected: boolean = false;
            for (let option of filter.optionList) {
                if (option.isDefault) {
                    isOptionSelected = true;
                    break;
                }
            }

            if (!isOptionSelected) {
                filter.isDefault = false;
            }
        }
    }
}